import { ApolloLink, FetchResult, NextLink, Observable, Operation } from '@apollo/client/core';
// keep track of the queries that are not completed
// similar to opQueue in QueueLink library since the original is private
/**
 * Keeps track of the queries that are not completed in offline mode
 */
 class QueueLinkTracker extends ApolloLink {
  private queue: Operation[] = [];
  //Using map instead of array for faster lookup
  private isClose = false;
  /**
   * This function is called when the link is executed, in other words whenever a query is made.
   * It keeps track of the queries that are not completed in offline mode.
   * If the link is closed, the query is added to the queue, and the request is sent back with a dummy response. 
   * If the link is closed and the query is executed, it is removed from the queue.
   * @updated 4/04/2024 - 08:41:56
   * @param operation The operation or query that is to be executed
   * @param forward  The next link in the chain, if there is one
   */
  request(operation: Operation, forward?: NextLink) {
    if (forward === undefined) {
      return null;
    }

    if (this.isClose) {
      this.queue.push(operation);

      // If this occurred during offline mode, just return a dummy response
      // TODO : Do something with response in future
      if(operation.operationName === "InsertDataFromForm"){
            const fetchResult: FetchResult = {
              data : {
                insertDataFromForm: {
                  projectId: "",
                  __typename : "Data",
                }
              }
          }
          const linkResult = Observable.of(fetchResult);
          return linkResult;
      }
 
    }

    return forward(operation).map((data) => {
      this.queue = this.queue.filter((item: any) => item !== operation);
      return data;
    })

  }

  close(): void {
    this.isClose = true;
  }

  open(): void {
    this.isClose = false;
  }

  getQueue() :  Operation[] {
    return this.queue;
  }

}


export const offlineLinkTracker = new QueueLinkTracker();