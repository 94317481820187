import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ProjectState } from '@ih/app/client/project/data-access';
import { AlertService, ToastService } from '@ih/app/client/shared/services';
import { Board, Project } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';

import {
  ConfirmationCancel,
  DeleteManagerBoard,
  FindAllBoards,
  RemoveBoardAction,
  ResetProjectBoardsList,
  SetBoards,
  ShowDeleteConfirmation,
} from '../actions';
import {
  DeleteBoardError,
  DeleteBoardSuccess,
  FindAllBoardsError,
  FindAllBoardsSuccess,
} from '../effects';

export interface ListStateModel {
  boards: Board[];
}

@State<ListStateModel>({
  name: 'projectBoardsList',
  defaults: {
    boards: [],
  },
})
@Injectable()
export class ListState {
  constructor(
    private readonly toastService: ToastService,
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly alertService: AlertService,
  ) {}

  @Selector()
  public static getState(state: ListStateModel) {
    return state;
  }

  @Selector()
  public static boards(state: ListStateModel): Board[] {
    return state.boards;
  }

  @Action(FindAllBoards)
  public findAllBoards(ctx: StateContext<ListStateModel>) {
    const project = this.store.selectSnapshot<Project | null>(
      ProjectState.project,
    );
    const projectId: string | undefined = project?.id;

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllBoards($projectId: String!) {
        findAllBoards(request: { where: { projectId: $projectId } }) {
          id
          name
          slug
        }
      }
    `;

    return this.apollo
      .watchQuery<{ findAllBoards: Board[] }>({
        query,
        variables: {
          projectId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        tap((result: ApolloQueryResult<{ findAllBoards: Board[] }>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllBoardsSuccess(result));
        }),
        catchError((error) => ctx.dispatch(new FindAllBoardsError(error))),
      );
  }

  @Action(FindAllBoardsSuccess)
  public findAllBoardsSuccess(
    ctx: StateContext<ListStateModel>,
    { result }: FindAllBoardsSuccess,
  ) {
    const boards = (result?.data?.findAllBoards || []) as Board[];
    ctx.dispatch(new SetBoards(boards));
  }

  @Action(FindAllBoardsError)
  public findAllBoardsError(
    ctx: StateContext<ListStateModel>,
    { error }: FindAllBoardsError,
  ) {
    this.toastService.showError('Failed to fetch boards');
    console.error('Failed to fetch boards: ', error.message);
  }

  @Action(SetBoards)
  public setBoards(ctx: StateContext<ListStateModel>, { boards }: SetBoards) {
    ctx.setState(
      produce((draft) => {
        draft.boards = boards;
      }),
    );
  }

  @Action(ResetProjectBoardsList)
  public resetProjectBoardsList(ctx: StateContext<ListStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.boards = [];
      }),
    );
  }

  @Action(ShowDeleteConfirmation)
  public showDeleteConfirmationAction(
    ctx: StateContext<ListStateModel>,
    { board }: ShowDeleteConfirmation,
  ) {
    this.alertService.showAlert(
      `You are about to delete this board. Are you sure you want to continue?`,
      'Confirm?',
      undefined,
      [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ctx.dispatch(new ConfirmationCancel());
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            ctx.dispatch(new DeleteManagerBoard(board.id));
          },
        },
      ],
    );
  }

  @Action(DeleteManagerBoard)
  public deleteManagerBoard(
    ctx: StateContext<ListStateModel>,
    { id }: DeleteManagerBoard,
  ) {
    type ResultType = {
      deleteBoard: Board | null;
    };
    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeleteBoard($id: String!) {
            deleteBoard(request: { id: $id, permanent: true }) {
              id
              name
            }
          }
        `,
        variables: {
          id,
        },
      })
      .pipe(
        tap((result: any) =>
          ctx.dispatch(new DeleteBoardSuccess(result.data?.deleteBoard)),
        ),
        catchError((error) => ctx.dispatch(new DeleteBoardError(error))),
      );
  }

  @Action(DeleteBoardSuccess)
  public deleteBoardSuccess(
    ctx: StateContext<ListStateModel>,
    { board }: DeleteBoardSuccess,
  ) {
    if (board) {
      return ctx.dispatch(new RemoveBoardAction(board.id));
    }

    return null;
  }

  @Action(DeleteBoardError)
  public deleteBoardError(
    ctx: StateContext<ListStateModel>,
    { error }: DeleteBoardError,
  ) {
    this.toastService.showError('Failed to delete boards');
    console.error('Failed to delete boards: ', error.message);
  }

  @Action(RemoveBoardAction)
  public removeBoardAction(
    ctx: StateContext<ListStateModel>,
    { id }: RemoveBoardAction,
  ) {
    ctx.dispatch(new FindAllBoards());
  }
}
