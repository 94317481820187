import { ApolloQueryResult } from '@apollo/client/core';
import { OrganisationMember } from '@ih/app/shared/apis/interfaces';

export class FindAllOrganisationsSuccess {
  public static readonly type =
    '[User/Organisations] FindAllOrganisationsSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllOrganisationMembers: OrganisationMember[];
    }>
  ) {}
}
