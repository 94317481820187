import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ResetUserOrganisationsList } from '@ih/app/client/user/organisations/data-access';
import { ResetUserProjectsList } from '@ih/app/client/user/projects/data-access';
import { User } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, EMPTY, map, take } from 'rxjs';
import { GetUserAction, ResetStates, SetUserAction } from '../actions';

export interface UserStateModel {
  user: User | null;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: null,
  },
})
@Injectable()
export class UserState {
  @Selector()
  public static getState(state: UserStateModel) {
    return state;
  }

  @Selector()
  public static user(state: UserStateModel): User | null {
    return state.user;
  }

  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly router: Router
  ) {}

  @Action(GetUserAction)
  public getUserAction(
    ctx: StateContext<UserStateModel>,
    { id }: GetUserAction
  ) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueUser($id: String!) {
        findUniqueUser(request: { id: $id }) {
          id
          displayName
          username
          email
        }
      }
    `;

    return this.apollo
      .watchQuery<{ findUniqueUser: User }>({
        query,
        variables: {
          id,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<{ findUniqueUser: User }>) => {
          this.store.dispatch(new ResetStates());
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          const user = result?.data?.findUniqueUser as User;
          ctx.dispatch(new SetUserAction(user));
        }),
        catchError((error) => {
          console.error(error);
          this.router.navigate(['/error', 404]);
          return EMPTY;
        })
      );
  }

  @Action(SetUserAction)
  public setUserAction(
    ctx: StateContext<UserStateModel>,
    { user }: SetUserAction
  ) {
    ctx.setState(
      produce((draft) => {
        draft.user = user;
      })
    );
  }

  @Action(ResetStates)
  public resetStates(ctx: StateContext<UserStateModel>) {
    ctx.dispatch([
      new ResetUserProjectsList(),
      new ResetUserOrganisationsList(),
    ]);
  }
}
