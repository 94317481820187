import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Actions, Select, Store } from '@ngxs/store';
import {
  Submit,
  ChatState,
} from '@ih/app/client/shared/components/chat/data-access';
import { Observable } from 'rxjs';

@Component({
  selector: 'ih-app-client-shared-components-chat-feature',
  template: `
    <ion-content>
      <ion-list class="safe-padding">
        <ion-item lines="none">
          <ion-label slot="start">Chat</ion-label>
          <ion-button
            fill="solid"
            color="danger"
            size="small"
            slot="end"
            (click)="closeModal()"
          >
            <ion-icon name="close-outline"></ion-icon>
          </ion-button>
        </ion-item>
        <ion-grid class="grid-fitting">
          <ion-row>
            <ion-col size="12">
              <ion-card>
                <ion-card-content> Hi, how may I help you? </ion-card-content>
              </ion-card>
              <ng-container *ngFor="let message of messages$ | async">
                <ion-card [color]="message.type === 'user' ? 'primary' : ''">
                  <ion-card-content>
                    {{ message.text }}
                  </ion-card-content>
                </ion-card>
              </ng-container>
              <ion-spinner
                *ngIf="busy$ | async"
                class="ion-margin-start ion-margin-top"
                name="dots"
                color="primary"
              ></ion-spinner>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div class="ion-padding">
          <form [formGroup]="form" ngxsForm="chat.form" (submit)="submit()">
            <ion-item lines="none" fill="outline">
              <ion-input
                formControlName="question"
                required="true"
                autofocus="true"
                type="text"
                placeholder="Type your question"
              ></ion-input>
              <ion-button
                [disabled]="busy$ | async"
                slot="end"
                fill="clear"
                size="default"
                type="submit"
              >
                <ion-icon name="send-outline" slot="icon-only"></ion-icon>
              </ion-button>
            </ion-item>
          </form>
        </div>
      </ion-list>
    </ion-content>
  `,
  styles: [],
})
export class AppClientSharedComponentsChatFeatureComponent {
  @Select(ChatState.messages) messages$!: Observable<any[]>;
  @Select(ChatState.busy) busy$!: Observable<boolean>;

  public form = this.fb.group({
    question: ['', [Validators.required]],
  });

  constructor(
    private readonly modalController: ModalController,
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly actions$: Actions
  ) {}

  // ngOnInit(): void {
  //   // sub to busy
  // }

  closeModal() {
    this.modalController.dismiss();
  }

  submit() {
    if (this.form.valid) this.store.dispatch(new Submit());
  }
}
