import { OrganisationMember, OrganisationRole } from '@ih/app/shared/apis/interfaces';

export class FindAllOrganisationRoles {
  public static readonly type =
    '[Organisation/OrganisationMember/List] FindAllOrganisationRoles';
  constructor(
    public readonly organisationMembers: OrganisationMember[],
    public readonly defaultRoles: OrganisationRole[]
  ) {}
}
