import { ApolloQueryResult } from '@apollo/client/core';
import { Project } from '@ih/app/shared/apis/interfaces';

export class FindAllProjectsSuccess {
  public static readonly type =
    '[Organisation/Projects/List] FindAllProjectsSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllProjects: Project[];
    }>
  ) {}
}
