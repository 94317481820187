import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';

export function forbidSQLKeywordsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const blacklist = [
      /\bSELECT\b/i,
      /\bFROM\b/i,
      /\bUPDATE\b/i,
      /\bINSERT\b/i,
      /\bDELETE\b/i,
      /\bCREATE\b/i,
      /\bDROP\b/i,
      /\bALTER\b/i,
      /\bJOIN\b/i,
      /\bUNION\b/i,
      /\bWITH\b/i,
    ];
    let forbidden;
    for (const item in blacklist) {
      if (blacklist[item].test(control.value)) {
        forbidden = blacklist[item].test(control.value)
      }
    }
    return forbidden ? {forbiddenName: {value: control.value}} : null;
  };
}
