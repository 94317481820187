import { FirebaseError } from "firebase/app";

  /**
   * Displays a error toast and sets the registering status to false
   * @updated 18/03/2024 - 09:30:00
   * @public
   * @param ctx StateContext<RegisterStateModel>
   * @param userCredential UserCredential
   * @see libs/app/client/register/data-access/src/lib/states/register.state.ts
   */
export class RegisterErrorAction {
  public static readonly type = '[Register] RegisterErrorAction';
  constructor(public readonly error: FirebaseError | Error) {}
}
