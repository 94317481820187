export function displayTypeRender(type: string): string {
  const typeMap: Record<string, string> = {
    BOOLEAN: 'Yes/No',
    DATE: 'Date',
    NUMBER: 'Number',
    MULTIRADIO: 'Multi Radio Button',
    FILEUPLOAD: 'File Upload',
    PARAGRAPH: 'Paragraph',
    SHORT: 'Short Text',
    TIME: 'Time',
    DROPDOWN: 'Dropdown',
    USERINFO: 'User Info',
    MULTICHECKBOX: 'Multi Checkbox',
    LOCATION: 'Location',
    MARKDOWN: 'Content - Markdown',
    DEVICEINFO: 'Device Info',
    QR: 'QR Code'
  };

  const displayType = typeMap[type];
  return displayType ? displayType : type.toLowerCase();
}
