import { OrganisationMember, OrganisationPermission } from '@ih/app/shared/apis/interfaces';

export class SetOrganisationPermissionsAction {
  public static readonly type =
    '[Authorisation] SetOrganisationPermissionsAction';
  constructor(
    public readonly organisationMembers: OrganisationMember[],
    public readonly permissions: OrganisationPermission[]
  ) {}
}
