import { Injectable } from '@angular/core';
import {
  Auth,
  GoogleAuthProvider,
  OAuthProvider,
  User,
  UserCredential,
  applyActionCode,
  authState,
  browserLocalPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut,
  updateEmail,
  updatePassword,
  updateProfile,
  reauthenticateWithPopup
} from '@angular/fire/auth';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Navigate } from '@ngxs/router-plugin';
import { Store } from '@ngxs/store';
import { FirebaseError} from 'firebase/app';
import { Observable } from 'rxjs';
import {
  getAuth,
} from 'firebase/auth';

@Injectable()
export class AuthenticationService {
  constructor(private readonly afa: Auth, private readonly store: Store) {

   }
   provider : OAuthProvider | GoogleAuthProvider | null = null;

  authState(): Observable<User | null> {
    return authState(this.afa);
  }

  async register(displayName: string, email: string, password: string) {
    const userCredential = await createUserWithEmailAndPassword(
      this.afa,
      email,
      password
    );
    await updateProfile(userCredential.user, {
      displayName,
    });
    await sendEmailVerification(userCredential.user);

    return userCredential;
  }

  async loginWithEmail(
    email: string,
    password: string
  ): Promise<UserCredential> {
    return await this.afa
      .setPersistence(browserLocalPersistence)
      .then(() => signInWithEmailAndPassword(this.afa, email, password));
  }

  // async signInWithGoogle(): Promise<UserCredential> {
  //   return await this.afa
  //     .setPersistence(browserLocalPersistence)
  //     .then(() => signInWithPopup(this.afa, new GoogleAuthProvider()));
  // }

  async signInWithGoogle(): Promise<UserCredential> {
    // 1. Create credentials on the native layer
      const result = await FirebaseAuthentication.signInWithGoogle({
        skipNativeAuth: true,
        scopes: ['email', 'profile'],
      });    
      
      
      // 2. Sign in on the web layer using the id token
      const credential = GoogleAuthProvider.credential(
        result.credential?.idToken
      );
      this.provider = new GoogleAuthProvider()
      return await signInWithCredential(this.afa, credential);
  }

  async signInWithApple(): Promise<UserCredential> {
    // 1. Create credentials on the native layer
    const result = await FirebaseAuthentication.signInWithApple({
      skipNativeAuth: true,
    });
    // 2. Sign in on the web layer using the id token and nonce
    const provider = new OAuthProvider('apple.com');
    const credential = provider.credential({
      idToken: result.credential?.idToken,
      rawNonce: result.credential?.nonce,
    });
    const auth = getAuth();
    this.provider = provider
    return await signInWithCredential(auth, credential);
  }

  async logout() {
    // 1. Sign out on the native layer
      await FirebaseAuthentication.signOut();
      this.provider = null;
      // 1. Sign out on the web layer
      return await signOut(this.afa);
  }

  async getIdToken(): Promise<string | null> {
    const userPromise = new Promise<User | null>((resolve) => {
      this.afa.onAuthStateChanged((user) => resolve(user));
    });

    const authUser = await userPromise;
    if (!authUser) return null;


    return await authUser.getIdToken();
  }

  async updateUser(displayName: string): Promise<void> {
    const user = this.afa.currentUser;

    if (!user) {
      throw new Error('User is not logged in');
    }

    if (user.isAnonymous) {
      throw new Error('You need an account to update your account');
    }

    return await updateProfile(user, {
      displayName: displayName,
    }).catch((error) => {
      throw new Error(error);
    });
  }

  async updateProfilePicture(photoUrl: string): Promise<void> {
    const user = this.afa.currentUser;

    if (!user) {
      throw new Error('User is not logged in');
    }

    if (user.isAnonymous) {
      throw new Error('You need an account to update your account');
    }

    return await updateProfile(user, {
      photoURL: photoUrl,
    }).catch((error) => {
      throw new Error(error);
    });
  }

  async updateEmail(email: string): Promise<void> {
    const user = this.afa.currentUser;

    if (!user) {
      throw new Error('User is not logged in');
    }

    if (user.isAnonymous) {
      throw new Error('You need an account to update your account');
    }

    if ((await this.getUserEmail()) == email) {
      return;
    }

    return await updateEmail(user, email).catch((error) => {
      throw new Error(error);
    });
  }

  async getUserEmail(): Promise<string | null> {
    const user = await this.afa.currentUser;
    if (user != null) {
      return user.email;
    }
    return null;
  }

  async updatePassword(password: string): Promise<void> {
    const user = await this.afa.currentUser;
    if (!user) {
      throw new Error('User is not logged in');
    }

    await user.getIdToken()
    if (user.isAnonymous) {
      throw new Error('You need an account to update your password');
    }

    if(this.provider){
      try {
        await reauthenticateWithPopup(user,this.provider)
      } catch (error) {
        throw new Error('Invalid credentials supplied')
      }
    }
    try {
    await updatePassword(user, password);
    } catch (error) {
      if(error instanceof FirebaseError && error.code === 'auth/requires-recent-login')
      throw new Error('Login credentials expired. Please login again.')
    }
  }

  async forgot(email: string): Promise<void> {
    return await sendPasswordResetEmail(this.afa, email);
  }

  async reset(oobCode: string, password: string): Promise<void> {
    return await confirmPasswordReset(this.afa, oobCode, password);
  }

  async verify(oobCode: string): Promise<void> {
    return await applyActionCode(this.afa, oobCode);
  }

  // TODO: Remove after implementing proper unauthenticated user
  async checkPublicAccount(): Promise<void> {
    const email = await this.getUserEmail();
    if (email === 'anonymous@public.user') {
      await this.logout();
      this.store.dispatch(new Navigate(['/']));
    }
  }

  async redirectToLogin(): Promise<void> {
    const email = await this.getUserEmail();
    if (email === 'anonymous@public.user') {
      await this.logout();
      this.store.dispatch(new Navigate(['/']));
    }
  }
}
