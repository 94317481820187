import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { FindAllApis, ResetProjectApisList, SetApis } from '../actions';
import { FindAllApisError, FindAllApisSuccess } from '../effects';
import { catchError, tap } from 'rxjs';

import { Api } from '@ih/app/shared/apis/interfaces';
import { ApolloQueryResult } from '@apollo/client/core';
import { Injectable } from '@angular/core';
import { ToastService } from '@ih/app/client/shared/services';
import { produce } from 'immer';

export interface ListStateModel {
  apis: Api[];
}

@State<ListStateModel>({
  name: 'projectApisList',
  defaults: {
    apis: [],
  },
})
@Injectable()
export class ListState {
  constructor(
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static getState(state: ListStateModel) {
    return state;
  }

  @Selector()
  public static apis(state: ListStateModel): Api[] {
    return state.apis;
  }

  @Action(FindAllApis)
  public findAllApis(ctx: StateContext<ListStateModel>) {
    return this.apollo
      .watchQuery<{ findAllApis: Api[] }>({
        query: gql`
          query {
            findAllApis(request: {}) {
              id
              name
              description
            }
          }
        `,
      })
      .valueChanges.pipe(
        tap((result: ApolloQueryResult<{ findAllApis: Api[] }>) =>
          ctx.dispatch(new FindAllApisSuccess(result)),
        ),
        catchError((error) => ctx.dispatch(new FindAllApisError(error))),
      );
  }

  @Action(FindAllApisSuccess)
  public findAllApisSuccess(
    ctx: StateContext<ListStateModel>,
    { result }: FindAllApisSuccess,
  ) {
    const apis = (result?.data?.findAllApis || []) as Api[];
    ctx.dispatch(new SetApis(apis));
  }

  @Action(FindAllApisError)
  public findAllApisError(
    ctx: StateContext<ListStateModel>,
    { error }: FindAllApisError,
  ) {
    this.toastService.showError('Failed to fetch apis');
    console.error('Failed to fetch apis', error.message);
  }

  @Action(SetApis)
  public setApis(ctx: StateContext<ListStateModel>, { apis }: SetApis) {
    ctx.setState(
      produce((draft) => {
        draft.apis = apis;
      }),
    );
  }

  @Action(ResetProjectApisList)
  public resetProjectApisList(ctx: StateContext<ListStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.apis = [];
      }),
    );
  }
}
