import { Injectable } from '@angular/core';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { FindAllGroupPermissionsRequest, FindUniqueGroupRolePermissionsRequest, Group, GroupMember, GroupPermission } from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { catchError, EMPTY, map, Observable, of, take } from 'rxjs';

@Injectable()
export class GroupsService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  findUniqueGroup(groupId : string) : Observable<Group | null> {
    type ResultType = {
      findUniqueGroup: Group | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueGroup($id: String!) {
        findUniqueGroup(request: { id: $id }) {
          id
          name
          slug
          description
          visibility
          photoURL
          photoRef
          organisationId
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          id: groupId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              { findUniqueGroup: Group | null },
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findUniqueGroup || null;
          }
        ),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      );
      
  }

  findGroupMemberWithRolePermissions(
    userId: string,
    groupId: string
  ): Observable<GroupMember[]> {
    type ResultType = { findMeGroupMember: GroupMember[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindMeGroupMember($userId: String!, $groupId: String!) {
        findMeGroupMember(
          request: { where: { userId: $userId, groupId: $groupId } }
        ) {
          role
          groupId
          groupRoleId
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          userId,
          groupId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            return result.data?.findMeGroupMember || [];
          }
        ),
        catchError((error) => {
          console.error(error);
          return of([]);
        })
      );
  }

  findAllGroupPermissions(
    request: FindAllGroupPermissionsRequest
  ): Observable<GroupPermission[]> {
    type ResultType = { findAllGroupPermissions: GroupPermission[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllGroupPermissions($request: FindAllGroupPermissionsRequest!) {
        findAllGroupPermissions(request: $request) {
          id
          identifier
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            return result.data?.findAllGroupPermissions || [];
          }
        )
      );
  }

  findUniqueGroupRolePermissions(
    request: FindUniqueGroupRolePermissionsRequest
  ): Observable<GroupPermission[]> {
    type ResultType = { findUniqueGroupRolePermissions: GroupPermission[] | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllGroupPermissions($request: FindUniqueGroupRolePermissionsRequest!) {
        findUniqueGroupRolePermissions(request: $request) {
          id
          identifier
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            return result.data?.findUniqueGroupRolePermissions || [];
          }
        )
      );
  }
}
