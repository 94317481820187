import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AlertService,
  DashboardsService,
  ToastService,
} from '@ih/app/client/shared/services';
import {
  DashboardComponent,
  Dashboard,
  Project,
} from '@ih/app/shared/apis/interfaces';
import {
  ConfirmationCancel,
  DeleteDashboard,
  FindAllDashboards,
  FindDashboardCharts,
  RemoveDashboard,
  ResetProjectDashboardsList,
  SetDashboards,
  ShowDeleteConfirmation,
  TrashDashboard,
} from '../actions';
import {
  DeleteDashboardError,
  DeleteDashboardSuccess,
  FindAllDashboardsError,
  FindAllDashboardsSuccess,
  FindDashboardChartsError,
  FindDashboardChartsSuccess,
  TrashDashboardError,
  TrashDashboardSuccess,
} from '../effects';
import { catchError, tap } from 'rxjs';

import { Apollo } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { ProjectState } from '@ih/app/client/project/data-access';
import { produce } from 'immer';

export interface DashboardListStateModel {
  dashboards: Dashboard[];
}

@State<DashboardListStateModel>({
  name: 'projectDashboardsList',
  defaults: {
    dashboards: [],
  },
})
@Injectable()
export class ListState {
  constructor(
    private readonly apollo: Apollo,
    private readonly store: Store,
    private readonly alertService: AlertService,
    private readonly toastService: ToastService,
    private readonly dashboardsService: DashboardsService,
  ) {}

  @Selector()
  public static getState(state: DashboardListStateModel) {
    return state;
  }

  @Selector()
  public static dashboards(state: DashboardListStateModel): Dashboard[] {
    return state.dashboards;
  }

  @Action(FindAllDashboards)
  public findAllDashboards(ctx: StateContext<DashboardListStateModel>) {
    const project = this.store.selectSnapshot<Project | null>(
      ProjectState.project,
    );
    const projectId = project?.id || '';

    return this.dashboardsService
      .findAllDashboards({ where: { projectId: projectId, deleted: false } })
      .pipe(
        tap((dashboards: Dashboard[] | null) => {
          if (dashboards) {
            ctx.dispatch(new FindAllDashboardsSuccess(dashboards));
          }
        }),
        catchError((error) => ctx.dispatch(new FindAllDashboardsError(error))),
      );
  }

  @Action(FindAllDashboardsSuccess)
  public findAllDashboardsSuccess(
    ctx: StateContext<DashboardListStateModel>,
    { dashboards }: FindAllDashboardsSuccess,
  ) {
    ctx.dispatch(new SetDashboards(dashboards));
  }

  @Action(FindAllDashboardsError)
  public findAllDashboardsError(
    ctx: StateContext<DashboardListStateModel>,
    { error }: FindAllDashboardsError,
  ) {
    this.toastService.showError('Failed to fetch dashboards');
    console.error('Failed to fetch dashboards', error.message);
  }

  @Action(SetDashboards)
  public setDashboards(
    ctx: StateContext<DashboardListStateModel>,
    { dashboards }: SetDashboards,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.dashboards = dashboards;
      }),
    );
  }

  @Action(ResetProjectDashboardsList)
  public resetProjectDashboardsList(
    ctx: StateContext<DashboardListStateModel>,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.dashboards = [];
      }),
    );
  }

  // Used to check if board still has any charts if so do not delete
  @Action(FindDashboardCharts)
  public FindDashboardCharts(
    ctx: StateContext<DashboardListStateModel>,
    { id }: FindDashboardCharts,
  ) {
    return this.dashboardsService
      .findDashboardComponents({ where: { id, deleted: false } })
      .pipe(
        tap((charts: DashboardComponent[] | null) => {
          if (charts) {
            ctx.dispatch(new FindDashboardChartsSuccess(charts, id));
          }
        }),
        catchError((error) =>
          ctx.dispatch(new FindDashboardChartsError(error)),
        ),
      );
  }

  @Action(FindDashboardChartsSuccess)
  public findDashboardChartsSuccess(
    ctx: StateContext<DashboardListStateModel>,
    { charts, id }: FindDashboardChartsSuccess,
  ) {
    if (charts.length > 0) {
      const error: Error = Error(
        'Cannot delete dashboard if, the dashboard still contains charts',
      );
      return ctx.dispatch(new DeleteDashboardError(error));
    } else {
      return ctx.dispatch(new DeleteDashboard(id));
    }
  }

  @Action(FindDashboardChartsError)
  public findDashboardChartsError(
    ctx: StateContext<DashboardListStateModel>,
    { error }: FindDashboardChartsError,
  ) {
    this.toastService.showError('Failed to fetch dashboards');
    console.error('Failed to fetch dashboard charts', error.message);
  }

  @Action(ShowDeleteConfirmation)
  public showDeleteConfirmationAction(
    ctx: StateContext<DashboardListStateModel>,
    { dashboard }: ShowDeleteConfirmation,
  ) {
    this.alertService.showAlert(
      `You are about to delete this dashboard. Are you sure you want to continue?`,
      'Confirm?',
      undefined,
      [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ctx.dispatch(new ConfirmationCancel());
          },
        },
        {
          text: 'Trash',
          cssClass: 'primary',
          handler: () => {
            ctx.dispatch(new TrashDashboard(dashboard.id));
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            ctx.dispatch(new FindDashboardCharts(dashboard.id));
          },
        },
      ],
    );
  }

  @Action(ConfirmationCancel)
  public confirmationCancel() {
    this.alertService.hide();
  }

  @Action(TrashDashboard)
  public trashDashboard(
    ctx: StateContext<DashboardListStateModel>,
    { id }: TrashDashboard,
  ) {
    return this.dashboardsService
      .deleteDashboard({ id, permanent: false })
      .pipe(
        tap((dashboard: Dashboard | null) => {
          if (dashboard) {
            ctx.dispatch(new TrashDashboardSuccess(dashboard));
          }
        }),
        catchError((error) => ctx.dispatch(new TrashDashboardError(error))),
      );
  }

  @Action(TrashDashboardSuccess)
  public trashDashboardSuccess(
    ctx: StateContext<DashboardListStateModel>,
    { dashboard }: TrashDashboardSuccess,
  ) {
    if (dashboard) {
      this.toastService.showInfo(
        `Dashboard ${dashboard.name} successfully trashed.`,
      );
      return ctx.dispatch(new RemoveDashboard(dashboard.id));
    }

    return null;
  }

  @Action(RemoveDashboard)
  public removeDashboard(
    ctx: StateContext<DashboardListStateModel>,
    { id }: RemoveDashboard,
  ) {
    ctx.dispatch(new FindAllDashboards());
    return ctx.setState(
      produce((draft) => {
        draft.dashboards = draft.dashboards.filter(
          (dashboard) => dashboard.id !== id,
        );
      }),
    );
  }

  @Action(TrashDashboardError)
  public trashDashboardError(
    ctx: StateContext<DashboardListStateModel>,
    { error }: TrashDashboardError,
  ) {
    this.toastService.showError('Dashboard trashed failed: ' + error);
  }

  @Action(DeleteDashboard)
  public deleteDashboard(
    ctx: StateContext<DashboardListStateModel>,
    { id }: DeleteDashboard,
  ) {
    return this.dashboardsService.deleteDashboard({ id, permanent: true }).pipe(
      tap((dashboard: Dashboard | null) => {
        if (dashboard) {
          ctx.dispatch(new DeleteDashboardSuccess(dashboard));
        }
      }),
      catchError((error) => ctx.dispatch(new DeleteDashboardError(error))),
    );
  }

  @Action(DeleteDashboardSuccess)
  public deleteDashboardSuccess(
    ctx: StateContext<DashboardListStateModel>,
    { dashboard }: DeleteDashboardSuccess,
  ) {
    if (dashboard) {
      this.toastService.showInfo(
        `Dashboard ${dashboard.name} successfully deleted.`,'success',
      );
      return ctx.dispatch(new RemoveDashboard(dashboard.id));
    }

    return null;
  }

  @Action(DeleteDashboardError)
  public deleteDashboardError(
    ctx: StateContext<DashboardListStateModel>,
    { error }: TrashDashboardError,
  ) {
    this.toastService.showError('Dashboard delete failed');
    console.error('Failed to delete dashboard', error.message);
  }
}
