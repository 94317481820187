import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import { Project } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';
import {
  FindAllProjects,
  ResetOrganisationProjectsList,
  SetProjects,
} from '../actions';
import { FindAllProjectsError, FindAllProjectsSuccess } from '../effects';

export interface OrganisationProjectsListStateModel {
  projects: Project[];
}

@State<OrganisationProjectsListStateModel>({
  name: 'organisationProjectsList',
  defaults: {
    projects: [],
  },
})
@Injectable()
export class OrganisationProjectsListState {
  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static getState(state: OrganisationProjectsListStateModel) {
    return state;
  }

  @Selector()
  public static projects(state: OrganisationProjectsListStateModel): Project[] {
    return state.projects;
  }

  @Action(FindAllProjects)
  public findAllProjects(
    ctx: StateContext<OrganisationProjectsListStateModel>,
    { organisationId }: FindAllProjects,
  ) {
    if (!organisationId) {
      organisationId = this.store.selectSnapshot<string>(
        (state) => state.organisation.organisation.id,
      );
    }

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findAllProjects($organisationId: String!) {
        findAllProjects(
          request: {
            where: { organisationId: $organisationId, deleted: false }
          }
        ) {
          name
          description
          id
          slug
          photoURL
          groupLabel {
            slug
          }
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllProjects: Project[];
    }>({
      query,
      variables: {
        organisationId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap((result: ApolloQueryResult<{ findAllProjects: Project[] }>) => {
        if (result.data && networkStatus) {
          this.apollo.client.cache.writeQuery({
            query,
            data: result.data,
          });
        }
        ctx.dispatch(new FindAllProjectsSuccess(result));
      }),
      catchError((error) => ctx.dispatch(new FindAllProjectsError(error))),
    );
  }

  @Action(FindAllProjectsSuccess)
  public findAllProjectsSuccess(
    ctx: StateContext<OrganisationProjectsListStateModel>,
    { result }: FindAllProjectsSuccess,
  ) {
    const projects = (result?.data?.findAllProjects || []) as Project[];
    ctx.dispatch(new SetProjects(projects));
  }

  @Action(FindAllProjectsError)
  public findAllProjectsError(
    ctx: StateContext<OrganisationProjectsListStateModel>,
    { error }: FindAllProjectsError,
  ) {
    this.toastService.showError('Failed to fetch projects');
    console.error('Failed to fetch projects: ', error.message);
  }

  @Action(SetProjects)
  public setProjects(
    ctx: StateContext<OrganisationProjectsListStateModel>,
    { projects }: SetProjects,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.projects = projects;
      }),
    );
  }

  @Action(ResetOrganisationProjectsList)
  public resetOrganisationProjectsList(
    ctx: StateContext<OrganisationProjectsListStateModel>,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.projects = [];
      }),
    );
  }
}
