import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppClientSharedComponentsChatFeatureComponent } from './app-client-shared-components-chat-feature.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { NgxsModule } from '@ngxs/store';
import { ChatState } from '@ih/app/client/shared/components/chat/data-access';
export * from './app-client-shared-components-chat-feature.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
    NgxsFormPluginModule,
    NgxsModule.forFeature([ChatState]),
    FormsModule,
  ],
  declarations: [AppClientSharedComponentsChatFeatureComponent],
  exports: [AppClientSharedComponentsChatFeatureComponent],
})
export class AppClientSharedComponentsChatFeatureModule {}
