/**
 * Sets organisation permissions in MeStateModel.permissions.project
 * @updated 04/11/2024
 * @public
 * @param ctx StateContext<MeStateModel>
 * @param organisationPermissions string[]
 * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
 */export class SetOrganisationPermissions {
  public static readonly type = '[Me] SetOrganisationPermissions';
  constructor(
    public readonly organisationPermissions: string[],
    public readonly meta? : boolean
  ) {}
}
