import { FileExtra } from '@ih/app/client/shared/interfaces';
import {
  AlertService,
  StorageService,
  ToastService,
} from '@ih/app/client/shared/services';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, tap } from 'rxjs';
import {
  ConfirmationCancelAction,
  ConfirmZipAction,
  DeleteAction,
  DownloadFolderAction,
  ExtractZipAction,
  ListAllAction,
  ResetProjectStorageList,
  SetErrorAction,
  SetFileDownloadUrlAction,
  SetFileMetaAction,
  SetFilesAction,
  SetFoldersAction,
  SetParentAction,
  SetParentPathAction,
  SetPathAction,
  ShowDeleteConfirmationAction,
} from '../actions';
import {
  DeleteErrorEffect,
  DeleteSuccessEffect,
  DownloadSuccessEffect,
  ExtractZipErrorEffect,
  ExtractZipSuccessEffect,
  ListAllErrorEffect,
  ListAllSuccessEffect,
} from '../effects';

import { Injectable } from '@angular/core';
import {
  FullMetadata,
  getDownloadURL,
  getMetadata,
  ListResult,
  StorageReference,
} from '@angular/fire/storage';
import { produce } from 'immer';

export interface StorageBrowseStateModel {
  parent: string | null;
  parentPath: string | null;
  path: string | null;
  folders: StorageReference[];
  files: (StorageReference & FileExtra)[];
  error: Error | null;
}

@State<StorageBrowseStateModel>({
  name: 'projectStorageBrowse',
  defaults: {
    parent: null,
    parentPath: null,
    path: null,
    folders: [],
    files: [],
    error: null,
  },
})
@Injectable()
export class StorageBrowseState {
  constructor(
    private readonly store: Store,
    private readonly storageService: StorageService,
    private readonly alertService: AlertService,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static parent(state: StorageBrowseStateModel): string | null {
    return state.parent;
  }

  @Selector()
  public static parentPath(state: StorageBrowseStateModel): string | null {
    return state.parentPath;
  }

  @Selector()
  public static path(state: StorageBrowseStateModel): string | null {
    return state.path;
  }

  @Selector()
  public static folders(state: StorageBrowseStateModel): StorageReference[] {
    return state.folders;
  }

  @Selector()
  public static files(state: StorageBrowseStateModel): StorageReference[] {
    return state.files;
  }

  @Action(ListAllAction)
  public listAllAction(ctx: StateContext<StorageBrowseStateModel>) {
    const state = ctx.getState();
    const path = state.path;

    if (path) {
      return this.storageService.listAll(path).pipe(
        tap((result: ListResult) =>
          ctx.dispatch(new ListAllSuccessEffect(result)),
        ),
        catchError((error) => ctx.dispatch(new ListAllErrorEffect(error))),
      );
    }

    const error = new Error('Path not set');
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(ListAllSuccessEffect)
  public listAllSuccessEffect(
    ctx: StateContext<StorageBrowseStateModel>,
    { result }: ListAllSuccessEffect,
  ) {
    const folders = result.prefixes || [];
    const files = result.items || [];

    files.forEach((file) => {
      getMetadata(file).then((meta: FullMetadata) => {
        ctx.dispatch(new SetFileMetaAction(file, meta));
      });
    });

    files.forEach((file) => {
      getDownloadURL(file).then((downloadUrl: string) => {
        ctx.dispatch(new SetFileDownloadUrlAction(file, downloadUrl));
      });
    });

    return ctx.dispatch([
      new SetFoldersAction(folders),
      new SetFilesAction(files),
    ]);
  }

  @Action(ListAllErrorEffect)
  public listAllPagesErrorEffect(
    ctx: StateContext<StorageBrowseStateModel>,
    { error }: ListAllErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(SetPathAction)
  public setPathAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { path }: SetPathAction,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.path = path;
      }),
    );

    return this.store.dispatch([
      new SetParentPathAction(),
      new SetParentAction(),
      new ListAllAction(),
    ]);
  }

  @Action(SetParentAction)
  public setParentAction(ctx: StateContext<StorageBrowseStateModel>) {
    const state = ctx.getState();
    const path = state.path;
    let parent = state.parentPath;

    if (path) {
      const parts = path.split('/');
      parts.pop();

      if (parts.length > 0) {
        const index = parts.length - 1;
        parent = parts[index];
      }
    }

    return ctx.setState(
      produce((draft) => {
        draft.parent = parent;
      }),
    );
  }

  @Action(SetParentPathAction)
  public setParentPathAction(ctx: StateContext<StorageBrowseStateModel>) {
    const state = ctx.getState();
    const path = state.path;
    let parentPath = state.parentPath;

    if (path) {
      const parts = path.split('/');
      parts.pop();
      parentPath = parts.join('/');
    }

    return ctx.setState(
      produce((draft) => {
        draft.parentPath = parentPath;
      }),
    );
  }

  @Action(SetFoldersAction)
  public setFoldersAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { folders }: SetFoldersAction,
  ) {
    return ctx.setState(
      produce((draft) => {
        draft.folders = folders;
      }),
    );
  }

  /**
   * Sets the files inside StorageBrowseState to the parameter files
   * 
   * @param ctx 
   * @param files StorageReference[] 
   * @returns 
   */
  @Action(SetFilesAction)
  public setFilesAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { files }: SetFilesAction,
  ) {
    // Filter out all Keepme files from the files array
    files = files.filter((file) => !file.fullPath.includes('KEEPME.md'));
    
    return ctx.setState(
      produce((draft) => {
        draft.files = files;
      }),
    );
  }

  @Action(SetErrorAction)
  public setErrorAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { error }: SetErrorAction,
  ) {
    this.toastService.showError('Error occured');
    console.error('Storage Error: ', error.message);
    return ctx.setState(
      produce((draft) => {
        draft.error = error;
      }),
    );
  }

  @Action(SetFileMetaAction)
  public setFileMetaAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { file, meta }: SetFileMetaAction,
  ) {
    const state = ctx.getState();
    const files = state.files;

    return ctx.setState(
      produce((draft) => {
        draft.files = files.map((target_file) => {
          if (target_file.fullPath === file.fullPath) {
            target_file.meta = meta;
          }

          return target_file;
        });
      }),
    );
  }

  @Action(SetFileDownloadUrlAction)
  public setFileDownloadUrlAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { file, downloadUrl }: SetFileDownloadUrlAction,
  ) {
    const state = ctx.getState();
    const files = state.files;

    return ctx.setState(
      produce((draft) => {
        draft.files = files.map((target_file) => {
          if (target_file.fullPath === file.fullPath) {
            target_file.downloadUrl = downloadUrl;
          }

          return target_file;
        });
      }),
    );
  }

  @Action(ShowDeleteConfirmationAction)
  public showDeleteConfirmationAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { file }: ShowDeleteConfirmationAction,
  ) {
    this.alertService.showAlert(
      `You are about to delete "${file.fullPath}"". Are you sure you want to continue?`,
      'Confirm?',
      undefined,
      [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ctx.dispatch(new ConfirmationCancelAction());
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            ctx.dispatch(new DeleteAction(file));
          },
        },
      ],
    );
  }

  @Action(ConfirmationCancelAction)
  public ConfirmationCancelAction() {
    this.alertService.hide();
  }

  @Action(ConfirmZipAction)
  public confirmZipAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { file }: ExtractZipAction,
  ) {
    this.alertService.showAlert(
      `You are about to extract "${file.fullPath}"". Are you sure you want to continue?`,
      'Confirm?',
      undefined,
      [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ctx.dispatch(new ConfirmationCancelAction());
          },
        },
        {
          text: 'Extract',
          cssClass: 'primary',
          handler: async () => {
            try {
              this.toastService.showInfo(`Extracting zip`);
              ctx.dispatch(new ExtractZipAction(file));
            } catch (error) { 
              ctx.dispatch(new ExtractZipErrorEffect(error as Error));
            }
          },
        },
      ],
    );
  }

 @Action(ExtractZipAction)
  public async extractZipAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { file }: ExtractZipAction,
  ) {
    const folder = await this.storageService.extractZip({reference : file.fullPath});
    return ctx.dispatch(new ExtractZipSuccessEffect(folder));
  }

  @Action(ExtractZipSuccessEffect)
  public extractZipSuccessEffect(ctx: StateContext<StorageBrowseStateModel>,{folder} : ExtractZipSuccessEffect) {
    //Prune first part of string that ends with /
    const newFolder = folder.substring(folder.indexOf('/')+1);

    this.toastService.showInfo(`Zip extracted to ${newFolder}`,'success');
    return ctx.dispatch(new ListAllAction());
  }

  @Action(ExtractZipErrorEffect)
  public extractZipErrorEffect(
    ctx: StateContext<StorageBrowseStateModel>,
    { error }: ExtractZipErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(DeleteAction)
  public async deleteAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { file }: DeleteAction,
  ) {
    const fileExtra = file as StorageReference & FileExtra;
    if(fileExtra.downloadUrl) { // Is a file
    return this.storageService.delete(file.fullPath).pipe(
      tap(() => ctx.dispatch(new DeleteSuccessEffect())),
      catchError((error) => ctx.dispatch(new DeleteErrorEffect(error))),
    );
    }
    else  // Is a folder
    {
      try{
        await this.storageService.deleteFolder(file.fullPath);
       return ctx.dispatch(new DeleteSuccessEffect());
      }
      catch(error)
      {
        console.error(error);
        const err = new Error('Error deleting folder');
        return ctx.dispatch(new DeleteErrorEffect(err));
      }
    }
  }

  @Action(DownloadFolderAction)
  public  async downloadFolderAction(
    ctx: StateContext<StorageBrowseStateModel>,
    { folder }: DownloadFolderAction,
  ) {
    try {
      await this.storageService.downloadFolder(folder.name,folder.fullPath);
      return ctx.dispatch(new DownloadSuccessEffect());
    }
    catch(error)
    {
      console.error(error);
      const err = new Error('Error downloading folder');
      return ctx.dispatch(new SetErrorAction(err));
    }
  }


  @Action(DownloadSuccessEffect)
  public downloadSuccessEffect(ctx: StateContext<StorageBrowseStateModel>) {
    this.toastService.showInfo(`Successfully downloaded.`,'success');
    return ctx.dispatch(new ListAllAction());
  }


  @Action(DeleteSuccessEffect)
  public deleteSuccessEffect(ctx: StateContext<StorageBrowseStateModel>) {
    this.toastService.showInfo(`successfully deleted.`,'success');
    return ctx.dispatch(new ListAllAction());
  }

  @Action(DeleteErrorEffect)
  public deleteError(
    ctx: StateContext<StorageBrowseStateModel>,
    { error }: DeleteErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(ResetProjectStorageList)
  public resetProjectStorageList(ctx: StateContext<StorageBrowseStateModel>) {
    return ctx.setState(
      produce((draft) => {
          draft.parent = null;
          draft.parentPath = null;
          draft.path = null;
          draft.folders = [];
          draft.files = [];
          draft.error = null;
      }),
    );
  }
}
