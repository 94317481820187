import { Component, Input } from '@angular/core';
import { GetGroup } from '@ih/app/client/group/data-access';
import { GetOrganisationAction } from '@ih/app/client/organisation/data-access';
import { GetProject } from '@ih/app/client/project/data-access';
import { GetUserAction } from '@ih/app/client/user/data-access';
import {
  MenuState,
  SetMenuContexts,
} from '@ih/app/client/shared/components/menu-content/data-access';
import { MenuContext, MenuItem } from '@ih/app/client/shared/interfaces';
import {
  FindAllGroupPermissionsAction,
  FindAllOrganisationPermissionsAction,
  FindAllProjectPermissionsAction,
  FindGroupOrganisationProjectRoleAction,
  FindGroupProjectRoleAction,
  FindProjectGroupProjectRoleAction,
  FindProjectOrganisationProjectRoleAction,
  FindProjectRoleAction,
  GetMe,
  LogoutAction,
  SetGroupPermissionsAction,
  SetGroupRoleAction,
  SetOrganisationPermissionsAction,
  SetOrganisationRoleAction,
  SetProjectPermissionsAction,
  SetProjectRoleAction,
} from '@ih/app/client/shared/states';
import {
  ActionsExecuting,
  actionsExecuting,
} from '@ngxs-labs/actions-executing';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { AppClientSharedComponentsChatFeatureComponent } from '@ih/app/client/shared/components/chat/feature';

@Component({
  selector: 'ih-app-client-shared-components-menu-content-feature',
  template: `
    <ih-app-client-shared-components-menu-content-ui
      [menuId]="menuId"
      [busy]="busy$ | async"
      [menuItems]="menuItems$ | async"
      [menuContexts]="menuContexts$ | async"
      [enableLogout]="enableLogout"
      [enableContext]="enableContext"
      (logoutClick)="onLogoutClick()"
      (chatClick)="onChatClick()"
    ></ih-app-client-shared-components-menu-content-ui>
  `,
  styles: [],
})
export class AppClientSharedComponentsMenuContentFeatureComponent {
  @Select(MenuState.menuContexts) menuContexts$!: Observable<MenuContext[]>;
  @Select(MenuState.menuItems) menuItems$!: Observable<MenuItem[]>;
  @Select(
    actionsExecuting([
      /** Menu */
      SetMenuContexts,
      /** Getters */
      GetMe,
      GetOrganisationAction,
      GetGroup,
      GetProject,
      GetUserAction,
      /** Permissions */
      // Organisation:
      FindAllOrganisationPermissionsAction,
      SetOrganisationPermissionsAction,
      SetOrganisationRoleAction,
      // Group:
      FindAllGroupPermissionsAction,
      FindGroupOrganisationProjectRoleAction,
      FindGroupProjectRoleAction,
      SetGroupPermissionsAction,
      SetGroupRoleAction,
      // Project:
      FindAllProjectPermissionsAction,
      FindProjectGroupProjectRoleAction,
      FindProjectOrganisationProjectRoleAction,
      FindProjectRoleAction,
      SetProjectPermissionsAction,
      SetProjectRoleAction,
    ])
  )
  busy$!: Observable<ActionsExecuting>;

  @Input() menuId = '';
  @Input() busy!: ActionsExecuting;
  @Input() menuItems!: MenuItem[] | null;
  @Input() menuContexts!: MenuContext[] | null;
  @Input() enableLogout = false;
  @Input() enableContext = true;

  constructor(private readonly store: Store, private readonly modalController: ModalController) {}

  onLogoutClick() {
    this.store.dispatch(new LogoutAction());
  }

  async onChatClick() {
    const modal = await this.modalController.create({
      component: AppClientSharedComponentsChatFeatureComponent,
    });
    modal.present();
  }
}
