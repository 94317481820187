import { ApolloQueryResult } from '@apollo/client/core';
import { Form } from '@ih/app/shared/apis/interfaces';

export class FindAllFormsSuccess {
  public static readonly type = '[Project/Form/List] FindAllFormsSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllForms: Form[];
    }>
  ) {}
}
