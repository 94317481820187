import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ResetGroupProjectsList } from '@ih/app/client/group/projects/list/data-access';
import { ResetGroupUsersList } from '@ih/app/client/group/users/list/data-access';
import { Group } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, EMPTY, map, take } from 'rxjs';
import { GetGroup, ResetStates, SetGroup } from '../actions';

export interface GroupStateModel {
  group: Group | null;
}

@State<GroupStateModel>({
  name: 'group',
  defaults: {
    group: null,
  },
})
@Injectable()
export class GroupState {
  @Selector()
  public static getState(state: GroupStateModel) {
    return state;
  }

  @Selector()
  public static group(state: GroupStateModel): Group | null {
    return state.group;
  }

  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly router: Router
  ) {}

  @Action(GetGroup)
  public getGroup(ctx: StateContext<GroupStateModel>, { id }: GetGroup) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueGroup($id: String!) {
        findUniqueGroup(request: { id: $id }) {
          id
          name
          slug
          visibility
          description
          organisationId
          photoURL
          photoRef
          organisationLabel {
            name
            slug
            visibility
            id
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<{ findUniqueGroup: Group }>({
        query,
        variables: {
          id,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<{ findUniqueGroup: Group }>) => {
          this.store.dispatch(new ResetStates());
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          const group = result?.data?.findUniqueGroup as Group;
          ctx.dispatch(new SetGroup(group));
        }),
        catchError((error) => {
          console.error(error);
          this.router.navigate(['/error', 404]);
          return EMPTY;
        })
      );
  }

  @Action(SetGroup)
  public setGroup(ctx: StateContext<GroupStateModel>, { group }: SetGroup) {
    ctx.setState(
      produce((draft) => {
        draft.group = group;
      })
    );
  }

  @Action(ResetStates)
  public resetStates(ctx: StateContext<GroupStateModel>) {
    ctx.dispatch([new ResetGroupUsersList(), new ResetGroupProjectsList()]);
  }
}
