import { ApolloLink, NextLink, Operation } from '@apollo/client/core';
import * as cuid from 'cuid';
import { jobHistoryService } from '@ih/app/client/shared/services';
import stringify from 'fast-safe-stringify';
import { JobStatus } from '@ih/app/client/shared/interfaces';

// keep track of the queries that are not completed
// similar to opQueue in QueueLink library since the original is private
/**
 * Keeps track of the queries that are not completed in offline mode
 */
 class JobTrackerLink extends ApolloLink {
  
  private nextLink : NextLink | undefined = undefined;
  /**
   * This function is called when the link is executed, in other words whenever a query is made.
   * It keeps track of all queries made for the current session.
   * If the query fails, it will not immediately be retried, 
   * If the link is closed, the query is added to the queue.
   * If the link is closed and the query is executed, it is removed from the queue.
   * @updated 20/03/2024 - 08:22:19
   * @param operation The operation or query that is to be executed
   * @param forward  The next link in the chain, if there is one
   */
  request(operation: Operation, forward?: NextLink) {
    if (forward === undefined) {
      return null;
    }
    // If links remain consistent this is fine. If not, we need to update the link dynamically
    if(!this.nextLink) {
      this.nextLink = forward;
    }

    // TODO : Check if this operation type should be stored in the history
    const operationType = operation.operationName

    if(operationType === 'InsertDataFromForm') {
      const jobId = cuid();
      operation.extensions = { id : jobId }
      const context = operation.getContext();
      const contextJSON = stringify(context);
      operation.extensions.context = contextJSON;
      // stringify the context to avoid circular references
      const json = JSON.stringify(operation);

      const timestamp: string = JSON.stringify(new Date());

      const status = JobStatus.pending;
      jobHistoryService.add({
        jobId,
        status,
        operation : json,
        timestamp
      })
      return forward(operation).map((data) => {
        if(data.errors) {
          jobHistoryService.jobFailedUpdate(jobId, Array.from(data.errors));
        }
        else  {
          jobHistoryService.jobSucceededUpdate(jobId);
        }        
        return data;
      })
    }
    else
    return forward(operation)
  }

  /**
   * Resends the operation to the link for execution. If it succeeds, the job is marked as successful in the history.
   * Otherwise it is marked as failed. 
   * @updated 02/04/2024 - 11:49:55
   * @returns 
   */
  async retry(op : Operation) : Promise<boolean> { 
    let response = false;
    if(op) {
      if(!this.nextLink) return false;
        op.getContext = () => {
          return JSON.parse(op.extensions.context)
        }
        response = await new Promise((resolve) => {
          this.nextLink!(op).subscribe({
            next: (result) => {
              if (!result.errors) {
                jobHistoryService.jobSucceededUpdate(op.extensions.id);
                response = true;
                resolve(true);
              }
              else
              console.error(result.errors)
              resolve(false);
            },
            error: (error) => {
              console.error('Error occurred:', error);
              resolve(false)
            }
          });
        })
  }
  return response;
 }
}


export const jobLinkTracker = new JobTrackerLink();
