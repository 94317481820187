import { Injectable } from '@angular/core';
import {
  AuthenticationService,
  ToastService,
  UsersService,
} from '@ih/app/client/shared/services';
import { GetMe, SetRegisteringAction } from '@ih/app/client/shared/states';
import { Navigate } from '@ngxs/router-plugin';
import {
  Action,
  Actions,
  ofActionCompleted,
  Selector,
  State,
  StateContext,
} from '@ngxs/store';
import { produce } from 'immer';
import { take, tap } from 'rxjs';
import {
  RegisterErrorAction,
  RegisterSuccessAction,
  ToggleShowPasswordAction,
} from '../actions';
import { RegisterAction } from '../actions/register';
import { FirebaseError } from 'firebase/app';
import { firebaseAuthErrorMessages } from '@ih/app/client/shared/interfaces';

export interface RegisterFormModel {
  displayName: string;
  email: string;
  username: string;
  password: string;
}

export interface RegisterFormStateModel {
  model: RegisterFormModel;
  dirty: boolean;
  status: string;
}

export interface RegisterStateModel {
  form: RegisterFormStateModel;
  showPassword: boolean;
  errors: Error[];
}

const defaults: RegisterStateModel = {
  form: {
    model: {
      displayName: '',
      email: '',
      username: '',
      password: '',
    },
    dirty: false,
    status: '',
  },
  showPassword: false,
  errors: [],
};

@State<RegisterStateModel>({
  name: 'register',
  defaults,
})
@Injectable()
export class RegisterState {
  @Selector()
  public static showPassword(state: RegisterStateModel): boolean {
    return state.showPassword;
  }

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly usersService: UsersService,
    private readonly toastService: ToastService,
    private readonly actions$: Actions,
  ) {}

  /**
   * Toggles the showPassword state
   * @updated 18/03/2024 - 09:15:00
   * @public
   */
  @Action(ToggleShowPasswordAction)
  public toggleShowPasswordAction(ctx: StateContext<RegisterStateModel>) {
    const state = ctx.getState();
    const showPassword = state.showPassword;

    return ctx.setState(
      produce(ctx.getState(), (state: RegisterStateModel) => {
        state.showPassword = !showPassword;
      }),
    );
  }

  /**
   * Registers the user and syncs the user account with usersService. Sets the username if the userCredential has a user.
   * While this action is ongoing it sets the registering status to true, which is only set to false when the GetMe action is completed or failed.
   * @updated 18/03/2024 - 09:18:11
   * @public
   * @onSuccess Dispatch {@link registerSuccessAction}
   * @onFail Dispatch {@link registerErrorAction}
   */
  @Action(RegisterAction)
  public async registerAction(ctx: StateContext<RegisterStateModel>) {
    const state = ctx.getState();
    const displayName = state.form.model.displayName;
    const email = state.form.model.email;
    const username = state.form.model.username;
    const password = state.form.model.password;

    ctx.dispatch(new SetRegisteringAction(true));


    try{ 
    // Register the user
       const isValid = await this.usersService.verifyUserDetails({
        email,
        username,
      });

      if(isValid.data?.verifyUser.email){
        return ctx.dispatch(new RegisterErrorAction(new Error('Email already in use')));
      }

      if(isValid.data?.verifyUser.username){
        return ctx.dispatch(new RegisterErrorAction(new Error('Username already in use')));
      }


      const userCredential = await this.authenticationService.register(
        displayName,
        email,
        password,
      );

      // Sync user account
      try {
        await this.usersService.sync();
      } catch (error) {
        return ctx.dispatch(new RegisterErrorAction(error as Error));
      }
  


      // Set username
      if (userCredential.user) {
        await this.usersService.update({
          id: userCredential.user.uid,
          username,
        });
      }
      return ctx.dispatch(new RegisterSuccessAction(userCredential));
    } catch (error) {
      return ctx.dispatch(new RegisterErrorAction(error as FirebaseError));
    }
  }


  /**
   * Displays a success toast and navigates to the home page. Also sets the registering status to false
   * @updated 18/03/2024 - 09:25:15
   * @public
   * @param ctx StateContext<RegisterStateModel>
   * @param userCredential UserCredential
   */
  @Action(RegisterSuccessAction)
  public async registerSuccessAction(
    ctx: StateContext<RegisterStateModel>,
    { userCredential }: RegisterSuccessAction,
  ) {
    const displayName = userCredential.user?.displayName || 'user';
    this.toastService.showInfo(
      `Registration success! Welcome, ${displayName}!`,
    );

    this.actions$
      .pipe(
        ofActionCompleted(GetMe),
        tap(() => {
          ctx.dispatch(new Navigate(['/home']));
          ctx.dispatch(new SetRegisteringAction(false));
        }),
        take(1),
      )
      .subscribe();
  }

  /**
   * Displays a error toast and sets the registering status to false
   * @updated 18/03/2024 - 09:30:00
   * @public
   * @param ctx StateContext<RegisterStateModel>
   * @param userCredential UserCredential
   */
  @Action(RegisterErrorAction)
  public async registerErrorAction(
    ctx: StateContext<RegisterStateModel>,
    { error }: RegisterErrorAction,
  ) {
    let message = error.message;
    if(error instanceof FirebaseError){
       message = firebaseAuthErrorMessages[error.code] || error.message;
    }
    this.toastService.showError(message);
    ctx.dispatch(new SetRegisteringAction(false));
  }
}
