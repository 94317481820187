/**
 * Sets project permissions in MeStateModel.permissions.project
 * @updated 04/11/2024
 * @public
 * @param ctx StateContext<MeStateModel>
 * @param projectPermissions string[]
 * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
 */export class SetProjectPermissions {
  public static readonly type = '[Me] SetProjectPermissions';
  constructor(
    public readonly projectPermissions: string[],
  ) {}
}
