import { ApolloQueryResult } from '@apollo/client/core';
import { Group } from '@ih/app/shared/apis/interfaces';

export class FindAllGroupsSuccess {
  public static readonly type =
    '[Organisation/Group/List] FindAllGroupsSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{ findAllGroups: Group[] }>
  ) {}
}
