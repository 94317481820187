import { ApolloQueryResult } from '@apollo/client/core';
import { ProjectMember, ProjectRole } from '@ih/app/shared/apis/interfaces';

export class FindAllDefaultRolesSuccess {
  public static readonly type =
    '[Project/ProjectRoles/List] FindAllDefaultRolesSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      getBaseProjectRoles: ProjectRole[];
    }>,
    public readonly members: ProjectMember[]
  ) {}
}
