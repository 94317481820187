import {
  Job,
  RemoveTrackedQuery,
  TrackedMedia,
  TrackedQuery,
} from '@ih/app/client/shared/interfaces';
import Dexie, { Table } from 'dexie';

export class AppDB extends Dexie {
  trackedQuery!: Table<TrackedQuery, string>;
  removeTrackedQuery!: Table<RemoveTrackedQuery, string>;
  trackedMedia!: Table<TrackedMedia | null, string>;
  downloadedForms!: Table<any>;
  downloadedSharedForms!: Table<any>;
  queriesCache!: Table<Job>;

  constructor() {
    super('localDB');
    this.version(3).stores({
      trackedQuery: 'trackId',
      removeTrackedQuery: 'trackId',
      trackedMedia: 'trackId',
      downloadedForms: 'id',
      downloadedSharedForms: 'id',
      queriesCache: 'jobId',
    });
  }
}
export const db = new AppDB();

