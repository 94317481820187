import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  GetNotifications,
  NotificationsState,
  Refresh,
  Sync,
  SyncAll,
  Upload,
} from '@ih/app/client/shared/components/notifications/data-access';
import { TrackedMedia, TrackedQuery } from '@ih/app/client/shared/interfaces';
import { MediaQueriesSyncService } from '@ih/app/client/shared/services';
import { NetworkState } from '@ih/app/client/shared/states';
import { ActionsExecuting, actionsExecuting } from '@ngxs-labs/actions-executing';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import prettyBytes from 'pretty-bytes';
import { Observable, Subscription, take, tap } from 'rxjs';

@Component({
  selector: 'ih-app-client-shared-components-notifications-feature',
  template: `
    <ion-list>
      <ion-item-divider>
        <ion-label slot="start">Notifications</ion-label>
        <ion-button slot="end" fill="clear" (click)="refresh()">
          <ion-icon name="refresh-outline"></ion-icon>
        </ion-button>
      </ion-item-divider>
      <ng-container
        *ngIf="
          trackedQueries.length <= 0 &&
          notifications.length <= 0 &&
          trackedMedia.length <= 0
        "
      >
        <ion-item>
          <ion-label>
            <p>You have no new notifications.</p>
          </ion-label>
        </ion-item>
      </ng-container>
      <ng-container *ngIf="notifications.length > 0">
        <ion-item *ngFor="let notification of notifications">
          <ion-label
            ><p>{{ notification }}</p></ion-label
          >
        </ion-item>
      </ng-container>
      <ng-container *ngIf="trackedMedia.length > 0">
        <ion-item-divider>
          <ion-label slot="start"> Pending Media Upload </ion-label>
        </ion-item-divider>
        <ion-item>
          <ion-label>
            <p>Upload the files that you submitted</p>
            <p>while you were offline.</p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <p>Upload may use a lot of data,</p>
            <p>connection to Wi-Fi recommended</p>
            <p>for large files.</p>
          </ion-label>
        </ion-item>
        <ng-container *ngIf="trackedMedia.length > 0">
          <ion-item>
            <ion-label slot="start">
              <h3>{{ trackedMedia.length }} submissions</h3>
            </ion-label>
            <ion-label slot="end">
              <h3>{{ prettyBytesAdapter(totalFileSize) }}</h3>
            </ion-label>
          </ion-item>
        </ng-container>
        <ion-item-divider>
          <ion-button
            [disabled]="
              (networkStatus$ | async) === false || (busyUploading$ | async)
            "
            slot="end"
            fill="clear"
            (click)="upload()"
          >
            Upload
          </ion-button>
        </ion-item-divider>
        <ion-progress-bar
          type="indeterminate"
          *ngIf="busyUploading$ | async"
        ></ion-progress-bar>
      </ng-container>
      <ng-container *ngIf="trackedQueries.length > 0">
        <ion-item-divider>
          <ion-label slot="start"> Pending form submissions </ion-label>
        </ion-item-divider>
        <ion-item-divider>
          <ion-badge slot="start" *ngIf="trackedQueries.length > 0">{{
            trackedQueries.length
          }}</ion-badge>
          <ion-button
            [disabled]="(networkStatus$ | async) === false || (busy$ | async)"
            slot="end"
            fill="clear"
            (click)="syncAll()"
          >
            <ion-icon name="sync-outline" class="ion-margin-end"></ion-icon>
            Sync All
          </ion-button>
        </ion-item-divider>
        <ng-container *ngIf="trackedQueries.length > 0">
          <ion-item *ngFor="let trackedQuery of trackedQueries">
            <ion-label *ngIf="trackedQuery.name === 'InsertDataFromForm'">
              <h3>Form submission</h3>
              <p>{{ parseJson(trackedQuery.variablesJSON).formName }}</p>
            </ion-label>
            <ion-button
              [disabled]="(networkStatus$ | async) === false || (busy$ | async)"
              slot="end"
              fill="clear"
              (click)="sync(trackedQuery)"
            >
              <ion-icon name="sync-outline"></ion-icon>
            </ion-button>
          </ion-item>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="busy$ | async">
        <ion-item>
          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 50%"
              ></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text
                animated
                style="width: 70%"
              ></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 50%"
              ></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text
                animated
                style="width: 70%"
              ></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
        <ion-item>
          <ion-label>
            <h3>
              <ion-skeleton-text
                animated
                style="width: 50%"
              ></ion-skeleton-text>
            </h3>
            <p>
              <ion-skeleton-text
                animated
                style="width: 70%"
              ></ion-skeleton-text>
            </p>
          </ion-label>
        </ion-item>
      </ng-container>
    </ion-list>
  `,
  styles: [],
})
export class AppClientSharedComponentsNotificationsFeatureComponent
  implements OnInit, OnDestroy
{
  @Select(NotificationsState.trackedQueries) trackedQueries$!: Observable<
    TrackedQuery[]
  >;
  @Select(NotificationsState.trackedMedia) trackedMedia$!: Observable<
    TrackedMedia[]
  >;
  @Select(NotificationsState.notifications) notifications$!: Observable<
    string[]
  >;
  @Select(NetworkState.status) networkStatus$!: Observable<boolean | unknown>;

  @Select(actionsExecuting([GetNotifications]))
  busy$!: Observable<ActionsExecuting>;

  @Select(actionsExecuting([Upload]))
  busyUploading$!: Observable<ActionsExecuting>;

  trackedQueries: TrackedQuery[] = [];
  trackedQueriesSub$: Subscription;

  trackedMedia: TrackedMedia[] = [];
  trackedMediaSub$: Subscription;

  notifications: string[] = [];
  notificationsSub$: Subscription;

  // trackedMedia: TrackedMedia[] = [];
  totalFileSize = 0;

  constructor(
    private readonly store: Store,
    private readonly mediaQueriesSyncService: MediaQueriesSyncService,
    private readonly actions$: Actions
  ) {
    this.trackedQueriesSub$ = this.trackedQueries$
      .pipe(
        tap((trackedQueries: TrackedQuery[]) => {
          this.trackedQueries = trackedQueries;
        })
      )
      .subscribe();

    this.trackedMediaSub$ = this.trackedMedia$
      .pipe(
        tap((trackedMedia: TrackedMedia[]) => {
          this.trackedMedia = trackedMedia;
        })
      )
      .subscribe();

    this.notificationsSub$ = this.notifications$
      .pipe(
        tap((notifications: string[]) => {
          this.notifications = notifications;
        })
      )
      .subscribe();

    this.getTotalFileSize();
  }

  ngOnInit(): void {
    this.store.dispatch(new GetNotifications());
  }

  refresh() {
    this.store.dispatch(new Refresh());

    this.getTotalFileSize();
  }

  sync(trackedQuery: TrackedQuery) {
    this.store.dispatch(new Sync(trackedQuery));
  }

  syncAll() {
    this.store.dispatch(new SyncAll());
  }

  upload() {
    this.actions$
      .pipe(
        ofActionCompleted(Upload),
        tap(() => {
          this.store.dispatch(new Refresh());
        }),
        take(1)
      )
      .subscribe();

    this.store.dispatch(new Upload());
  }

  getTotalFileSize() {
    this.mediaQueriesSyncService.getTotalFileSize().then((size) => {
      this.totalFileSize = size;
    });
  }

  prettyBytesAdapter(size: number) {
    return prettyBytes(size);
  }

  parseJson(data: string): any {
    return JSON.parse(data);
  }

  ngOnDestroy() {
    if (this.trackedQueriesSub$ && !this.trackedQueriesSub$.closed) {
      this.trackedQueriesSub$.unsubscribe();
    }
    if (this.trackedMediaSub$ && !this.trackedMediaSub$.closed) {
      this.trackedMediaSub$.unsubscribe();
    }
    if (this.notificationsSub$ && !this.notificationsSub$.closed) {
      this.notificationsSub$.unsubscribe();
    }
  }
}
