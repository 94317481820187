import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuState } from '@ih/app/client/shared/components/menu-content/data-access';
import { AppClientSharedComponentsMenuContentUiModule } from '@ih/app/client/shared/components/menu-content/ui';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { AppClientSharedComponentsMenuContentFeatureComponent } from './app-client-shared-components-menu-content-feature.component';
import { ChatState } from '@ih/app/client/shared/components/chat/data-access';
@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AppClientSharedComponentsMenuContentUiModule,
    NgxsModule.forFeature([MenuState, ChatState]),
  ],
  declarations: [AppClientSharedComponentsMenuContentFeatureComponent],
  exports: [AppClientSharedComponentsMenuContentFeatureComponent],
})
export class AppClientSharedComponentsMenuContentFeatureModule {}
