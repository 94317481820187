import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  constructor(private readonly swUpdate: SwUpdate) {}

  available$(): Observable<VersionEvent> {
    return this.swUpdate.versionUpdates;
  }

  activated(): Promise<boolean> {
    return this.swUpdate.activateUpdate();
  }

  checkForUpdate(): Promise<boolean> {
    return this.swUpdate.checkForUpdate();
  }
}
