import { Injectable } from '@angular/core';
import { TrackedQuery } from '@ih/app/client/shared/interfaces';
import { ToastService } from '@ih/app/client/shared/services';
import { Apollo, gql } from 'apollo-angular';
import { catchError, tap, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TrackQueriesSyncService {
  private trackedQueries: TrackedQuery[] = [];

  constructor(
    private readonly apollo: Apollo,
    private readonly toastService: ToastService
  ) {}

  syncAll(payload: TrackedQuery[]) {
    this.trackedQueries = payload;
    this.trackedQueries.forEach((trackedQuery) => {
      this.sync(trackedQuery);
    });
  }

  sync(trackedQuery: TrackedQuery) {
    // TODO: Check that is it InsertDataFromForm
    if (trackedQuery.name === 'InsertDataFromForm') {
      const mutation = gql`
        mutation InsertDataFromForm(
          $tableId: String!
          $formName: String!
          $submitterEmail: String!
          $rows: [DataRowInput!]!
        ) @serialize(key: ["serialiseMutation"]) {
          insertDataFromForm(
            request: {
              tableId: $tableId
              rows: $rows
              formName: $formName
              submitterEmail: $submitterEmail
            }
          ) {
            projectId
          }
        }
      `;

      this.apollo
        .mutate({
          mutation,
          variables: JSON.parse(trackedQuery.variablesJSON),
        })
        .pipe(
          tap(() =>
            this.toastService.showInfo('Successfully synced', 'tertiary')
          ),
          catchError((error) =>
            this.toastService.showError('Sync failed:' + error.message)
          ),
          take(1)
        )
        .subscribe();
    }
  }
}
