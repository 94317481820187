/**
 * Displays a failure toast and logs it to console
 * @updated 18/03/2024 - 09:22:11
 * @public
 * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
 */
export class GetMeError {
  public static readonly type = '[Me] GetMeError';
  constructor(public readonly error: Error) {}
}
