import { ApolloQueryResult } from '@apollo/client/core';
import { ProjectMember } from '@ih/app/shared/apis/interfaces';

export class FindAllProjectMembersSuccess {
  public static readonly type =
    '[Project/ProjectMembers/List] FindAllProjectMembersSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllProjectMembers: ProjectMember[];
    }>
  ) {}
}
