import { ApolloQueryResult } from '@apollo/client/core';
import { OrganisationMember, OrganisationRole } from '@ih/app/shared/apis/interfaces';

export class FindAllDefaultRolesSuccess {
  public static readonly type =
    '[Organisation/OrganisationRoles/List] FindAllDefaultRolesSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      listCoreOrganisationRoles: OrganisationRole[];
    }>,
    public readonly members: OrganisationMember[]
  ) {}
}
