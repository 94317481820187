import {
  OrganisationMember,
  OrganisationRole,
} from '@ih/app/shared/apis/interfaces';

export class SetOrganisationMembers {
  public static readonly type =
    '[Organisation/OrganisationMember/List] SetOrganisationMembers';
  constructor(
    public readonly organisationMembers: OrganisationMember[],
    public readonly organisationRoles: OrganisationRole[]
  ) {}
}
