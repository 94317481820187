import { Injectable } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  FindUniqueFormRequest,
  Form,
  InsertDataFromFormRequest,
  Question,
  QuestionType,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';

// Where to put this?
const basicQuestionTypes = [
  QuestionType.SHORT,
  QuestionType.PARAGRAPH,
  QuestionType.DROPDOWN,
  QuestionType.BOOLEAN,
  QuestionType.DATE,
  QuestionType.TIME,
  QuestionType.NUMBER,
  QuestionType.MULTIRADIO,
  QuestionType.USERINFO,
  QuestionType.DEVICEINFO,
  QuestionType.QR
];

@Injectable()
export class FormsService {
  constructor(
    private readonly apollo: Apollo,
    private readonly fb: UntypedFormBuilder,
    private readonly store: Store,
  ) {}

  extractRowColumns(form: Form | null, answers: Record<string, unknown>) : 
{ 
  tableId: string;
  columnId: string;
  value: any;
}[]
 {
    const rowColumns: {
      tableId: string;
      columnId: string;
      value: any;
    }[] = [];

    form?.sections?.forEach((section) => {
      section?.questions?.forEach((question) => {
        if (question?.type && basicQuestionTypes.includes(question?.type)) 
        {
          const parsedTableId = JSON.parse(question?.configuration).tableId;
          const parsedColumnId = JSON.parse(question?.configuration).columnId;

          if (
            parsedTableId &&
            parsedColumnId &&
            String(answers[parsedColumnId]) !==
              'null'
          ) {
            rowColumns.push({
              tableId : parsedTableId,
              columnId : parsedColumnId,
              value: answers[parsedColumnId] + '',
            });
          }
        }
        if (question?.type === QuestionType.MULTICHECKBOX) {
          if (JSON.parse(question?.configuration).options) {
            JSON.parse(question?.configuration).options.forEach(
              (option: any) => {
                if (
                  option.tableId &&
                  option.columnId &&
                  answers[option.columnId]
                ) {
                  rowColumns.push({
                    tableId: option.tableId,
                    columnId: option.columnId,
                    value: answers[option.columnId] + '',
                  });
                }
              },
            );
          }
        }
        if (question?.type === QuestionType.LOCATION) {
          const parsedLatitudeTableId = JSON.parse(question?.configuration).latitude?.tableId;
          const parsedLatitudeColumnId = JSON.parse(question?.configuration).latitude?.columnId;
          if (
            parsedLatitudeTableId &&
            parsedLatitudeColumnId &&
            answers[parsedLatitudeColumnId]
          ) {
            rowColumns.push({
              tableId: parsedLatitudeTableId,
              columnId: parsedLatitudeColumnId,
              value:
                answers[parsedLatitudeColumnId] +
                '',
            });
          }

          const parsedLongitudeTableId = JSON.parse(question?.configuration).longitude?.tableId;
          const parsedLongitudeColumnId = JSON.parse(question?.configuration).longitude?.columnId;
          if (
            parsedLongitudeTableId &&
            parsedLongitudeColumnId &&
            answers[parsedLongitudeColumnId]
          ) {
            rowColumns.push({
              tableId: parsedLongitudeTableId,
              columnId: parsedLongitudeColumnId,
              value:
                answers[
                  parsedLongitudeColumnId
                ] + '',
            });
          }
        }
        if (question?.type === QuestionType.FILEUPLOAD) {
          const parsedTableId = JSON.parse(question?.configuration).tableId
          const parsedColumnId = JSON.parse(question?.configuration).columnId

          if (
            parsedTableId &&
            parsedColumnId &&
            answers[parsedColumnId]
          ) {
            rowColumns.push({
              tableId: parsedTableId,
              columnId: parsedColumnId,
              value:
                "'" +
                answers[parsedColumnId] +
                "'" +
                '',
            });
          }
        }
      });
    });

    return rowColumns;
  }

  findUniqueForm(request: FindUniqueFormRequest): Observable<Form | null> {
    type ResultType = { findUniqueForm: Form | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueForm($request: FindUniqueFormRequest!) {
        findUniqueForm(request: $request) {
          id
          name
          slug
          emailList
          layout
          configuration
          visibility
          project {
            id
            tables {
              id
              name
              columns {
                id
                name
              }
            }
          }
          sections {
            id
            name
            order
            questions {
              id
              name
              type
              configuration
              description
              content
              required
            }
          }
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >,
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findUniqueForm || null;
          },
        ),
      );
  }


  /**
   * Creates an apollo mutation to insert the data into the db. 
   * To see the offline functionality of this, see the middelware at {@offline}
   * @offline apps/app/go/src/app/graphql.module.ts
   * @param param0 
   * @returns 
   */
  InsertDataFromForm({
    tableId,
    rows,
    formName,
    submitterEmail,
    formId,
    projectId,
  }: InsertDataFromFormRequest): Observable<any> {
    // const projectId = this.store.selectSnapshot<Project | null>(
    //   (state) => state.project.project.id
    // );

    // const query = gql`
    //   query FindDataRows(
    //     $tableId: String!
    //     $cursor: String
    //     $limit: Int!
    //     $orderBy: String!
    //     $orderByType: DataType!
    //     $search: String
    //   ) {
    //     findDataRows(
    //       request: {
    //         tableId: $tableId
    //         cursor: $cursor
    //         limit: $limit
    //         orderBy: $orderBy
    //         orderByType: $orderByType
    //         search: $search
    //       }
    //     ) {
    //       rows {
    //         columns {
    //           columnId
    //           value
    //         }
    //       }
    //       cursor
    //     }
    //   }
    // `;

    return this.apollo
      .mutate({
        mutation: gql`
          mutation InsertDataFromForm(
            $tableId: String!
            $formName: String!
            $submitterEmail: String!
            $rows: [DataRowInput!]!
            $formId: String!
            $projectId: String!
          ) @serialize(key: ["serialiseMutation"]) {
            insertDataFromForm(
              request: {
                tableId: $tableId
                rows: $rows
                formName: $formName
                submitterEmail: $submitterEmail
                formId: $formId
                projectId: $projectId
              }
            ) {
              projectId
            }
          }
        `,
        variables: {
          tableId,
          rows,
          formName,
          submitterEmail,
          formId,
          projectId,
        },


        // TODO: adding mutation data to cache to show in table
        // - consider generic
        // - consider multiple table cache to edit

        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   insertDataFromForm: {
        //     __typename: 'DataRowsResponse',
        //     projectId,
        //   },
        // },
        // update: (proxy) => {
        //   // Read the data from our cache for this query.
        //   const data = proxy.readQuery({
        //     query: query,
        //   }) as any;

        //   if (data) {
        //     console.warn(data);

        //     // Setting the primary columnm, currently only for auto increment
        //     // and retrieving data from exisitng data, need init
        //     const cursor = {
        //       columnId: data.findDataRows.rows[0].columns[0].columnId,
        //       value: (
        //         parseInt(data.findDataRows.rows[0].columns[0].value) + 1
        //       ).toString(),
        //     };

        //     // Adding the cursor
        //     rows[0].columns.unshift(cursor);

        //     // For some reason unshifting makes duplicates
        //     // Removing dups
        //     if (rows[0].columns[0].value === rows[0].columns[1].value) {
        //       rows[0].columns.splice(0, 1);
        //     }

        //     console.warn(rows);

        //     // deep copy of query data
        //     const findDataRows = {
        //       cursor: data.findDataRows.cursor,
        //       rows: data.findDataRows.rows.map((i: any) => {
        //         return i;
        //       }),
        //       __typename: 'DataRowsResponse',
        //     };

        //     console.warn(findDataRows);

        //     // Adding new data to object to cache
        //     if (findDataRows) {
        //       findDataRows.rows.unshift(rows[0]);
        //     }

        //     console.warn(findDataRows);

        //     // Write to cache
        //     proxy.updateQuery({ query }, () => ({
        //       findDataRows,
        //     }));
        //   }
        // },
      })

      .pipe(map((result) => result));


  }

  /**
   * Creates a untyped form group from the form layout using the configurations and types specified
   * @param formLayout$ 
   * @updated 18/04/2024 - 10:04:23
   * @returns 
   */
  formGroupFromLayout(formLayout$: Observable<Form>) : UntypedFormGroup {
    const formGroup = this.fb.group({});
    formLayout$.forEach((form) => {
      form?.sections?.forEach((section) => {
        section?.questions?.forEach((question) => {
          //TODO : Make this cleaner
          let defaultValue: unknown = '';
          if (question?.type === QuestionType.BOOLEAN) defaultValue = false;
          // If there is a question set up a formControl for it
          if (question) {
            const questionFC = new UntypedFormControl({
              value: defaultValue,
              disabled: false,
            });
            questionFC.addValidators(validatorsByType(question));

            // If the question is of the basic type add the control
            if (
              (question.type && (basicQuestionTypes.includes(question?.type) || question?.type === "FILEUPLOAD")) 
               &&
               
              JSON.parse(question.configuration).columnId
            ) {
              formGroup.addControl(
                JSON.parse(question.configuration).columnId,
                questionFC,
              );
            }
            // If the question is of the more complex type loop through options
            if (question?.type === QuestionType.MULTICHECKBOX) {
              JSON.parse(question.configuration).options.forEach(
                (option: any) => {
                  if (option.columnId) {
                    const questionOptionFC = new UntypedFormControl({
                      value: false,
                      disabled: false,
                    });
                    formGroup.addControl(option.columnId, questionOptionFC);
                  }
                },
              );
            }

            // If the question is of the Location type then add Lat and Long
            if (question?.type === QuestionType.LOCATION) {
              const editCoordId =
                JSON.parse(question.configuration).latitude.columnId +
                '_' +
                JSON.parse(question.configuration).longitude.columnId;
              formGroup.addControl(editCoordId, new UntypedFormControl(false));
              const questionLatFC = new UntypedFormControl({
                value: '',
                disabled: false,
              });
              formGroup.addControl(
                JSON.parse(question.configuration).latitude.columnId,
                questionLatFC,
              );
              const questionLongFC = new UntypedFormControl({
                value: '',
                disabled: false,
              });
              formGroup.addControl(
                JSON.parse(question.configuration).longitude.columnId,
                questionLongFC,
              );
            }
          }
        });
      });
    });
    return formGroup;
  }
}

/**
 * Configures the validators for the form based on the question type and configuration specified
 * Note : Regex is not implemented for all types, and does not work for NUMBER type. 
 * TODO : Fix or remove Number regex
 * @updated 18/04/2024 - 10:01:45
 * @param question 
 * @returns 
 */
function validatorsByType(question: Question): ValidatorFn[] {
  const configArray = JSON.parse(question.configuration);
  const validatorArray = [];
  switch (question.type) {
    case 'SHORT': {
      if (configArray.maxLength) {
        validatorArray.push(Validators.maxLength(configArray.maxLength));
      }
      break;
    }
    case 'PARAGRAPH': {
      if (configArray.maxLength) {
        validatorArray.push(Validators.maxLength(configArray.maxLength));
      }
      break;
    }
    case 'NUMBER': {
      const numericNumberReg = '^-?[0-9]\\d*(\\.\\d{1,2})?$';
      validatorArray.push(Validators.pattern(numericNumberReg));
      if (configArray.max) {
        validatorArray.push(Validators.max(configArray.max));
      }
      if (configArray.min) {
        validatorArray.push(Validators.min(configArray.min));
      }
      break;
    }
    case 'DROPDOWN': {
      //TODO: add regex for DROPDOWN?
      break;
    }
    case 'DATE': {
      //TODO: add regex for DATE format?
      break;
    }
    case 'TIME': {
      //TODO: add regex for TIME format?
      break;
    }
    case 'BOOLEAN': {
      //TODO: add regex for BOOLEAN?
      break;
    }
    case 'MULTIRADIO': {
      //TODO: add regex for MULTIRADIO?
      break;
    }
    case 'MULTICHECKBOX': {
      //TODO: add regex for MULTICHECKBOX?
      break;
    }
  }
  if (question.required) {
    validatorArray.push(Validators.required);
  }
  return validatorArray;
}
