import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import { RedirectGuard } from '@ih/app/client/shared/services';

const redirectLoggedOut = () => redirectUnauthorizedTo(['']);
const redirectLoggedIn = () => redirectLoggedInTo(['home']);

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/landing/feature').then(
        (m) => m.AppClientLandingFeatureModule
      ),
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/go/home/feature').then((m) => m.AppGoHomeFeatureModule),
  },
  {
    path: 'app/:appId',
    // canActivate: [AngularFireAuthGuard],
    // data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/app/feature').then(
        (m) => m.AppClientAppFeatureModule
      ),
  },
  {
    path: 'jobs',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/jobs/feature').then(
        (m) => m.AppClientJobsFeatureModule
      ),
  },
  {
    path: 'login',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/login/feature').then(
        (m) => m.AppClientLoginFeatureModule
      ),
  },
  {
    path: 'register',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/register/feature').then(
        (m) => m.AppClientRegisterFeatureModule
      ),
  },
  {
    path: 'forgot',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/forgot/feature').then(
        (m) => m.AppClientForgotFeatureModule
      ),
  },
  {
    path: 'reset',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedIn },
    loadChildren: () =>
      import('@ih/app/client/reset/feature').then(
        (m) => m.AppClientResetFeatureModule
      ),
  },
  {
    path: 'verify',
    loadChildren: () =>
      import('@ih/app/client/verify/feature').then(
        (m) => m.AppClientVerifyFeatureModule
      ),
  },
  {
    path: 'redirect',
    loadChildren: () =>
      import('@ih/app/client/redirect/feature').then(
        (m) => m.AppClientRedirectFeatureModule
      ),
  },
  {
    path: 'getting-started',
    canActivate: [AuthGuard],
    data: { authGuardPipe: redirectLoggedOut },
    loadChildren: () =>
      import('@ih/app/client/getting-started/feature').then(
        (m) => m.AppClientGettingStartedFeatureModule
      ),
  },
  {
    path: 'terms-and-conditions',
    loadChildren: () =>
      import('@ih/app/client/terms-and-conditions/feature').then(
        (m) => m.AppClientTermsAndConditionsFeatureModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('@ih/app/client/privacy-policy/feature').then(
        (m) => m.AppClientPrivacyPolicyFeatureModule
      ),
  },
  {
    path: 'docs',
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: 'https://docs.informationhub.io',
    },
  },
  {
    path: 'errors/:code',
    loadChildren: () =>
      import('@ih/app/client/errors/feature').then(
        (m) => m.AppClientErrorsFeatureModule
      ),
  },
  {
    path: '**',
    redirectTo: 'errors/404',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppGoShellFeatureRoutingModule {}
