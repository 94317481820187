import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { HideMenu, ShowMenu } from '../actions';

export interface MenuDisplayStateModel {
  showMenu: boolean;
}

const defaults: MenuDisplayStateModel = {
  showMenu: false,
};

@State<MenuDisplayStateModel>({
  name: 'menuDisplay',
  defaults,
})
@Injectable()
export class MenuDisplayState {
  @Selector()
  public static showMenu(state: MenuDisplayStateModel): boolean {
    return state.showMenu;
  }

  @Action(ShowMenu)
  public showMenu(ctx: StateContext<MenuDisplayStateModel>) {
    ctx.setState(
      produce((draft) => {
        if (draft.showMenu !== true) draft.showMenu = true;
      })
    );
  }

  @Action(HideMenu)
  public hideMenu(ctx: StateContext<MenuDisplayStateModel>) {
    ctx.setState(
      produce((draft) => {
        if (draft.showMenu !== false) draft.showMenu = false;
      })
    );
  }
}
