import { ApolloQueryResult } from '@apollo/client/core';
import { OrganisationMember, OrganisationRole } from '@ih/app/shared/apis/interfaces';

export class FindAllOrganisationRolesSuccess {
  public static readonly type =
    '[Organisation/OrganisationRoles/List] FindAllOrganisationRolesSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllOrganisationRoles: OrganisationRole[];
    }>,
    public readonly members: OrganisationMember[],
    public readonly defaultRoles: OrganisationRole[]
  ) {}
}
