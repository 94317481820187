import { ApolloLink, NextLink, Operation } from '@apollo/client/core';
import { TrackQueriesService } from '@ih/app/client/shared/services';
import * as cuid from 'cuid';
import stringify from 'fast-safe-stringify';

export class TrackerLink extends ApolloLink {
  trackQueriesService = new TrackQueriesService();

  request(operation: Operation, forward?: NextLink) {
    if (forward === undefined) {
      return null;
    }

    const queryJSON: string = JSON.stringify(operation.query);

    const operationType = JSON.parse(queryJSON).definitions[0].operation;

    let trackId = cuid();
    const name: string = operation.operationName;

    // only if the query is a mutation
    if (operationType === 'mutation') {
      if (operation.variables.tracked) {
        trackId = operation.variables.trackId;
      } else {
        operation.variables.tracked = true;
        operation.variables.trackId = trackId;
      }

      const context = operation.getContext();
      const variablesJSON: string = JSON.stringify(operation.variables);

      // stringify the context to avoid circular references
      const contextJSON = stringify(context);

      // this.trackQueriesService.add({
      //   contextJSON,
      //   trackId,
      //   name,
      //   queryJSON,
      //   variablesJSON,
      // });
    }

    return forward(operation).map((data) => {
      // this.trackQueriesService.remove(trackId);
      if (operationType === 'mutation') {
        // this.trackQueriesService.addToRemove(trackId);
      }
      return data;
    });
  }
}
