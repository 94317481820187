import { ApolloQueryResult } from '@apollo/client/core';
import { ProjectMember, ProjectRole } from '@ih/app/shared/apis/interfaces';

export class FindAllProjectRolesSuccess {
  public static readonly type =
    '[Project/ProjectRoles/List] FindAllProjectRolesSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllProjectRoles: ProjectRole[];
    }>,
    public readonly members: ProjectMember[],
    public readonly defaultRoles: ProjectRole[]
  ) {}
}
