import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({ providedIn: 'root' })
export class PermissionService {
  async checkPermission() {
    return Geolocation.checkPermissions();
  }

  async requestPermission() {
    return Geolocation.requestPermissions();
  }
}
