import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { DismissLoader, PresentLoader } from '../actions';
import { PresentLoaderSuccess } from '../effects';

export interface LoaderStateModel {
  overlay: boolean;
}

const defaults: LoaderStateModel = {
  overlay: false,
};

@State<LoaderStateModel>({
  name: 'loader',
  defaults,
})
@Injectable()
export class LoaderState {
  constructor(private readonly loadingController: LoadingController) {}

  @Selector()
  public static overlay(state: LoaderStateModel): boolean {
    return state.overlay;
  }

  @Action(DismissLoader)
  public dimissLoader(ctx: StateContext<LoaderStateModel>) {
    // if (ctx.getState().overlay === true) {
    ctx.setState(
      produce((draft) => {
        draft.overlay = false;
      })
    );
    this.loadingController.dismiss();
    // }
  }

  @Action(PresentLoader)
  public async presentLoader(ctx: StateContext<LoaderStateModel>) {
    // if (ctx.getState().overlay === false) {
    const loading = await this.loadingController.create({
      message: 'Please wait...',
    });
    await loading
      .present()
      .then(() => ctx.dispatch(new PresentLoaderSuccess()));
    // }
  }

  @Action(PresentLoaderSuccess)
  public presentLoaderSuccess(ctx: StateContext<LoaderStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.overlay = true;
      })
    );
  }
}
