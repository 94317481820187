/**
 * Selecting what NGXS states to be cached
 * This is needed to prevent caching of states that will cause issues if cached
 * Currently there is no way to only specify exclusions
 * Whenever a new state is added, it should be added to this array if it needs to be cached
 * Do not cache the following:
 * - ngxs_actions_executed
 * - ngxs_actions_executing
 * + List additional exclusions here
 */

export const stateCacheKeys = [
  'app',
  'appAddPagesModal',
  'appsList',
  'authentication',
  'board',
  'boardColumns',
  'browseDashboardsList',
  'browseFormsList',
  'browseGroupsList',
  'browseOrganisationsList',
  'browseProjectsList',
  'browseTablesList',
  'browseUsersList',
  'editForm',
  'forgot',
  // 'form',
  'group',
  'groupAuthorisation',
  'groupMembersList',
  'groupProjectsCreate',
  'groupProjectsList',
  'groupSettings',
  'groupSettingsCreateRole',
  'groupSettingsDeleteRole',
  'groupSettingsUpdateRole',
  'groupUsersInvite',
  'groupUsersMemberDelete',
  'groupUsersMemberUpdate',
  'groupsList',
  'homeSettingsApiKeyDelete',
  'homeSettingsApiKeyView',
  'homeSettingsApiKeysCreate',
  'homeSettingsApiKeysEdit',
  'homeSettingsApiKeysList',
  'landing',
  'loader',
  'login',
  'masthead',
  'me',
  'menu',
  'menuDisplay',
  'network',
  'notifications',
  'organisation',
  'organisationAuthorisation',
  'organisationGroupsList',
  'organisationMembersList',
  'organisationProjectsCreate',
  'organisationProjectsList',
  'organisationSettings',
  'organisationSettingsCreateRole',
  'organisationSettingsDeleteRole',
  'organisationSettingsUpdateRole',
  'organisationUsersInvite',
  'organisationUsersMemberDelete',
  'organisationUsersMemberUpdate',
  'organisationsCreate',
  'organisationsGroupsCreate',
  'organisationsList',
  'project',
  'projectAppSettings',
  'projectAppsCreate',
  'projectAppsList',
  'projectAuthorisation',
  'projectBoardsList',
  'projectDashboard',
  'projectDashboardCreate',
  'projectDashboardDelete',
  'projectDashboardTables',
  'projectDashboardUpdate',
  'projectDashboardsChart',
  'projectDashboardsChartBarChartCreate',
  'projectDashboardsChartEmbeddedChartCreate',
  'projectDashboardsChartGeeChartCreate',
  'projectDashboardsChartLineChartCreate',
  'projectDashboardsChartMapChartCreate',
  'projectDashboardsChartScatterChartCreate',
  'projectDashboardsChartScorecardCreate',
  'projectDashboardsChartTableChartCreate',
  'projectDashboardsList',
  'projectFormSettings',
  'projectFormsCreate',
  'projectFormsList',
  'projectManagerCardCreate',
  'projectManagerCardUpdate',
  'projectManagerCreate',
  'projectMembersList',
  'projectProjectOverview',
  'projectSettings',
  'projectSettingsCreateRole',
  'projectSettingsDeleteRole',
  'projectSettingsUpdateRole',
  'projectStorageBrowse',
  'projectStorageSharedCreate',
  'projectStorageSharedObjectSelector',
  'projectStorageSharedUpload',
  'projectStorageView',
  'projectTableChart',
  'projectTableColumn',
  'projectTableDelete',
  'projectTableRow',
  'projectTableUpdate',
  'projectTablesCreate',
  'projectTablesImportData',
  'projectTablesList',
  'projectUsersInvite',
  'projectUsersMemberDelete',
  'projectUsersMemberUpdate',
  'projectViewTable',
  'projectWikiCreate',
  'projectWikiList',
  'projectWikiView',
  'projectWikiViewEdit',
  'projectWikiViewSettings',
  'projectsCreate',
  'projectsList',
  'register',
  'reset',
  'router',
  'settings',
  'shareApp',
  'shareDashboard',
  'shareForm',
  'shareTable',
  'shareWiki',
  'tableAddColumns',
  'tableAddRow',
  'ui',
  'user',
  'userOrganisations',
  'userProjects',
  'verify',
];
