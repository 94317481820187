import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ResetOrganisationGroupsList } from '@ih/app/client/organisation/groups/list/data-access';
import { ResetOrganisationProjectsList } from '@ih/app/client/organisation/projects/list/data-access';
import { ResetOrganisationUsersList } from '@ih/app/client/organisation/users/list/data-access';
import { Organisation } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, EMPTY, map, take } from 'rxjs';
import { ResetStates } from '../actions';
import { GetOrganisationAction, SetOrganisationAction } from './../actions';

export interface OrganisationStateModel {
  organisation: Organisation | null;
}

@State<OrganisationStateModel>({
  name: 'organisation',
  defaults: {
    organisation: null,
  },
})
@Injectable()
export class OrganisationState {
  @Selector()
  public static getState(state: OrganisationStateModel) {
    return state;
  }

  @Selector()
  public static organisation(
    state: OrganisationStateModel
  ): Organisation | null {
    return state.organisation;
  }

  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly router: Router
  ) {}

  @Action(GetOrganisationAction)
  public getOrganisationAction(
    ctx: StateContext<OrganisationStateModel>,
    { id }: GetOrganisationAction
  ) {
    // this.store.dispatch(new ResetStates());

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueOrganisation($id: String!) {
        findUniqueOrganisation(request: { id: $id }) {
          id
          name
          slug
          description
          visibility
          photoURL
          photoRef
        }
      }
    `;

    return this.apollo
      .watchQuery<{ findUniqueOrganisation: Organisation }>({
        query,
        variables: {
          id,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: ApolloQueryResult<{ findUniqueOrganisation: Organisation }>
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            const organisation = result?.data
              ?.findUniqueOrganisation as Organisation;
            ctx.dispatch(new SetOrganisationAction(organisation));
          }
        ),
        catchError((error) => {
          console.error(error);
          this.router.navigate(['/error', 404]);
          return EMPTY;
        })
      );
  }

  @Action(SetOrganisationAction)
  public setOrganisationAction(
    ctx: StateContext<OrganisationStateModel>,
    { organisation }: SetOrganisationAction
  ) {
    ctx.setState(
      produce((draft) => {
        draft.organisation = organisation;
      })
    );
  }

  @Action(ResetStates)
  public resetStates(ctx: StateContext<OrganisationStateModel>) {
    ctx.dispatch([
      new ResetOrganisationUsersList(),
      new ResetOrganisationProjectsList(),
      new ResetOrganisationGroupsList(),
    ]);
  }
}
