import { Injectable } from '@angular/core';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  FindAllSitePermissionsRequest,
  FindAllSiteRolePermissionsRequest,
  SitePermission,
  SiteRolePermission,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable()
export class SiteService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  findUserPermissions(
    request: FindAllSiteRolePermissionsRequest
  ): Observable<SiteRolePermission[]> {
    type ResultType = {
      findAllSiteRolePermissions: SiteRolePermission[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findAllSiteRolePermissions(
        $request: FindAllSiteRolePermissionsRequest!
      ) {
        findAllSiteRolePermissions(request: $request) {
          id
          sitePermissionId
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllSiteRolePermissions || [];
          }
        )
      );
  }

    //ProjectMembers
    findAllSitePermissions(
      request: FindAllSitePermissionsRequest
    ): Observable<SitePermission[]> {
      type ResultType = { findAllSitePermissions: SitePermission[] | null };
      let fetchPolicy = 'cache-only';
  
      const networkStatus = this.store.selectSnapshot<boolean>(
        (state) => state.network.status
      );
  
      if (networkStatus) {
        fetchPolicy = 'network-only';
      }
  
      const query = gql`
        query findAllSitePermissions(
          $request: FindAllSitePermissionsRequest!
        ) {
          findAllSitePermissions(request: $request) {
            id
            identifier
          }
        }
      `;
  
      return this.apollo
        .watchQuery<ResultType>({
          query,
          variables: {
            request,
          },
          fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
        })
        .valueChanges.pipe(
          take(1),
          map(
            (
              result: FetchResult<
                ResultType,
                Record<string, any>,
                Record<string, any>
              >
            ) => {
              if (result.data && networkStatus) {
                this.apollo.client.cache.writeQuery({
                  query,
                  data: result.data,
                });
              }
              return result.data?.findAllSitePermissions || [];
            }
          )
        );
    }

  
}
