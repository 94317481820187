import { Injectable } from '@angular/core';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  FindAllOrganisationPermissionsRequest,
  FindUniqueOrganisationRolePermissionsRequest,
  Organisation,
  OrganisationMember,
  OrganisationPermission,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { catchError, EMPTY, map, Observable, of, take } from 'rxjs';

@Injectable()
export class OrganisationsService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  findUniqueOrganisation(organisationId : string) : Observable<Organisation | null> {
    type ResultType = {
      findUniqueOrganisation: Organisation | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueOrganisation($id: String!) {
        findUniqueOrganisation(request: { id: $id }) {
          id
          name
          slug
          description
          visibility
          photoURL
          photoRef
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          id: organisationId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              { findUniqueOrganisation: Organisation | null },
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findUniqueOrganisation || null;
          }
        ),
        catchError((error) => {
          console.error(error);
          return EMPTY;
        })
      );
      
  }

  findOrganisationMemberWithRolePermissions(
    userId: string,
    organisationId: string
  ): Observable<OrganisationMember[]> {
    type ResultType = {
      findMeOrganisationMember: OrganisationMember[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindMeOrganisationMember(
        $userId: String!
        $organisationId: String!
      ) {
        findMeOrganisationMember(
          request: {
            where: { userId: $userId, organisationId: $organisationId }
          }
        ) {
          role
          organisationId
          organisationRoleId
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          userId,
          organisationId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }

            return result.data?.findMeOrganisationMember || [];
          }
        ),
        catchError(() => {
          return of([]); 
        })
      );
  }

  findAllOrganisationPermissions(
    request: FindAllOrganisationPermissionsRequest
  ): Observable<OrganisationPermission[]> {
    type ResultType = {
      findAllOrganisationPermissions: OrganisationPermission[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllOrganisationPermissions(
        $request: FindAllOrganisationPermissionsRequest!
      ) {
        findAllOrganisationPermissions(request: $request) {
          id
          identifier
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findAllOrganisationPermissions || [];
          }
        )
      );
  }

  findUniqueOrganisationRolePermissions(
    request: FindUniqueOrganisationRolePermissionsRequest
  ): Observable<OrganisationPermission[]> {
    type ResultType = {
      findUniqueOrganisationRolePermissions: OrganisationPermission[] | null;
    };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllOrganisationPermissions(
        $request: FindUniqueOrganisationRolePermissionsRequest!
      ) {
        findUniqueOrganisationRolePermissions(request: $request) {
          id
          identifier
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findUniqueOrganisationRolePermissions || [];
          }
        )
      );
  }
}
