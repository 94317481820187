  /**
   * Toggles the synching status of the state
   * @updated 20/04/2024 - 08:17:44
   * @public
   * @param StateContext NetworkStateModel
   * @see libs/app/client/shared/states/src/lib/network/states/network.state.ts
   */
export class SetSynchingStatus {
  public static readonly type = '[Network] SetSynchingStatus';
  constructor(public readonly synching: boolean) {}

}
