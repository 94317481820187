import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AvatarModule } from 'ngx-avatars';
import { AppClientSharedComponentsMenuContentUiComponent } from './app-client-shared-components-menu-content-ui.component';

@NgModule({
  imports: [CommonModule, IonicModule, RouterModule, AvatarModule],
  declarations: [AppClientSharedComponentsMenuContentUiComponent],
  exports: [AppClientSharedComponentsMenuContentUiComponent],
})
export class AppClientSharedComponentsMenuContentUiModule {}
