/**
 * Sets group permissions in MeStateModel.permissions.project
 * @updated 04/11/2024
 * @public
 * @param ctx StateContext<MeStateModel>
 * @param groupPermissions string[]
 * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
 */export class SetGroupPermissions {
  public static readonly type = '[Me] SetGroupPermissions';
  constructor(
    public readonly groupPermissions: string[],
    public readonly meta? : boolean
  ) {}
}
