import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import { GroupMember, OrganisationMember, Project, ProjectMember, User } from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { catchError, lastValueFrom, of, take, tap } from 'rxjs';

// TODO: Move the guards to guards, replace business logic with filter, This file should only contain the query which fetches members
@Injectable({
  providedIn: 'root',
})
export class ProjectGuard  {
  constructor(
    private router: Router,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
    private readonly store: Store
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const projectId = next.parent?.params['projectId'];

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );
    const getMember = new GetMembers();

    const userId = await getMember.getUserId(this.store, this.apollo);

    if (userId === '') {
      return this.router.createUrlTree(['/login']);
    }

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllProjectMembers($userId: String!, $projectId: String!) {
        findAllProjectMembers(
          request: { where: { projectId: $projectId, userId: $userId } }
        ) {
          id
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllProjectMembers: ProjectMember[];
    }>({
      query,
      variables: {
        projectId,
        userId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });

    const result$ = postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{ findAllProjectMembers: ProjectMember[] }>
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
        }
      ),
      catchError((error) => {
        console.error(error);
        this.toastService.showError('Error while fetching project members');
        return of();
      })
    );

    const result = await lastValueFrom(result$);

    if (result?.data?.findAllProjectMembers?.length > 0) {
      return true;
    }

    const project = await getMember.getProject(
      this.apollo,
      fetchPolicy,
      networkStatus,
      projectId
    );

    if (project.data.findUniqueProject.groupId) {
      const groupMember = await getMember.getGroupMember(
        this.apollo,
        fetchPolicy,
        networkStatus,
        project.data.findUniqueProject.groupId,
        userId
      );

      if (groupMember.data.findAllGroupMembers.length > 0) return true;
    }

    if (project.data.findUniqueProject.organisationId) {
      const organisationMember = await getMember.getOrganisationMember(
        this.apollo,
        fetchPolicy,
        networkStatus,
        project.data.findUniqueProject.organisationId,
        userId
      );

      if (organisationMember.data.findAllOrganisationMembers.length > 0)
        return true;
    }

    return this.router.createUrlTree(['/errors/401']);
  }
}

@Injectable({
  providedIn: 'root',
})
export class GroupGuard  {
  constructor(
    private router: Router,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
    private readonly store: Store
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const groupId = next.parent?.params['unique'];

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    const getMember = new GetMembers();

    const userId = await getMember.getUserId(this.store, this.apollo);

    if (userId === '') {
      return this.router.createUrlTree(['/login']);
    }

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const groupMembers = await getMember.getGroupMember(
      this.apollo,
      fetchPolicy,
      networkStatus,
      groupId,
      userId
    );

    if (groupMembers?.data?.findAllGroupMembers?.length > 0) {
      return true;
    }

    const group = await getMember.getGroup(
      this.apollo,
      fetchPolicy,
      networkStatus,
      groupId
    );

    if (group.data.findUniqueGroup.organisationId) {
      const organisationMembers = await getMember.getOrganisationMember(
        this.apollo,
        fetchPolicy,
        networkStatus,
        group.data.findUniqueGroup.organisationId,
        userId
      );

      if (organisationMembers?.data?.findAllOrganisationMembers?.length > 0) {
        return true;
      }
    }

    return this.router.createUrlTree(['/errors/401']);
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationGuard  {
  constructor(
    private router: Router,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
    private readonly store: Store
  ) {}

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const organisationId = next.parent?.params['unique'];

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    const getMember = new GetMembers();

    const userId = await getMember.getUserId(this.store, this.apollo);

    if (userId === '') {
      return this.router.createUrlTree(['/login']);
    }

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const organisationMembers = await getMember.getOrganisationMember(
      this.apollo,
      fetchPolicy,
      networkStatus,
      organisationId,
      userId
    );

    if (organisationMembers?.data?.findAllOrganisationMembers?.length > 0) {
      return true;
    }

    return this.router.createUrlTree(['/errors/401']);
  }
}
class GetMembers {
  async getUserId(store: Store, apollo: Apollo): Promise<string> {
    let fetchPolicy = 'cache-only';

    const networkStatus = store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query Me {
        me {
          id
        }
      }
    `;

    const postsQuery = apollo.watchQuery<{
      me: User | null;
    }>({
      query,
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });

    const result$ = postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            me: User | null;
          }>
        ) => {
          if (result.data && networkStatus) {
            apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
        }
      ),
      catchError((error) => {
        console.error(error);
        return of();
      })
    );

    const result = await lastValueFrom(result$);

    return result?.data?.me?.id || '';
  }

  async getProject(
    apollo: Apollo,
    fetchPolicy: string,
    networkStatus: boolean,
    projectId: string
  ): Promise<
    ApolloQueryResult<{
      findUniqueProject: Project;
    }>
  > {
    const query = gql`
      query FindUniqueProject($projectId: String!) {
        findUniqueProject(request: { id: $projectId }) {
          id
          groupId
          organisationId
        }
      }
    `;

    const postsQuery = apollo.watchQuery<{
      findUniqueProject: Project;
    }>({
      query,
      variables: {
        projectId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });

    const result$ = postsQuery.valueChanges.pipe(
      take(1),
      tap((result: ApolloQueryResult<{ findUniqueProject: Project }>) => {
        if (result.data && networkStatus) {
          apollo.client.cache.writeQuery({
            query,
            data: result.data,
          });
        }
      }),
      catchError((error) => {
        console.error(error);
        return of();
      })
    );

    return await lastValueFrom(result$);
  }

  async getGroupMember(
    apollo: Apollo,
    fetchPolicy: string,
    networkStatus: boolean,
    groupId: string,
    userId: string
  ): Promise<
    ApolloQueryResult<{
      findAllGroupMembers: GroupMember[];
    }>
  > {
    const query = gql`
      query FindAllGroupMembers($userId: String!, $groupId: String!) {
        findAllGroupMembers(
          request: { where: { groupId: $groupId, userId: $userId } }
        ) {
          id
        }
      }
    `;

    const postsQuery = apollo.watchQuery<{
      findAllGroupMembers: GroupMember[];
    }>({
      query,
      variables: {
        groupId,
        userId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });

    const result$ = postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (result: ApolloQueryResult<{ findAllGroupMembers: GroupMember[] }>) => {
          if (result.data && networkStatus) {
            apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
        }
      ),
      catchError((error) => {
        console.error(error);
        return of();
      })
    );

    return await lastValueFrom(result$);
  }

  async getGroup(
    apollo: Apollo,
    fetchPolicy: string,
    networkStatus: boolean,
    groupId: string
  ): Promise<
    ApolloQueryResult<{
      findUniqueGroup: Project;
    }>
  > {
    const query = gql`
      query FindUniqueGroup($groupId: String!) {
        findUniqueGroup(request: { id: $groupId }) {
          id
          organisationId
        }
      }
    `;

    const postsQuery = apollo.watchQuery<{
      findUniqueGroup: Project;
    }>({
      query,
      variables: {
        groupId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });

    const result$ = postsQuery.valueChanges.pipe(
      take(1),
      tap((result: ApolloQueryResult<{ findUniqueGroup: Project }>) => {
        if (result.data && networkStatus) {
          apollo.client.cache.writeQuery({
            query,
            data: result.data,
          });
        }
      }),
      catchError((error) => {
        console.error(error);
        return of();
      })
    );

    return await lastValueFrom(result$);
  }

  async getOrganisationMember(
    apollo: Apollo,
    fetchPolicy: string,
    networkStatus: boolean,
    organisationId: string,
    userId: string
  ): Promise<
    ApolloQueryResult<{
      findAllOrganisationMembers: OrganisationMember[];
    }>
  > {
    const query = gql`
      query FindAllOrganisationMembers(
        $userId: String!
        $organisationId: String!
      ) {
        findAllOrganisationMembers(
          request: {
            where: { organisationId: $organisationId, userId: $userId }
          }
        ) {
          id
        }
      }
    `;

    const postsQuery = apollo.watchQuery<{
      findAllOrganisationMembers: OrganisationMember[];
    }>({
      query,
      variables: {
        organisationId,
        userId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });

    const result$ = postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllOrganisationMembers: OrganisationMember[];
          }>
        ) => {
          if (result.data && networkStatus) {
            apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
        }
      ),
      catchError((error) => {
        console.error(error);
        return of();
      })
    );

    return await lastValueFrom(result$);
  }
}
