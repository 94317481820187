import { Injectable } from '@angular/core';
import { UpdateUserRequest, User, ValidUserResponse,ValidUserRequest } from '@ih/app/shared/apis/interfaces';
import { Apollo, gql } from 'apollo-angular';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class UsersService {
  constructor(private readonly apollo: Apollo) {}

  async verifyUserDetails(request: ValidUserRequest) {
    const verifyQuery = this.apollo.mutate<{ verifyUser: ValidUserRequest }>({
      mutation: gql`
        mutation verifyUser(
          $email: String
          $username: String
        ) {
          verifyUser(
            request: {
              email: $email
              username: $username
            }
          ) {
            email
            username
          }
        }
      `,
      variables: {
        email: request.email,
        username: request.username,
      },
    });

    return await firstValueFrom(verifyQuery);
  }

  async sync() {
    const syncQuery = this.apollo.mutate<{ syncUser: User }>({
      mutation: gql`
        mutation {
          syncUser {
            id
          }
        }
      `,
    });

    return await firstValueFrom(syncQuery);
  }

  async update(updateRequest: UpdateUserRequest) {
    const updateQuery = this.apollo.mutate<{ updateQuery: User }>({
      mutation: gql`
        mutation UpdateUser(
          $id: String!
          $displayName: String
          $email: String
          $username: String
          $photoURL: String
        ) {
          updateUser(
            request: {
              id: $id
              displayName: $displayName
              email: $email
              username: $username
              photoURL: $photoURL
            }
          ) {
            id
            username
            email
            displayName
            updated
          }
        }
      `,
      variables: {
        id: updateRequest.id,
        displayName: updateRequest.displayName,
        email: updateRequest.email,
        username: updateRequest.username,
        photoURL: updateRequest.photoURL,
      },
    });

    return await firstValueFrom(updateQuery);
  }
}
