import { Injectable } from '@angular/core';
import { TrackedMedia, TrackedQuery } from '@ih/app/client/shared/interfaces';
import {
  MediaQueriesSyncService,
  TrackQueriesService,
  TrackQueriesSyncService,
} from '@ih/app/client/shared/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import {
  AddNotification,
  CheckCount,
  GetNotifications,
  GetTrackedMedia,
  GetTrackedQueries,
  Refresh,
  SetNotifications,
  SetTrackedMedia,
  SetTrackedQueries,
  Sync,
  SyncAll,
  Upload,
} from '../actions';

export interface NotificationsStateModel {
  trackedQueries: TrackedQuery[];
  trackedMedia: TrackedMedia[];
  notifications: string[];
  count: number;
}

const defaults: NotificationsStateModel = {
  trackedQueries: [],
  trackedMedia: [],
  notifications: [],
  count: 0,
};

@State<NotificationsStateModel>({
  name: 'notifications',
  defaults,
})
@Injectable()
export class NotificationsState {
  @Selector()
  public static getState(state: NotificationsStateModel) {
    return state;
  }

  @Selector()
  public static trackedQueries(state: NotificationsStateModel): TrackedQuery[] {
    return state.trackedQueries;
  }

  @Selector()
  public static trackedMedia(state: NotificationsStateModel): TrackedMedia[] {
    return state.trackedMedia;
  }

  @Selector()
  public static notifications(state: NotificationsStateModel): string[] {
    return state.notifications;
  }

  @Selector()
  public static count(state: NotificationsStateModel): number {
    return state.count;
  }

  constructor(
    private readonly trackQueriesService: TrackQueriesService,
    private readonly trackQueriesSyncService: TrackQueriesSyncService,
    private readonly mediaQueriesSyncService: MediaQueriesSyncService,
  ) {}

  // TODO: Add more actions such as adding other notifications
  // currently only catering for forms trackedQueries


  
  @Action(GetNotifications)
  public getNotifications(ctx: StateContext<NotificationsStateModel>) {
    // ctx.dispatch(new AddNotification('test'));
    // currently only getting and setting form submission requests
    // this.trackQueriesService.removeCompletedQueries().then(() => {
    //   this.trackQueriesService
    //     .getTrackedQueriesFromDB()
    //     .then((queries) => {
    //       // Currently only for form submissions
    //       const filteredQueries = queries.filter((query) => {
    //         return query.name === 'InsertDataFromForm';
    //       });
    //       ctx.dispatch(new SetNotifications(filteredQueries));
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // });
    ctx.setState(
      produce((draft) => {
        draft.trackedQueries = defaults.trackedQueries;
        draft.trackedMedia = defaults.trackedMedia;
        draft.notifications = defaults.notifications;
        draft.count = defaults.count;
      }),
    );

    ctx.dispatch([new GetTrackedQueries(), new GetTrackedMedia()]);
  }

  @Action(SetNotifications)
  public setNotifications(
    ctx: StateContext<NotificationsStateModel>,
    { trackedQueries }: SetNotifications,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.trackedQueries = trackedQueries;
        draft.count = draft.count + trackedQueries.length;
      }),
    );
  }

  @Action(AddNotification)
  public addNotification(
    ctx: StateContext<NotificationsStateModel>,
    { message }: AddNotification,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.notifications.push(message);
        draft.count++;
      }),
    );
    // ctx.dispatch(new Refresh());
  }

  @Action(GetTrackedMedia)
  public getTrackedMedia(ctx: StateContext<NotificationsStateModel>) {
    this.mediaQueriesSyncService
      .getTrackedMediaFromDB()
      .then((queries) => {
        ctx.dispatch(new SetTrackedMedia(queries));
      })
      .catch((error) => {
        console.error(error);
      });
  }

  @Action(SetTrackedMedia)
  public setTrackedMedia(
    ctx: StateContext<NotificationsStateModel>,
    { trackedMedia }: SetTrackedMedia,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.trackedMedia = trackedMedia;
        draft.count = draft.count + trackedMedia.length;
      }),
    );
  }

  @Action(GetTrackedQueries)
  public getTrackedQueries(ctx: StateContext<NotificationsStateModel>) {
    this.trackQueriesService.removeCompletedQueries().then(() => {
      this.trackQueriesService
        .getTrackedQueriesFromDB()
        .then((queries) => {
          // Currently only for form submissions
          const filteredQueries = queries.filter((query) => {
            return query.name === 'InsertDataFromForm';
          });
          ctx.dispatch(new SetTrackedQueries(filteredQueries));
        })
        .catch((error) => {
          console.error(error);
        });
    });
  }

  @Action(SetTrackedQueries)
  public setTrackedQueries(
    ctx: StateContext<NotificationsStateModel>,
    { trackedQueries }: SetTrackedQueries,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.trackedQueries = trackedQueries;
        draft.count = draft.count + trackedQueries.length;
      }),
    );
  }


  /**
   * Uploads ALL files from ALL media queries to firebase storage, and upon success removes the TrackedMedia element from indexedDB.
   * This will also subsequently update the table values to link to the url in firebase storage.
   * @updated 28/03/2024 - 15:16:24
   * @param ctx NotificationStateModel
   */
  @Action(Upload)
  public async upload(ctx: StateContext<NotificationsStateModel>) {
    await this.mediaQueriesSyncService.upload();
  }

  @Action(SyncAll)
  public async syncAll(ctx: StateContext<NotificationsStateModel>) {
    await this.trackQueriesService
      .getTrackedQueriesFromDB()
      .then((trackedQueries) => {
        const filteredQueries = trackedQueries.filter((query) => {
          return query.name === 'InsertDataFromForm';
        });
        ctx.setState(
          produce((draft) => {
            draft.trackedQueries = filteredQueries;
            this.trackQueriesSyncService.syncAll(filteredQueries);
            ctx.dispatch(new Refresh());
          }),
        );
      });
  }

  @Action(Sync)
  public async sync(
    ctx: StateContext<NotificationsStateModel>,
    { trackedQuery }: Sync,
  ) {
    await this.trackQueriesService
      .getTrackedQuery(trackedQuery.trackId)
      .then((trackedQuery) => {
        if (trackedQuery.length > 0) {
          this.trackQueriesSyncService.sync(trackedQuery[0]);
        }
        ctx.dispatch(new Refresh());
      });
  }

  @Action(Refresh)
  public refresh(ctx: StateContext<NotificationsStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.trackedQueries = defaults.trackedQueries;
        draft.trackedMedia = defaults.trackedMedia;
        draft.notifications = defaults.notifications;
        draft.count = defaults.count;
      }),
    );
    // FIXME: Getting old data faster than it is cleared
    ctx.dispatch(new GetNotifications());
  }

  /**
   * Dispatches GetNotifications action
   * @dispatch {@link getNotifications}
   * @param ctx 
   */
  @Action(CheckCount)
  public checkCount(ctx: StateContext<NotificationsStateModel>) {
    ctx.dispatch(new GetNotifications());
  }
}
