import { ApolloQueryResult } from '@apollo/client/core';
import { Board } from '@ih/app/shared/apis/interfaces';

export class FindAllBoardsSuccess {
  public static readonly type = '[Project/Board/List] FindAllBoardsSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllBoards: Board[];
    }>
  ) {}
}
