import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import { OrganisationMember } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';
import {
  FindAllOrganisationMembers,
  ResetUserOrganisationsList,
  SetOrganisations,
} from '../actions';
import {
  FindAllOrganisationsError,
  FindAllOrganisationsSuccess,
} from '../effects';

export interface UserOrganisationsStateModel {
  organisationMembers: OrganisationMember[];
}

@State<UserOrganisationsStateModel>({
  name: 'userOrganisations',
  defaults: {
    organisationMembers: [],
  },
})
@Injectable()
export class UserOrganisationsState {
  constructor(
    private readonly apollo: Apollo,
    private readonly store: Store,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static getState(state: UserOrganisationsStateModel) {
    return state;
  }

  @Selector()
  public static organisationMembers(
    state: UserOrganisationsStateModel,
  ): OrganisationMember[] {
    return state.organisationMembers;
  }

  @Action(FindAllOrganisationMembers)
  public findUniqueUser(ctx: StateContext<UserOrganisationsStateModel>) {
    const userId = this.store.selectSnapshot<string>(
      (state) => state.user.user.id,
    );

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllOrganisationMembers($userId: String!) {
        findAllOrganisationMembers(request: { where: { userId: $userId } }) {
          organisation {
            id
            name
            description
            slug
            photoURL
          }
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllOrganisationMembers: OrganisationMember[];
    }>({
      query,
      variables: {
        userId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllOrganisationMembers: OrganisationMember[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              variables: {
                userId,
              },
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllOrganisationsSuccess(result));
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllOrganisationsError(error))),
    );
  }

  @Action(FindAllOrganisationsSuccess)
  public findAllOrganisationsSuccess(
    ctx: StateContext<UserOrganisationsStateModel>,
    { result }: FindAllOrganisationsSuccess,
  ) {
    const organisationMembers: OrganisationMember[] = (
      result?.data?.findAllOrganisationMembers || []
    )
      .filter(function (organisationMember) {
        if (
          organisationMember.organisation &&
          organisationMember.organisation
        ) {
          return true;
        }
        return false;
      }, [])
      .sort((a, b) => {
        if (a == null || b == null) return 0;
        if (a.organisation == null || b.organisation == null) return 0;
        return a.organisation.name.localeCompare(b.organisation.name);
      });

    ctx.dispatch(new SetOrganisations(organisationMembers));
  }

  @Action(FindAllOrganisationsError)
  public findAllOrganisationsError(
    ctx: StateContext<UserOrganisationsStateModel>,
    { error }: FindAllOrganisationsError,
  ) {
    this.toastService.showError('Failed to fetch organisations');
    console.error('Failed to fetch organisations', error.message);
  }

  @Action(SetOrganisations)
  public setOrganisations(
    ctx: StateContext<UserOrganisationsStateModel>,
    { organisationMembers }: SetOrganisations,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.organisationMembers = organisationMembers;
      }),
    );
  }

  @Action(ResetUserOrganisationsList)
  public resetUserOrganisationsList(
    ctx: StateContext<UserOrganisationsStateModel>,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.organisationMembers = [];
      }),
    );
  }
}
