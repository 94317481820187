  /**
   * Registers the user and syncs the user account with usersService. Sets the username if the userCredential has a user.
   * While this action is ongoing it sets the registering status to true, which is only set to false when the GetMe action is completed or failed.
   * @updated 18/03/2024 - 09:18:11
   * @public
   * @onSuccess Dispatch {@link registerSuccessAction}
   * @onFail Dispatch {@link registerErrorAction}
   * @see libs/app/client/register/data-access/src/lib/states/register.state.ts
   */
export class RegisterAction {
  public static readonly type = '[Register] RegisterAction';
}
