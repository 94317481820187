import { ApolloQueryResult } from '@apollo/client/core';
import { ProjectMember } from '@ih/app/shared/apis/interfaces';

export class FindAllProjectsSuccess {
  public static readonly type = '[User/Projects] FindAllProjectsSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllProjectMembers: ProjectMember[];
    }>
  ) {}
}
