export enum FirebaseAuthErrorCodes {
    EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
    INVALID_EMAIL = 'auth/invalid-email',
    USER_DISABLED = 'auth/user-disabled',
    USER_NOT_FOUND = 'auth/user-not-found',
    WRONG_PASSWORD = 'auth/wrong-password',
    WEAK_PASSWORD = 'auth/weak-password',
    INVALID_VERIFICATION_CODE = 'auth/invalid-verification-code',
    INVALID_VERIFICATION_ID = 'auth/invalid-verification-id',
    SESSION_EXPIRED = 'auth/session-expired',
    INVALID_ID_TOKEN = 'auth/invalid-id-token',
    TOKEN_EXPIRED = 'auth/id-token-expired',
    INVALID_PASSWORD = 'auth/invalid-password',
    TOO_MANY_REQUESTS = 'auth/too-many-requests',
    UNAUTHORIZED_DOMAIN = 'auth/unauthorized-domain',
    INVALID_API_KEY = 'auth/invalid-api-key',
    INVALID_USER_TOKEN = 'auth/invalid-user-token',
    USER_TOKEN_EXPIRED = 'auth/user-token-expired',
    CREDENTIAL_TOO_OLD_LOGIN_AGAIN = 'auth/credential-too-old-login-again',
    INVALID_PHONE_NUMBER = 'auth/invalid-phone-number',
    INVALID_RECIPIENT_EMAIL = 'auth/invalid-recipient-email',
    MISSING_PHONE_NUMBER = 'auth/missing-phone-number',
    MISSING_VERIFICATION_CODE = 'auth/missing-verification-code',
    MISSING_VERIFICATION_ID = 'auth/missing-verification-id',
    INVALID_SESSION_INFO = 'auth/invalid-session-info',
    INVALID_TEMPORARY_PROOF = 'auth/invalid-temporary-proof',
    MISSING_TEMPORARY_PROOF = 'auth/missing-temporary-proof',
    USER_MISMATCH = 'auth/user-mismatch',
    REQUIRES_SECOND_FACTOR = 'auth/requires-recent-login',
    SECOND_FACTOR_ALREADY_ENROLLED = 'auth/second-factor-already-in-use',
    SECOND_FACTOR_LIMIT_EXCEEDED = 'auth/maximum-second-factor-count-exceeded',
    UNSUPPORTED_FIRST_FACTOR = 'auth/unsupported-first-factor',
    UNVERIFIED_EMAIL = 'auth/unverified-email',
    EMAIL_CHANGE_NEEDS_VERIFICATION = 'auth/email-change-needs-verification',
    SECOND_FACTOR_AUTH_DISABLED = 'auth/second-factor-auth-disabled',
    CUSTOM_TOKEN_MISMATCH = 'auth/custom-token-mismatch',
    INVALID_CUSTOM_TOKEN = 'auth/invalid-custom-token',
    CAPTCHA_CHECK_FAILED = 'auth/captcha-check-failed',
    INVALID_APP_CREDENTIAL = 'auth/invalid-app-credential',
}

export const firebaseAuthErrorMessages : { [key: string]: string } = {
    [FirebaseAuthErrorCodes.EMAIL_ALREADY_IN_USE]: 'Email is already in use',
    [FirebaseAuthErrorCodes.INVALID_EMAIL]: 'Invalid email',
    [FirebaseAuthErrorCodes.USER_DISABLED]: 'User account disabled',
    [FirebaseAuthErrorCodes.USER_NOT_FOUND]: 'User account does not exist',
    [FirebaseAuthErrorCodes.WRONG_PASSWORD]: 'Wrong password',
    [FirebaseAuthErrorCodes.WEAK_PASSWORD]: 'Password is too weak',
    [FirebaseAuthErrorCodes.INVALID_VERIFICATION_CODE]: 'Invalid verification code',
    [FirebaseAuthErrorCodes.INVALID_VERIFICATION_ID]: 'Invalid verification ID',
    [FirebaseAuthErrorCodes.SESSION_EXPIRED]: 'Session expired',
    [FirebaseAuthErrorCodes.INVALID_ID_TOKEN]: 'Invalid ID token',
    [FirebaseAuthErrorCodes.TOKEN_EXPIRED]: 'Token expired',
    [FirebaseAuthErrorCodes.INVALID_PASSWORD]: 'Invalid password',
    [FirebaseAuthErrorCodes.TOO_MANY_REQUESTS]: 'Too many requests',
    [FirebaseAuthErrorCodes.UNAUTHORIZED_DOMAIN]: 'Unauthorized domain',
    [FirebaseAuthErrorCodes.INVALID_API_KEY]: 'Invalid API key',
    [FirebaseAuthErrorCodes.INVALID_USER_TOKEN]: 'Invalid user token',
    [FirebaseAuthErrorCodes.USER_TOKEN_EXPIRED]: 'User token expired',
    [FirebaseAuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN]: 'Credential too old, login again',
    [FirebaseAuthErrorCodes.INVALID_PHONE_NUMBER]: 'Invalid phone number',
    [FirebaseAuthErrorCodes.INVALID_RECIPIENT_EMAIL]: 'Invalid recipient email',
    [FirebaseAuthErrorCodes.MISSING_PHONE_NUMBER]: 'Missing phone number',
    [FirebaseAuthErrorCodes.MISSING_VERIFICATION_CODE]: 'Missing verification code',
    [FirebaseAuthErrorCodes.MISSING_VERIFICATION_ID]: 'Missing verification ID',
    [FirebaseAuthErrorCodes.INVALID_SESSION_INFO]: 'Invalid session info',
    [FirebaseAuthErrorCodes.INVALID_TEMPORARY_PROOF]: 'Invalid temporary proof',
    [FirebaseAuthErrorCodes.MISSING_TEMPORARY_PROOF]: 'Missing temporary proof',
    [FirebaseAuthErrorCodes.USER_MISMATCH]: 'User mismatch',
    [FirebaseAuthErrorCodes.REQUIRES_SECOND_FACTOR]: 'Requires recent login',
    [FirebaseAuthErrorCodes.SECOND_FACTOR_ALREADY_ENROLLED]: 'Second factor already enrolled',
    [FirebaseAuthErrorCodes.SECOND_FACTOR_LIMIT_EXCEEDED]: 'Maximum second factor count exceeded',
    [FirebaseAuthErrorCodes.UNSUPPORTED_FIRST_FACTOR]: 'Unsupported first factor',
    [FirebaseAuthErrorCodes.UNVERIFIED_EMAIL]: 'Unverified email',
    [FirebaseAuthErrorCodes.EMAIL_CHANGE_NEEDS_VERIFICATION]: 'Email change needs verification',
    [FirebaseAuthErrorCodes.SECOND_FACTOR_AUTH_DISABLED]: 'Second factor auth disabled',
    [FirebaseAuthErrorCodes.CUSTOM_TOKEN_MISMATCH]: 'Custom token mismatch',
    [FirebaseAuthErrorCodes.INVALID_CUSTOM_TOKEN]: 'Invalid custom token',
    [FirebaseAuthErrorCodes.CAPTCHA_CHECK_FAILED]: 'Captcha check failed',
    [FirebaseAuthErrorCodes.INVALID_APP_CREDENTIAL]: 'Invalid app credential',
}