import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import { GroupMember, GroupRole } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';

import {
  FindAllDefaultRoles,
  FindAllGroupMembers,
  FindAllGroupRoles,
  ResetGroupUsersList,
  SetGroupMembers,
} from '../actions';
import {
  FindAllDefaultRolesError,
  FindAllDefaultRolesSuccess,
  FindAllGroupMembersError,
  FindAllGroupMembersSuccess,
  FindAllGroupRolesError,
  FindAllGroupRolesSuccess,
} from '../effects';

export interface GroupUsersListStateModel {
  groupMembers: GroupMember[];
  groupRoles: GroupRole[];
}

@State<GroupUsersListStateModel>({
  name: 'groupMembersList',
  defaults: {
    groupMembers: [],
    groupRoles: [],
  },
})
@Injectable()
export class GroupUsersListState {
  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static getState(state: GroupUsersListStateModel) {
    return state;
  }

  @Selector()
  public static groupMembers(state: GroupUsersListStateModel): GroupMember[] {
    return state.groupMembers;
  }

  @Action(FindAllGroupMembers)
  public findAllGroupMembers(ctx: StateContext<GroupUsersListStateModel>) {
    const groupId = this.store.selectSnapshot<string>(
      (state) => state.group.group.id,
    );

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllGroupMembers($groupId: String!) {
        findAllGroupMembers(request: { where: { groupId: $groupId } }) {
          id
          role
          user {
            id
            username
            displayName
            email
            photoURL
          }
          groupRoleId
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllGroupMembers: GroupMember[];
    }>({
      query,
      variables: {
        groupId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllGroupMembers: GroupMember[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllGroupMembersSuccess(result));
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllGroupMembersError(error))),
    );
  }

  @Action(FindAllGroupMembersSuccess)
  public findAllGroupMembersSuccess(
    ctx: StateContext<GroupUsersListStateModel>,
    { result }: FindAllGroupMembersSuccess,
  ) {
    const groupMembers: GroupMember[] = (result.data.findAllGroupMembers || [])
      .filter(function (groupMember) {
        return groupMember !== null && groupMember !== undefined;
      })
      .sort((a, b) => {
        if (!a.user || !b.user) return 0;
        if (!a.user.displayName || !b.user.displayName) return 0;
        return a.user.displayName.localeCompare(b.user.displayName);
      });

    ctx.dispatch(new FindAllDefaultRoles(groupMembers));
  }

  @Action(FindAllGroupMembersError)
  public findAllGroupMembersError(
    ctx: StateContext<GroupUsersListStateModel>,
    { error }: FindAllGroupMembersError,
  ) {
    this.toastService.showError('Failed to fetch users');
    console.error('Groups not found error: ', error.message);
  }

  @Action(FindAllDefaultRoles)
  public findAllDefaultRoles(
    ctx: StateContext<GroupUsersListStateModel>,
    { groupMembers }: FindAllDefaultRoles,
  ) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query {
        listCoreGroupRoles {
          id
          name
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      listCoreGroupRoles: GroupRole[];
    }>({
      query,
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            listCoreGroupRoles: GroupRole[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllDefaultRolesSuccess(result, groupMembers));
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllGroupMembersError(error))),
    );
  }

  @Action(FindAllDefaultRolesSuccess)
  public findAllDefaultRolesSuccess(
    ctx: StateContext<GroupUsersListStateModel>,
    { result, members }: FindAllDefaultRolesSuccess,
  ) {
    const defaultRoles: GroupRole[] = result?.data?.listCoreGroupRoles || [];

    ctx.dispatch(new FindAllGroupRoles(members, defaultRoles));
  }

  @Action(FindAllDefaultRolesError)
  public findAllDefaultRolesError(
    ctx: StateContext<GroupUsersListStateModel>,
    { error }: FindAllDefaultRolesError,
  ) {
    this.toastService.showError('Failed to fetch roles');
    console.error('Failed to fetch roles', error.message);
  }

  @Action(FindAllGroupRoles)
  public findAllGroupRoles(
    ctx: StateContext<GroupUsersListStateModel>,
    { groupMembers, defaultRoles }: FindAllGroupRoles,
  ) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    const groupId = this.store.selectSnapshot<string>(
      (state) => state.group.group.id,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllGroupRoles($groupId: String!) {
        findAllGroupRoles(
          request: { where: { groupId: $groupId, deleted: false } }
        ) {
          id
          name
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllGroupRoles: GroupRole[];
    }>({
      query,
      variables: {
        groupId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllGroupRoles: GroupRole[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(
            new FindAllGroupRolesSuccess(result, groupMembers, defaultRoles),
          );
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllGroupRolesError(error))),
    );
  }

  @Action(FindAllGroupRolesSuccess)
  public findAllGroupRolesSuccess(
    ctx: StateContext<GroupUsersListStateModel>,
    { result, members, defaultRoles }: FindAllGroupRolesSuccess,
  ) {
    const groupRoles: GroupRole[] = defaultRoles.concat(
      result?.data?.findAllGroupRoles,
    );
    ctx.dispatch(new SetGroupMembers(members, groupRoles));
  }

  @Action(FindAllGroupRolesError)
  public findAllGroupRolesError(
    ctx: StateContext<GroupUsersListStateModel>,
    { error }: FindAllGroupRolesError,
  ) {
    this.toastService.showError('Failed to fetch roles');
    console.error('Failed to fetch roles', error.message);
  }

  @Action(SetGroupMembers)
  public setGroupMembers(
    ctx: StateContext<GroupUsersListStateModel>,
    { groupMembers, groupRoles }: SetGroupMembers,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.groupMembers = groupMembers.map((member) => {
          return {
            ...member,
            groupRole: groupRoles.filter(
              (role) => role.id === member.groupRoleId,
            )[0],
          };
        });

        draft.groupRoles = groupRoles;
      }),
    );
  }

  @Action(ResetGroupUsersList)
  public resetGroupUsersList(ctx: StateContext<GroupUsersListStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.groupMembers = [];
        draft.groupRoles = [];
      }),
    );
  }
}
