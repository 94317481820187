
/*
 * -------------------------------------------------------
 * THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY)
 * -------------------------------------------------------
 */

/* tslint:disable */
/* eslint-disable */

export enum VisibilityType {
    PUBLIC = "PUBLIC",
    PRIVATE = "PRIVATE",
    PROTECTED = "PROTECTED"
}

export enum DashboardComponentType {
    BAR = "BAR",
    EMBEDDED = "EMBEDDED",
    FILTER = "FILTER",
    GOOGLE_EARTH_ENGINE = "GOOGLE_EARTH_ENGINE",
    IMAGE = "IMAGE",
    LINE = "LINE",
    MAP = "MAP",
    SCATTER = "SCATTER",
    SCORECARD = "SCORECARD",
    TABLE = "TABLE",
    TEXT = "TEXT",
    Time_Series = "Time_Series"
}

export enum DatasourceType {
    URL_SOURCE = "URL_SOURCE",
    TABLE_SOURCE = "TABLE_SOURCE",
    STORAGE_SOURCE = "STORAGE_SOURCE"
}

export enum Layout {
    DEFAULT = "DEFAULT",
    ICON = "ICON",
    ICONANDTEXT = "ICONANDTEXT",
    ACCORDION = "ACCORDION"
}

export enum QuestionType {
    SHORT = "SHORT",
    PARAGRAPH = "PARAGRAPH",
    MULTIRADIO = "MULTIRADIO",
    MULTICHECKBOX = "MULTICHECKBOX",
    DROPDOWN = "DROPDOWN",
    DATE = "DATE",
    TIME = "TIME",
    NUMBER = "NUMBER",
    LOCATION = "LOCATION",
    BOOLEAN = "BOOLEAN",
    FILEUPLOAD = "FILEUPLOAD",
    USERINFO = "USERINFO",
    MARKDOWN = "MARKDOWN",
    DEVICEINFO = "DEVICEINFO",
    QR = "QR"
}

export enum RoleType {
    ADMIN = "ADMIN",
    USER = "USER",
    SUBMITTER = "SUBMITTER",
    VIEWER = "VIEWER"
}

export enum PlatformType {
    ANDROID = "ANDROID",
    WEB = "WEB",
    IOS = "IOS"
}

export enum ProjectComponent {
    Storage = "Storage",
    Tables = "Tables",
    Forms = "Forms",
    Dashboards = "Dashboards"
}

export enum ProjectDataComponent {
    Storage = "Storage",
    Tables = "Tables"
}

export enum SortOrder {
    asc = "asc",
    desc = "desc"
}

export enum DataType {
    BOOLEAN = "BOOLEAN",
    DATE = "DATE",
    DOUBLE = "DOUBLE",
    FILE = "FILE",
    INT = "INT",
    JSON = "JSON",
    REAL = "REAL",
    TEXT = "TEXT",
    TIMESTAMP = "TIMESTAMP",
    TIMESTAMPTZ = "TIMESTAMPTZ",
    DROPDOWN = "DROPDOWN"
}

export enum ExportType {
    TSV = "TSV",
    XLSX = "XLSX"
}

export enum AggregationType {
    AVERAGE = "AVERAGE",
    SUM = "SUM",
    COUNT = "COUNT"
}

export enum OperationType {
    EQ = "EQ",
    NEQ = "NEQ",
    GT = "GT",
    GTE = "GTE",
    LT = "LT",
    LTE = "LTE",
    IN = "IN",
    NOT_IN = "NOT_IN",
    ILIKE = "ILIKE",
    IS = "IS",
    BETWEEN = "BETWEEN",
    NOT_BETWEEN = "NOT_BETWEEN"
}

export interface ApiWhere {
    id?: Nullable<string>;
    projectId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<ApiVisibilityTypeWrapper>;
}

export interface ApiVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface ProjectIdSlug {
    projectId: string;
    slug: string;
}

export interface FindUniqueApiRequest {
    id?: Nullable<string>;
    projectIdSlug?: Nullable<ProjectIdSlug>;
}

export interface FindAllApisRequest {
    where?: Nullable<ApiWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateApiRequest {
    projectId: string;
    slug: string;
    name: string;
    visibility?: Nullable<VisibilityType>;
}

export interface AppPageComponentWhere {
    id?: Nullable<string>;
    appPageId?: Nullable<string>;
    dashboardId?: Nullable<string>;
    formId?: Nullable<string>;
    pageId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueAppPageComponentRequest {
    id?: Nullable<string>;
}

export interface FindAllAppPageComponentsRequest {
    where?: Nullable<AppPageComponentWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateAppPageComponentRequest {
    appPageId: string;
    dashboardId?: Nullable<string>;
    formId?: Nullable<string>;
    pageId?: Nullable<string>;
}

export interface UpdateAppPageComponentRequest {
    id: string;
    appPageId?: Nullable<string>;
    dashboardId?: Nullable<string>;
    formId?: Nullable<string>;
    pageId?: Nullable<string>;
}

export interface DeleteAppPageComponentRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface AppPageWhere {
    id?: Nullable<string>;
    appId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueAppPageRequest {
    id?: Nullable<string>;
}

export interface FindAllAppPagesRequest {
    where?: Nullable<AppPageWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateAppPageRequest {
    appId: string;
    order: number;
}

export interface UpdateAppPageRequest {
    id: string;
    appId?: Nullable<string>;
    order?: Nullable<number>;
}

export interface UpdateAppPagesRequest {
    appPages: UpdateAppPageRequest[];
}

export interface DeleteAppPageRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface AppWhere {
    id?: Nullable<string>;
    project?: Nullable<Project>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<AppVisibilityTypeWrapper>;
}

export interface AppVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueAppRequest {
    id?: Nullable<string>;
    projectIdSlug?: Nullable<ProjectIdSlug>;
}

export interface FindAllAppsRequest {
    where?: Nullable<AppWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateAppRequest {
    projectId: string;
    slug: string;
    name: string;
    configuration: string;
    contents?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateAppRequest {
    id: string;
    projectId?: Nullable<string>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    contents?: Nullable<string>;
    configuration?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface DeleteAppRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface AuthenticationRequest {
    token: string;
}

export interface HasGroupPermissionRequest {
    id: string;
    userId: string;
    identifier: string;
}

export interface HasOrganisationPermissionRequest {
    id: string;
    userId: string;
    identifier: string;
}

export interface HasProjectPermissionRequest {
    id: string;
    userId: string;
    identifier: string;
}

export interface HasSitePermissionRequest {
    id: string;
    identifier: string;
}

export interface DashboardComponentWhere {
    id?: Nullable<string>;
    dashboardId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    name?: Nullable<string>;
    type?: Nullable<DashboardComponentType>;
    configuration?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<DashboardComponentVisibilityTypeWrapper>;
}

export interface DashboardComponentVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface DashboardIdName {
    dashboardId: string;
    name: string;
}

export interface FindUniqueDashboardComponentRequest {
    id?: Nullable<string>;
    dashboardIdName?: Nullable<DashboardIdName>;
}

export interface FindAllDashboardComponentsRequest {
    where?: Nullable<DashboardComponentWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateDashboardComponentRequest {
    dashboardId: string;
    name: string;
    type: DashboardComponentType;
    configuration: string;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateDashboardComponentRequest {
    id: string;
    dashboardId?: Nullable<string>;
    name?: Nullable<string>;
    type?: Nullable<DashboardComponentType>;
    configuration?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface DeleteDashboardComponentRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface CopyDashboardComponentsRequest {
    dashboardComponentIds: Nullable<string>[];
    dashboardId: string;
}

export interface DashboardWhere {
    id?: Nullable<string>;
    projectId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<DashboardVisibilityTypeWrapper>;
}

export interface DashboardVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueDashboardRequest {
    id?: Nullable<string>;
    projectIdSlug?: Nullable<ProjectIdSlug>;
}

export interface FindAllDashboardsRequest {
    where?: Nullable<DashboardWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateDashboardRequest {
    projectId: string;
    slug: string;
    name: string;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateDashboardRequest {
    id: string;
    projectId?: Nullable<string>;
    name?: Nullable<string>;
    slug?: Nullable<string>;
    allowSharing?: Nullable<boolean>;
    visibility?: Nullable<VisibilityType>;
}

export interface DeleteDashboardRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface DatasourceWhere {
    id?: Nullable<string>;
    dashboardId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    tested?: Nullable<boolean>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    type?: Nullable<DatasourceType>;
    configuration?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<DatasourceVisibilityTypeWrapper>;
}

export interface DatasourceVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueDatasourceRequest {
    id?: Nullable<string>;
    dashboardIdName?: Nullable<DashboardIdName>;
}

export interface FindAllDatasourcesRequest {
    where?: Nullable<DatasourceWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateDatasourceRequest {
    dashboardId: string;
    tested?: Nullable<boolean>;
    name: string;
    description?: Nullable<string>;
    type?: Nullable<DatasourceType>;
    configuration: string;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateDatasourceRequest {
    id: string;
    dashboardId?: Nullable<string>;
    tested?: Nullable<boolean>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    type?: Nullable<DatasourceType>;
    configuration?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface DeleteDatasourceRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface FormWhere {
    id?: Nullable<string>;
    project?: Nullable<Project>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    emailList?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<FormVisibilityTypeWrapper>;
}

export interface FormVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueFormRequest {
    id?: Nullable<string>;
    projectIdSlug?: Nullable<ProjectIdSlug>;
}

export interface FindAllFormsRequest {
    where?: Nullable<FormWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateFormRequest {
    projectId: string;
    slug: string;
    name: string;
    configuration: string;
    layout?: Nullable<Layout>;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateFormRequest {
    id: string;
    projectId?: Nullable<string>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    emailList?: Nullable<string>;
    allowSharing?: Nullable<boolean>;
    configuration?: Nullable<string>;
    layout?: Nullable<Layout>;
    visibility?: Nullable<VisibilityType>;
}

export interface DeleteFormRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface QuestionWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    sectionId?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    type?: Nullable<QuestionType>;
    configuration?: Nullable<string>;
    required?: Nullable<boolean>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface SectionIdName {
    sectionId: string;
    name: string;
}

export interface FindUniqueQuestionRequest {
    id?: Nullable<string>;
    sectionIdName?: Nullable<SectionIdName>;
}

export interface FindAllQuestionsRequest {
    where?: Nullable<QuestionWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateQuestionRequest {
    sectionId: string;
    name: string;
    description?: Nullable<string>;
    content?: Nullable<string>;
    type: QuestionType;
    configuration: string;
    required: boolean;
}

export interface UpdateQuestionRequest {
    id: string;
    sectionId?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    content?: Nullable<string>;
    type?: Nullable<QuestionType>;
    configuration?: Nullable<string>;
    required?: Nullable<boolean>;
}

export interface DeleteQuestionRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface SectionWhere {
    id?: Nullable<string>;
    form?: Nullable<Form>;
    deleted?: Nullable<boolean>;
    name?: Nullable<string>;
    order?: Nullable<number>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FormIdName {
    formId: string;
    name: string;
}

export interface FindUniqueSectionRequest {
    id?: Nullable<string>;
    formIdName?: Nullable<FormIdName>;
}

export interface FindAllSectionsRequest {
    where?: Nullable<SectionWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateSectionRequest {
    formId: string;
    name: string;
    order: number;
}

export interface UpdateSectionRequest {
    id: string;
    formId?: Nullable<string>;
    name?: Nullable<string>;
    order?: Nullable<number>;
}

export interface DeleteSectionRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface GroupMemberWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    group?: Nullable<Group>;
    user?: Nullable<User>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    groupRoleId?: Nullable<string>;
}

export interface FindUniqueGroupMemberRequest {
    id?: Nullable<string>;
}

export interface FindAllGroupMembersRequest {
    where?: Nullable<GroupMemberWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateGroupMemberRequest {
    groupId: string;
    userId: string;
    role?: Nullable<RoleType>;
    groupRoleId?: Nullable<string>;
}

export interface UpdateGroupMemberRequest {
    id: string;
    role?: Nullable<RoleType>;
    groupRoleId?: Nullable<string>;
}

export interface DeleteGroupMemberRequest {
    id: string;
    permanent: boolean;
}

export interface RequestToJoinGroupRequest {
    groupId: string;
    userId: string;
}

export interface GroupPermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    identifier?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueGroupPermissionRequest {
    id?: Nullable<string>;
}

export interface FindAllGroupPermissionsRequest {
    where?: Nullable<GroupPermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface FindUniqueGroupRolePermissionsRequest {
    id?: Nullable<string>;
    name?: Nullable<string>;
    groupId?: Nullable<string>;
}

export interface GroupRolePermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    groupRoleId?: Nullable<string>;
    groupPermissionId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueGroupRolePermissionRequest {
    id?: Nullable<string>;
}

export interface FindAllGroupRolePermissionsRequest {
    where?: Nullable<GroupRolePermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateGroupRolePermissionRequest {
    groupRoleId: string;
    groupPermissionId: string;
}

export interface CreateGroupRolePermissionsRequest {
    groupRoleId: string;
    permissions: CreateGroupRolePermissionRequest[];
}

export interface UpdateGroupRolePermissionRequest {
    id: string;
    groupId?: Nullable<string>;
    name?: Nullable<string>;
}

export interface DeleteGroupRolePermissionRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface DeleteGroupRolePermissionsRequest {
    groupRoleId: string;
    rolePermissions: DeleteGroupRolePermissionRequest[];
}

export interface GroupRolesWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    default?: Nullable<boolean>;
    name?: Nullable<string>;
    groupId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueGroupRoleRequest {
    id?: Nullable<string>;
}

export interface FindAllGroupRolesRequest {
    where?: Nullable<GroupRolesWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateGroupRoleRequest {
    groupId: string;
    name: string;
}

export interface UpdateGroupRoleRequest {
    id: string;
    groupId?: Nullable<string>;
    name?: Nullable<string>;
}

export interface DeleteGroupRoleRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface GroupWhere {
    id?: Nullable<string>;
    organisation?: Nullable<Organisation>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<GroupVisibilityTypeWrapper>;
}

export interface GroupVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface OrganisationIdSlug {
    organisationId: string;
    slug: string;
}

export interface FindUniqueGroupRequest {
    id?: Nullable<string>;
    organisationIdSlug?: Nullable<OrganisationIdSlug>;
}

export interface FindAllGroupsRequest {
    where?: Nullable<GroupWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateGroupRequest {
    organisationId: string;
    slug: string;
    name: string;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateGroupRequest {
    id: string;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface BoardColumnWhere {
    id?: Nullable<string>;
    boardId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    name?: Nullable<string>;
    order?: Nullable<number>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface BoardIdName {
    boardId: string;
    name: string;
}

export interface FindUniqueBoardColumnRequest {
    id?: Nullable<string>;
    boardIdName?: Nullable<BoardIdName>;
}

export interface FindAllBoardColumnsRequest {
    where?: Nullable<BoardColumnWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateBoardColumnRequest {
    boardId: string;
    name: string;
    order: number;
}

export interface UpdateBoardColumnRequest {
    id: string;
    boardId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    name?: Nullable<string>;
    order?: Nullable<number>;
}

export interface DeleteBoardColumnRequest {
    id: string;
    permanent: boolean;
}

export interface BoardWhere {
    id?: Nullable<string>;
    projectId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<BoardVisibilityTypeWrapper>;
}

export interface BoardVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueBoardRequest {
    id?: Nullable<string>;
    projectIdSlug?: Nullable<ProjectIdSlug>;
}

export interface FindAllBoardsRequest {
    where?: Nullable<BoardWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateBoardRequest {
    projectId: string;
    slug: string;
    name: string;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateBoardRequest {
    id: string;
    projectId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface DeleteBoardRequest {
    id: string;
    permanent: boolean;
}

export interface CardWhere {
    id?: Nullable<string>;
    boardColumnId?: Nullable<string>;
    userId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    closed?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    order?: Nullable<number>;
    configuration?: Nullable<string>;
    assigned?: Nullable<string>;
    dueDate?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface BoardColumnIdSlug {
    boardColumnId: string;
    slug: string;
}

export interface FindUniqueCardRequest {
    id?: Nullable<string>;
    boardColumnIdSlug?: Nullable<BoardColumnIdSlug>;
}

export interface FindAllCardsRequest {
    where?: Nullable<CardWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateCardRequest {
    boardColumnId: string;
    userId?: Nullable<string>;
    slug: string;
    name: string;
    description?: Nullable<string>;
    order: number;
    configuration: string;
    assigned?: Nullable<string>;
    dueDate?: Nullable<string>;
}

export interface UpdateCardRequest {
    id: string;
    boardColumnId?: Nullable<string>;
    userId?: Nullable<string>;
    closed?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    comments?: Nullable<string>;
    numberOfComments?: Nullable<number>;
    order?: Nullable<number>;
    configuration?: Nullable<string>;
    assigned?: Nullable<string>;
    dueDate?: Nullable<string>;
}

export interface DeleteCardRequest {
    id: string;
    permanent: boolean;
}

export interface NotificationTokensWhere {
    token?: Nullable<string>;
    deleted?: Nullable<boolean>;
    userId?: Nullable<string>;
    notificationType?: Nullable<PlatformType>;
    created?: Nullable<string>;
}

export interface FindUniqueNotificationTokenRequest {
    userId: string;
    notificationType: PlatformType;
}

export interface SendNotificationToUserRequest {
    token: string;
    message: string;
}

export interface FindAllNotificationTokensRequest {
    where?: Nullable<NotificationTokensWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface StoreNotificationTokenRequest {
    token: string;
    userId: string;
    notificationType: PlatformType;
}

export interface DeleteNotificationTokenRequest {
    token: string;
    permanent?: Nullable<boolean>;
}

export interface CreateOrganisationMemberRequest {
    organisationId: string;
    userId: string;
    role?: Nullable<RoleType>;
    organisationRoleId?: Nullable<string>;
}

export interface UpdateOrganisationMemberRequest {
    id: string;
    role?: Nullable<RoleType>;
    organisationRoleId?: Nullable<string>;
}

export interface FindUniqueOrganisationMemberRequest {
    id: string;
}

export interface DeleteOrganisationMemberRequest {
    id: string;
    permanent: boolean;
}

export interface OrganisationMemberWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    organisationId?: Nullable<string>;
    userId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    role?: Nullable<RoleType>;
}

export interface FindAllOrganisationMembersRequest {
    where?: Nullable<OrganisationMemberWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface RequestToJoinOrganisationRequest {
    organisationId: string;
    userId: string;
}

export interface OrganisationPermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    identifier?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueOrganisationPermissionRequest {
    id?: Nullable<string>;
}

export interface FindAllOrganisationPermissionsRequest {
    where?: Nullable<OrganisationPermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface FindUniqueOrganisationRolePermissionsRequest {
    id?: Nullable<string>;
    name?: Nullable<string>;
    organisationId?: Nullable<string>;
}

export interface OrganisationRolePermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    organisationRoleId?: Nullable<string>;
    organisationPermissionId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueOrganisationRolePermissionRequest {
    id?: Nullable<string>;
}

export interface FindAllOrganisationRolePermissionsRequest {
    where?: Nullable<OrganisationRolePermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateOrganisationRolePermissionRequest {
    organisationRoleId: string;
    organisationPermissionId: string;
}

export interface CreateOrganisationRolePermissionsRequest {
    organisationRoleId: string;
    permissions: CreateOrganisationRolePermissionRequest[];
}

export interface UpdateOrganisationRolePermissionRequest {
    id: string;
    organisationId?: Nullable<string>;
    name?: Nullable<string>;
}

export interface DeleteOrganisationRolePermissionRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface DeleteOrganisationRolePermissionsRequest {
    organisationRoleId: string;
    rolePermissions: DeleteOrganisationRolePermissionRequest[];
}

export interface OrganisationRolesWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    default?: Nullable<boolean>;
    name?: Nullable<string>;
    organisationId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueOrganisationRoleRequest {
    id?: Nullable<string>;
}

export interface FindAllOrganisationRolesRequest {
    where?: Nullable<OrganisationRolesWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateOrganisationRoleRequest {
    organisationId: string;
    name: string;
}

export interface UpdateOrganisationRoleRequest {
    id: string;
    organisationId?: Nullable<string>;
    name?: Nullable<string>;
}

export interface DeleteOrganisationRoleRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface OrganisationWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<OrganisationVisibilityTypeWrapper>;
}

export interface OrganisationVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueOrganisationRequest {
    id?: Nullable<string>;
    slug?: Nullable<string>;
}

export interface CreateOrganisationRequest {
    slug: string;
    name: string;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface FindAllOrganisationsRequest {
    where?: Nullable<OrganisationWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface UpdateOrganisationRequest {
    id: string;
    deleted?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface ProjectMemberWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    favourite?: Nullable<boolean>;
    projectId?: Nullable<string>;
    userId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    role?: Nullable<RoleType>;
    projectRoleId?: Nullable<string>;
}

export interface FindUniqueProjectMemberRequest {
    id: string;
}

export interface FindAllProjectMembersRequest {
    where?: Nullable<ProjectMemberWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateProjectMemberRequest {
    projectId: string;
    userId: string;
    role?: Nullable<RoleType>;
    projectRoleId?: Nullable<string>;
}

export interface UpdateProjectMemberRequest {
    id: string;
    role?: Nullable<RoleType>;
    favourite?: Nullable<boolean>;
    projectRoleId?: Nullable<string>;
}

export interface DeleteProjectMemberRequest {
    id: string;
    permanent: boolean;
}

export interface RequestToJoinProjectRequest {
    projectId: string;
    userId: string;
}

export interface ProjectPermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    identifier?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueProjectPermissionRequest {
    id?: Nullable<string>;
}

export interface FindUniqueProjectRolePermissionsRequest {
    id?: Nullable<string>;
    name?: Nullable<string>;
    projectId?: Nullable<string>;
}

export interface FindAllProjectPermissionsRequest {
    where?: Nullable<ProjectPermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface ProjectRolePermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    projectRoleId?: Nullable<string>;
    projectPermissionId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueProjectRolePermissionRequest {
    id?: Nullable<string>;
}

export interface FindAllProjectRolePermissionsRequest {
    where?: Nullable<ProjectRolePermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateProjectRolePermissionRequest {
    projectRoleId: string;
    projectPermissionId: string;
}

export interface CreateProjectRolePermissionsRequest {
    projectRoleId: string;
    permissions: CreateProjectRolePermissionRequest[];
}

export interface UpdateProjectRolePermissionRequest {
    id: string;
    projectId?: Nullable<string>;
    name?: Nullable<string>;
}

export interface DeleteProjectRolePermissionRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface DeleteProjectRolePermissionsRequest {
    projectRoleId: string;
    rolePermissions: DeleteProjectRolePermissionRequest[];
}

export interface ProjectRolesWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    default?: Nullable<boolean>;
    name?: Nullable<string>;
    projectId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueProjectRoleRequest {
    id?: Nullable<string>;
    projectId?: Nullable<string>;
    name?: Nullable<string>;
}

export interface FindAllProjectRolesRequest {
    where?: Nullable<ProjectRolesWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateProjectRoleRequest {
    projectId: string;
    name: string;
}

export interface UpdateProjectRoleRequest {
    id: string;
    projectId?: Nullable<string>;
    name?: Nullable<string>;
}

export interface DeleteProjectRoleRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface UserIdSlug {
    userId: string;
    slug: string;
}

export interface OrganisationIdGroupIdSlug {
    organisationId: string;
    groupId: string;
    slug: string;
}

export interface ProjectWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    template?: Nullable<boolean>;
    slug?: Nullable<string>;
    userId?: Nullable<string>;
    groupId?: Nullable<string>;
    organisationId?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<ProjectVisibilityTypeWrapper>;
}

export interface ProjectVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueProjectRequest {
    id?: Nullable<string>;
    userIdSlug?: Nullable<UserIdSlug>;
    organisationIdSlug?: Nullable<OrganisationIdSlug>;
    organisationIdGroupIdSlug?: Nullable<OrganisationIdGroupIdSlug>;
}

export interface FindAllProjectsRequest {
    where?: Nullable<ProjectWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateProjectRequest {
    slug: string;
    userId?: Nullable<string>;
    groupId?: Nullable<string>;
    organisationId?: Nullable<string>;
    name: string;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateProjectRequest {
    id: string;
    userId?: Nullable<string>;
    groupId?: Nullable<string>;
    organisationID?: Nullable<string>;
    deleted?: Nullable<boolean>;
    template?: Nullable<boolean>;
    slug?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface ChangeOwnerRequest {
    id: string;
    userId?: Nullable<string>;
    GroupId?: Nullable<string>;
    OrganisationId?: Nullable<string>;
}

export interface DeleteProjectRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface CopyProjectRequest {
    sourceId: string;
    organisationId?: Nullable<string>;
    userId?: Nullable<string>;
    groupId?: Nullable<string>;
    slug: string;
    visibility?: Nullable<VisibilityType>;
    name: string;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    data: string[];
    structures: string[];
}

export interface SiteSearchRequest {
    search?: Nullable<string>;
}

export interface ExtractZipRequest {
    reference: string;
}

export interface ColumnWhere {
    id?: Nullable<string>;
    tableId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    primaryKey?: Nullable<boolean>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    dataType?: Nullable<DataType>;
    required?: Nullable<boolean>;
    default?: Nullable<string>;
    order?: Nullable<number>;
    dataTypeConfiguration?: Nullable<string>;
    unique?: Nullable<boolean>;
    foreignKey?: Nullable<boolean>;
    autoIncrement?: Nullable<boolean>;
    isArray?: Nullable<boolean>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface ColumnOrderBy {
    id?: Nullable<SortOrder>;
    tableId?: Nullable<SortOrder>;
    name?: Nullable<SortOrder>;
    description?: Nullable<SortOrder>;
    order?: Nullable<SortOrder>;
    created?: Nullable<SortOrder>;
    updated?: Nullable<SortOrder>;
}

export interface TableIdName {
    tableId: string;
    name: string;
}

export interface FindUniqueColumnRequest {
    id?: Nullable<string>;
    tableIdName?: Nullable<TableIdName>;
}

export interface FindAllColumnsRequest {
    where?: Nullable<ColumnWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
    orderBy?: Nullable<ColumnOrderBy>;
    dashboardId?: Nullable<string>;
}

export interface CreateColumnRequest {
    tableId: string;
    primaryKey?: Nullable<boolean>;
    name: string;
    description?: Nullable<string>;
    dataType: DataType;
    required?: Nullable<boolean>;
    default?: Nullable<string>;
    order: number;
    dataTypeConfiguration?: Nullable<string>;
    unique?: Nullable<boolean>;
    foreignKey?: Nullable<boolean>;
    autoIncrement?: Nullable<boolean>;
    isArray?: Nullable<boolean>;
}

export interface CreateColumnsRequest {
    tableId: string;
    columns: CreateColumnRequest[];
}

export interface CreateColumnsFileUploadRequest {
    tableId: string;
    gcpBucketId: string;
    filePath: string;
    primaryKeyColumnName?: Nullable<string>;
    autoIncrementId?: Nullable<boolean>;
}

export interface UpdateColumnRequest {
    id: string;
    tableId?: Nullable<string>;
    primaryKey?: Nullable<boolean>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    dataType?: Nullable<DataType>;
    required?: Nullable<boolean>;
    default?: Nullable<string>;
    order?: Nullable<number>;
    dataTypeConfiguration?: Nullable<string>;
    unique?: Nullable<boolean>;
    foreignKey?: Nullable<boolean>;
    autoIncrement?: Nullable<boolean>;
    isArray?: Nullable<boolean>;
}

export interface UpdateColumnsRequest {
    columns: UpdateColumnRequest[];
}

export interface DeleteColumnRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface CopyColumnRequest {
    id: string;
    tableId: string;
}

export interface CopyColumnsRequest {
    columnIds: string[];
    tableId: string;
}

export interface CreateProjectSchemaRequest {
    projectId: string;
}

export interface InsertDataRequest {
    tableId?: Nullable<string>;
    projectIdName?: Nullable<ProjectIdName>;
    rows: DataRow[];
}

export interface InsertDataFromFileRequest {
    tableId: string;
    filePath: string;
    email: string;
}

export interface InsertDataFromFormRequest {
    tableId?: Nullable<string>;
    projectIdName?: Nullable<ProjectIdName>;
    formName: string;
    submitterEmail: string;
    rows: DataRow[];
    formId?: Nullable<string>;
    projectId?: Nullable<string>;
}

export interface DataRowInput {
    columns: DataColumn[];
}

export interface DataColumnInput {
    columnId: string;
    value?: Nullable<string>;
}

export interface FindAllDataRowsRequest {
    tableId?: Nullable<string>;
    projectIdName?: Nullable<ProjectIdName>;
}

export interface DownloadDataToFileRequest {
    tableId?: Nullable<string>;
    exportType: ExportType;
    projectIdName?: Nullable<ProjectIdName>;
    email: string;
    query?: Nullable<QueryTableRequest>;
}

export interface DownloadDataToFileResponse {
    response?: Nullable<string>;
}

export interface FindDataRowsRequest {
    tableId?: Nullable<string>;
    projectIdName?: Nullable<ProjectIdName>;
    orderBy: string;
    orderByType: DataType;
    limit: number;
    cursor?: Nullable<string>;
    search?: Nullable<string>;
}

export interface UpdateDataColumnValuesRequest {
    tableId?: Nullable<string>;
    projectIdName?: Nullable<ProjectIdName>;
    primaryKeyValue: string;
    row: DataRowInput;
}

export interface DeleteDataRowsRequest {
    tableId?: Nullable<string>;
    projectIdName?: Nullable<ProjectIdName>;
    primaryKeyValues: string[];
}

export interface ClearDataRowsRequest {
    tableId: string;
}

export interface Select {
    column: string;
    rename?: Nullable<string>;
    aggregate?: Nullable<AggregationType>;
}

export interface Where {
    args?: Nullable<string[]>;
    operation?: Nullable<OperationType>;
    and?: Nullable<Where[]>;
    or?: Nullable<Where[]>;
}

export interface Order {
    column: string;
    ascending?: Nullable<boolean>;
}

export interface QueryTableRequest {
    select: Select[];
    from: string;
    where?: Nullable<Where>;
    group?: Nullable<string[]>;
    order?: Nullable<Order[]>;
    offset?: Nullable<number>;
    limit?: Nullable<number>;
    dashboardId?: Nullable<string>;
}

export interface TableWhere {
    id?: Nullable<string>;
    projectId?: Nullable<string>;
    deleted?: Nullable<boolean>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<TableVisibilityTypeWrapper>;
}

export interface TableVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface ProjectIdName {
    projectId: string;
    name: string;
}

export interface FindUniqueTableRequest {
    id?: Nullable<string>;
    projectIdName?: Nullable<ProjectIdName>;
}

export interface FindAllTablesRequest {
    where?: Nullable<TableWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateTableRequest {
    projectId: string;
    name: string;
    description?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdateTableRequest {
    id: string;
    projectId?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface CountTableRowsRequest {
    id: string;
}

export interface CountTableRowsResponse {
    count: number;
}

export interface CopyTableRequest {
    id: string;
    projectId: string;
}

export interface CopyTablesRequest {
    sourceProjectId: string;
    destinationProjectId: string;
}

export interface DeleteTableRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface UserIdName {
    userId: string;
    name: string;
}

export interface ApiKeyWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    userId?: Nullable<string>;
    name?: Nullable<string>;
    description?: Nullable<string>;
    key?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueApiKeyRequest {
    id?: Nullable<string>;
    userIdName?: Nullable<UserIdName>;
    key?: Nullable<string>;
}

export interface FindAllApiKeysRequest {
    where?: Nullable<ApiKeyWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreateApiKeyRequest {
    name: string;
    description?: Nullable<string>;
}

export interface UpdateApiKeyRequest {
    id: string;
    name?: Nullable<string>;
    description?: Nullable<string>;
}

export interface DeleteApiKeyRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface SitePermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    identifier?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueSitePermissionRequest {
    id?: Nullable<string>;
}

export interface FindAllSitePermissionsRequest {
    where?: Nullable<SitePermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface SiteRolePermissionsWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    siteRoleId?: Nullable<string>;
    sitePermissionId?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueSiteRolePermissionRequest {
    id?: Nullable<string>;
}

export interface FindAllSiteRolePermissionsRequest {
    where?: Nullable<SiteRolePermissionsWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface SiteRolesWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    default?: Nullable<boolean>;
    name?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
}

export interface FindUniqueSiteRoleRequest {
    id?: Nullable<string>;
}

export interface FindAllSiteRolesRequest {
    where?: Nullable<SiteRolesWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface UserWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    verified?: Nullable<boolean>;
    email?: Nullable<string>;
    displayName?: Nullable<string>;
    username?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<UserVisibilityTypeWrapper>;
    siteRoleId?: Nullable<string>;
}

export interface UserVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniqueUserRequest {
    id?: Nullable<string>;
    username?: Nullable<string>;
    email?: Nullable<string>;
}

export interface ValidUserRequest {
    email: string;
    username: string;
}

export interface CreateUserRequest {
    id: string;
    email: string;
    displayName: string;
    username: string;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
    siteRoleId?: Nullable<string>;
}

export interface UpdateUserRequest {
    id: string;
    displayName?: Nullable<string>;
    email?: Nullable<string>;
    username?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface FindAllUsersRequest {
    where?: Nullable<UserWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface PageWhere {
    id?: Nullable<string>;
    deleted?: Nullable<boolean>;
    published?: Nullable<boolean>;
    pinned?: Nullable<boolean>;
    projectId?: Nullable<string>;
    project?: Nullable<Project>;
    name?: Nullable<string>;
    slug?: Nullable<string>;
    description?: Nullable<string>;
    content?: Nullable<string>;
    created?: Nullable<string>;
    updated?: Nullable<string>;
    visibility?: Nullable<PageVisibilityTypeWrapper>;
}

export interface PageVisibilityTypeWrapper {
    in: VisibilityType[];
}

export interface FindUniquePageRequest {
    id?: Nullable<string>;
    projectIdSlug?: Nullable<ProjectIdSlug>;
}

export interface FindAllPagesRequest {
    where?: Nullable<PageWhere>;
    limit?: Nullable<number>;
    page?: Nullable<number>;
}

export interface CreatePageRequest {
    projectId: string;
    name: string;
    slug: string;
    description?: Nullable<string>;
    visibility?: Nullable<VisibilityType>;
}

export interface UpdatePageRequest {
    id: string;
    deleted?: Nullable<boolean>;
    published?: Nullable<boolean>;
    pinned?: Nullable<boolean>;
    name?: Nullable<string>;
    slug?: Nullable<string>;
    description?: Nullable<string>;
    content?: Nullable<string>;
    allowSharing?: Nullable<boolean>;
    visibility?: Nullable<VisibilityType>;
}

export interface DeletePageRequest {
    id: string;
    permanent?: Nullable<boolean>;
}

export interface Api {
    id: string;
    projectId: string;
    deleted: boolean;
    slug: string;
    name: string;
    created: string;
    updated: string;
    visibility: VisibilityType;
}

export interface Project {
    id: string;
    apis?: Nullable<Nullable<Api>[]>;
    apps?: Nullable<Nullable<App>[]>;
    dashboards?: Nullable<Nullable<Dashboard>[]>;
    forms?: Nullable<Nullable<Form>[]>;
    board?: Nullable<Nullable<Board>[]>;
    projectMembers?: Nullable<Nullable<ProjectMember>[]>;
    roles?: Nullable<Nullable<ProjectRole>[]>;
    deleted: boolean;
    template: boolean;
    slug: string;
    userId?: Nullable<string>;
    user?: Nullable<User>;
    groupId?: Nullable<string>;
    group?: Nullable<Group>;
    groupLabel?: Nullable<GroupLabel>;
    organisationId?: Nullable<string>;
    organisation?: Nullable<Organisation>;
    organisationLabel?: Nullable<OrganisationLabel>;
    name: string;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created: string;
    updated: string;
    visibility: VisibilityType;
    tables?: Nullable<Nullable<Table>[]>;
    pages?: Nullable<Nullable<Page>[]>;
}

export interface IQuery {
    api(request?: Nullable<FindUniqueApiRequest>): Api | Promise<Api>;
    findAllApis(request?: Nullable<FindAllApisRequest>): Api[] | Promise<Api[]>;
    appPageComponent(request?: Nullable<FindUniqueAppPageComponentRequest>): AppPageComponent | Promise<AppPageComponent>;
    findAllAppPageComponents(request?: Nullable<FindAllAppPageComponentsRequest>): AppPageComponent[] | Promise<AppPageComponent[]>;
    appPage(request?: Nullable<FindUniqueAppPageRequest>): AppPage | Promise<AppPage>;
    findAllAppPages(request?: Nullable<FindAllAppPagesRequest>): AppPage[] | Promise<AppPage[]>;
    app(request?: Nullable<FindUniqueAppRequest>): App | Promise<App>;
    findAllApps(request?: Nullable<FindAllAppsRequest>): App[] | Promise<App[]>;
    authentication(request?: Nullable<AuthenticationRequest>): AuthUser | Promise<AuthUser>;
    hasGroupPermissionUsers(request?: Nullable<HasGroupPermissionRequest>): HasGroupPermissionResponse | Promise<HasGroupPermissionResponse>;
    hasOrganisationPermissionUsers(request?: Nullable<HasOrganisationPermissionRequest>): HasOrganisationPermissionResponse | Promise<HasOrganisationPermissionResponse>;
    hasProjectPermissionUsers(request?: Nullable<HasProjectPermissionRequest>): HasProjectPermissionResponse | Promise<HasProjectPermissionResponse>;
    hasSitePermissionUsers(request?: Nullable<HasSitePermissionRequest>): HasSitePermissionResponse | Promise<HasSitePermissionResponse>;
    findUniqueDashboardComponent(request?: Nullable<FindUniqueDashboardComponentRequest>): DashboardComponent | Promise<DashboardComponent>;
    findAllDashboardComponents(request?: Nullable<FindAllDashboardComponentsRequest>): DashboardComponent[] | Promise<DashboardComponent[]>;
    findUniqueDashboard(request?: Nullable<FindUniqueDashboardRequest>): Dashboard | Promise<Dashboard>;
    findAllDashboards(request?: Nullable<FindAllDashboardsRequest>): Dashboard[] | Promise<Dashboard[]>;
    findUniqueDatasource(request?: Nullable<FindUniqueDatasourceRequest>): Datasource | Promise<Datasource>;
    findAllDatasources(request?: Nullable<FindAllDatasourcesRequest>): Datasource[] | Promise<Datasource[]>;
    form(request?: Nullable<FindUniqueFormRequest>): Form | Promise<Form>;
    findAllForms(request?: Nullable<FindAllFormsRequest>): Form[] | Promise<Form[]>;
    findUniqueQuestion(request?: Nullable<FindUniqueQuestionRequest>): Question | Promise<Question>;
    findAllQuestions(request?: Nullable<FindAllQuestionsRequest>): Question[] | Promise<Question[]>;
    findUniqueSection(request?: Nullable<FindUniqueSectionRequest>): Section | Promise<Section>;
    findAllSections(request?: Nullable<FindAllSectionsRequest>): Section[] | Promise<Section[]>;
    findMeGroupMember(request?: Nullable<FindAllGroupMembersRequest>): GroupMember | Promise<GroupMember>;
    groupMember(request?: Nullable<FindUniqueGroupMemberRequest>): GroupMember | Promise<GroupMember>;
    findAllGroupMembers(request?: Nullable<FindAllGroupMembersRequest>): GroupMember[] | Promise<GroupMember[]>;
    findUniqueGroupRolePermissions(request?: Nullable<FindUniqueGroupRolePermissionsRequest>): Nullable<GroupPermission>[] | Promise<Nullable<GroupPermission>[]>;
    findUniqueGroupPermission(request?: Nullable<FindUniqueGroupPermissionRequest>): GroupPermission | Promise<GroupPermission>;
    findAllGroupPermissionsRequest(request?: Nullable<FindAllGroupPermissionsRequest>): GroupPermission[] | Promise<GroupPermission[]>;
    findUniqueGroupRolePermissionRequest(request?: Nullable<FindUniqueGroupRolePermissionRequest>): GroupRolePermission | Promise<GroupRolePermission>;
    findAllGroupRolePermissionsRequest(request?: Nullable<FindAllGroupRolePermissionsRequest>): GroupRolePermission[] | Promise<GroupRolePermission[]>;
    findUniqueGroupRole(request?: Nullable<FindUniqueGroupRoleRequest>): GroupRole | Promise<GroupRole>;
    findAllGroupRoles(request?: Nullable<FindAllGroupRolesRequest>): GroupRole[] | Promise<GroupRole[]>;
    listCoreGroupRoles(): GroupRole[] | Promise<GroupRole[]>;
    group(request?: Nullable<FindUniqueGroupRequest>): Group | Promise<Group>;
    findAllGroups(request?: Nullable<FindAllGroupsRequest>): Group[] | Promise<Group[]>;
    browseGroups(): Group[] | Promise<Group[]>;
    findUniqueBoardColumn(request?: Nullable<FindUniqueBoardColumnRequest>): BoardColumn | Promise<BoardColumn>;
    findAllBoardColumns(request?: Nullable<FindAllBoardColumnsRequest>): BoardColumn[] | Promise<BoardColumn[]>;
    findUniqueBoard(request?: Nullable<FindUniqueBoardRequest>): Board | Promise<Board>;
    findAllBoards(request?: Nullable<FindAllBoardsRequest>): Board[] | Promise<Board[]>;
    findUniqueCard(request?: Nullable<FindUniqueCardRequest>): Card | Promise<Card>;
    findAllCards(request?: Nullable<FindAllCardsRequest>): Card[] | Promise<Card[]>;
    findUniqueNotificationToken(request?: Nullable<FindUniqueNotificationTokenRequest>): NotificationToken | Promise<NotificationToken>;
    findAllNotificationTokens(request?: Nullable<FindAllNotificationTokensRequest>): NotificationToken[] | Promise<NotificationToken[]>;
    findMeOrganisationMember(request?: Nullable<FindAllOrganisationMembersRequest>): OrganisationMember[] | Promise<OrganisationMember[]>;
    findUniqueOrganisationMember(request?: Nullable<FindUniqueOrganisationMemberRequest>): OrganisationMember | Promise<OrganisationMember>;
    findAllOrganisationMembers(request?: Nullable<FindAllOrganisationMembersRequest>): OrganisationMember[] | Promise<OrganisationMember[]>;
    findUniqueOrganisationRolePermissions(request?: Nullable<FindUniqueOrganisationRolePermissionsRequest>): Nullable<OrganisationPermission>[] | Promise<Nullable<OrganisationPermission>[]>;
    findUniqueOrganisationPermission(request?: Nullable<FindUniqueOrganisationPermissionRequest>): OrganisationPermission | Promise<OrganisationPermission>;
    findAllOrganisationPermissionsRequest(request?: Nullable<FindAllOrganisationPermissionsRequest>): OrganisationPermission[] | Promise<OrganisationPermission[]>;
    findUniqueOrganisationRolePermissionRequest(request?: Nullable<FindUniqueOrganisationRolePermissionRequest>): OrganisationRolePermission | Promise<OrganisationRolePermission>;
    findAllOrganisationRolePermissionsRequest(request?: Nullable<FindAllOrganisationRolePermissionsRequest>): OrganisationRolePermission[] | Promise<OrganisationRolePermission[]>;
    listCoreOrganisationRoles(): OrganisationRole[] | Promise<OrganisationRole[]>;
    findUniqueOrganisationRole(request?: Nullable<FindUniqueOrganisationRoleRequest>): OrganisationRole | Promise<OrganisationRole>;
    findAllOrganisationRoles(request?: Nullable<FindAllOrganisationRolesRequest>): OrganisationRole[] | Promise<OrganisationRole[]>;
    findUniqueOrganisation(request?: Nullable<FindUniqueOrganisationRequest>): Organisation | Promise<Organisation>;
    findAllOrganisations(request?: Nullable<FindAllOrganisationsRequest>): Organisation[] | Promise<Organisation[]>;
    browseOrganisations(): Organisation[] | Promise<Organisation[]>;
    findMeProjectMembers(): ProjectMember[] | Promise<ProjectMember[]>;
    findUniqueProjectMember(request?: Nullable<FindUniqueProjectMemberRequest>): ProjectMember | Promise<ProjectMember>;
    findAllProjectMembers(request?: Nullable<FindAllProjectMembersRequest>): ProjectMember[] | Promise<ProjectMember[]>;
    findUniqueProjectRolePermissions(request?: Nullable<FindUniqueProjectRolePermissionsRequest>): Nullable<ProjectPermission>[] | Promise<Nullable<ProjectPermission>[]>;
    findUniqueProjectPermission(request?: Nullable<FindUniqueProjectPermissionRequest>): ProjectPermission | Promise<ProjectPermission>;
    findAllProjectPermissionsRequest(request?: Nullable<FindAllProjectPermissionsRequest>): ProjectPermission[] | Promise<ProjectPermission[]>;
    findUniqueProjectRolePermissionRequest(request?: Nullable<FindUniqueProjectRolePermissionRequest>): ProjectRolePermission | Promise<ProjectRolePermission>;
    findAllProjectRolePermissionsRequest(request?: Nullable<FindAllProjectRolePermissionsRequest>): ProjectRolePermission[] | Promise<ProjectRolePermission[]>;
    getBaseProjectRoles(): ProjectRole[] | Promise<ProjectRole[]>;
    findUniqueProjectRole(request?: Nullable<FindUniqueProjectRoleRequest>): ProjectRole | Promise<ProjectRole>;
    findAllProjectRoles(request?: Nullable<FindAllProjectRolesRequest>): ProjectRole[] | Promise<ProjectRole[]>;
    findUniqueProject(request?: Nullable<FindUniqueProjectRequest>): Project | Promise<Project>;
    findAllProjects(request?: Nullable<FindAllProjectsRequest>): Project[] | Promise<Project[]>;
    browseProjects(): Project[] | Promise<Project[]>;
    findAllTemplates(): Project[] | Promise<Project[]>;
    searchSite(request: SiteSearchRequest): Nullable<SiteSearchResponse> | Promise<Nullable<SiteSearchResponse>>;
    findUniqueColumn(request: FindUniqueColumnRequest): Column | Promise<Column>;
    findAllColumns(request: FindAllColumnsRequest): Column[] | Promise<Column[]>;
    findAllDataRows(request: FindAllDataRowsRequest): Nullable<Nullable<DataRow>[]> | Promise<Nullable<Nullable<DataRow>[]>>;
    downloadDataToFile(request: DownloadDataToFileRequest): Nullable<DownloadDataToFileResponse> | Promise<Nullable<DownloadDataToFileResponse>>;
    findDataRows(request: FindDataRowsRequest): Nullable<DataRowsResponse> | Promise<Nullable<DataRowsResponse>>;
    queryTable(request: QueryTableRequest): QueryTableResponse | Promise<QueryTableResponse>;
    findUniqueTable(request: FindUniqueTableRequest): Table | Promise<Table>;
    findAllTables(request: FindAllTablesRequest): Table[] | Promise<Table[]>;
    apiKey(request?: Nullable<FindUniqueApiKeyRequest>): ApiKey | Promise<ApiKey>;
    apiKeys(request?: Nullable<FindAllApiKeysRequest>): ApiKey[] | Promise<ApiKey[]>;
    findUniqueSitePermission(request?: Nullable<FindUniqueSitePermissionRequest>): SitePermission | Promise<SitePermission>;
    findAllSitePermissionsRequest(request?: Nullable<FindAllSitePermissionsRequest>): SitePermission[] | Promise<SitePermission[]>;
    findUniqueSiteRolePermissionRequest(request?: Nullable<FindUniqueSiteRolePermissionRequest>): SiteRolePermission | Promise<SiteRolePermission>;
    findAllSiteRolePermissionsRequest(request?: Nullable<FindAllSiteRolePermissionsRequest>): SiteRolePermission[] | Promise<SiteRolePermission[]>;
    findUniqueSiteRoleRequest(request?: Nullable<FindUniqueSiteRoleRequest>): SiteRole | Promise<SiteRole>;
    findAllSiteRolesRequest(request?: Nullable<FindAllSiteRolesRequest>): SiteRole[] | Promise<SiteRole[]>;
    me(): User | Promise<User>;
    user(request?: Nullable<FindUniqueUserRequest>): User | Promise<User>;
    findAllUsers(request?: Nullable<FindAllUsersRequest>): User[] | Promise<User[]>;
    browseUsers(): User[] | Promise<User[]>;
    findUniquePage(request?: Nullable<FindUniquePageRequest>): Page | Promise<Page>;
    findAllPages(request?: Nullable<FindAllPagesRequest>): Page[] | Promise<Page[]>;
}

export interface IMutation {
    createApi(request?: Nullable<CreateApiRequest>): Api | Promise<Api>;
    createAppPageComponent(request?: Nullable<CreateAppPageComponentRequest>): AppPageComponent | Promise<AppPageComponent>;
    deleteAppPageComponent(request?: Nullable<DeleteAppPageComponentRequest>): AppPageComponent | Promise<AppPageComponent>;
    updateAppPageComponent(request?: Nullable<UpdateAppPageComponentRequest>): AppPageComponent | Promise<AppPageComponent>;
    createAppPage(request?: Nullable<CreateAppPageRequest>): AppPage | Promise<AppPage>;
    deleteAppPage(request?: Nullable<DeleteAppPageRequest>): AppPage | Promise<AppPage>;
    updateAppPage(request?: Nullable<UpdateAppPageRequest>): AppPage | Promise<AppPage>;
    updateAppPages(request?: Nullable<UpdateAppPagesRequest>): BatchPayload | Promise<BatchPayload>;
    createApp(request?: Nullable<CreateAppRequest>): App | Promise<App>;
    deleteApp(request?: Nullable<DeleteAppRequest>): App | Promise<App>;
    updateApp(request?: Nullable<UpdateAppRequest>): App | Promise<App>;
    createDashboardComponent(request?: Nullable<CreateDashboardComponentRequest>): DashboardComponent | Promise<DashboardComponent>;
    deleteDashboardComponent(request?: Nullable<DeleteDashboardComponentRequest>): DashboardComponent | Promise<DashboardComponent>;
    updateDashboardComponent(request?: Nullable<UpdateDashboardComponentRequest>): DashboardComponent | Promise<DashboardComponent>;
    createDashboard(request?: Nullable<CreateDashboardRequest>): Dashboard | Promise<Dashboard>;
    deleteDashboard(request?: Nullable<DeleteDashboardRequest>): Dashboard | Promise<Dashboard>;
    updateDashboard(request?: Nullable<UpdateDashboardRequest>): Dashboard | Promise<Dashboard>;
    createDatasource(request?: Nullable<CreateDatasourceRequest>): Datasource | Promise<Datasource>;
    deleteDatasource(request?: Nullable<DeleteDatasourceRequest>): Datasource | Promise<Datasource>;
    updateDatasource(request?: Nullable<UpdateDatasourceRequest>): Datasource | Promise<Datasource>;
    createForm(request?: Nullable<CreateFormRequest>): Form | Promise<Form>;
    deleteForm(request?: Nullable<DeleteFormRequest>): Form | Promise<Form>;
    updateForm(request?: Nullable<UpdateFormRequest>): Form | Promise<Form>;
    createQuestion(request?: Nullable<CreateQuestionRequest>): Question | Promise<Question>;
    deleteQuestion(request?: Nullable<DeleteQuestionRequest>): Question | Promise<Question>;
    updateQuestion(request?: Nullable<UpdateQuestionRequest>): Question | Promise<Question>;
    createSection(request?: Nullable<CreateSectionRequest>): Section | Promise<Section>;
    deleteSection(request?: Nullable<DeleteSectionRequest>): Section | Promise<Section>;
    updateSection(request?: Nullable<UpdateSectionRequest>): Section | Promise<Section>;
    createGroupMember(request?: Nullable<CreateGroupMemberRequest>): GroupMember | Promise<GroupMember>;
    updateGroupMember(request?: Nullable<UpdateGroupMemberRequest>): GroupMember | Promise<GroupMember>;
    deleteGroupMember(request?: Nullable<DeleteGroupMemberRequest>): GroupMember | Promise<GroupMember>;
    requestToJoinGroup(request?: Nullable<RequestToJoinGroupRequest>): RequestToJoinGroupResponse | Promise<RequestToJoinGroupResponse>;
    createGroupRolePermission(request?: Nullable<CreateGroupRolePermissionRequest>): GroupRolePermission | Promise<GroupRolePermission>;
    createGroupRolePermissions(request?: Nullable<CreateGroupRolePermissionsRequest>): BatchPayload | Promise<BatchPayload>;
    deleteGroupRolePermission(request?: Nullable<DeleteGroupRolePermissionRequest>): GroupRolePermission | Promise<GroupRolePermission>;
    deleteGroupRolePermissions(request?: Nullable<DeleteGroupRolePermissionsRequest>): BatchPayload | Promise<BatchPayload>;
    updateGroupRolePermission(request?: Nullable<UpdateGroupRolePermissionRequest>): GroupRolePermission | Promise<GroupRolePermission>;
    createGroupRole(request?: Nullable<CreateGroupRoleRequest>): GroupRole | Promise<GroupRole>;
    deleteGroupRole(request?: Nullable<DeleteGroupRoleRequest>): GroupRole | Promise<GroupRole>;
    updateGroupRole(request?: Nullable<UpdateGroupRoleRequest>): GroupRole | Promise<GroupRole>;
    createGroup(request?: Nullable<CreateGroupRequest>): Group | Promise<Group>;
    updateGroup(request?: Nullable<UpdateGroupRequest>): Group | Promise<Group>;
    createBoardColumn(request?: Nullable<CreateBoardColumnRequest>): BoardColumn | Promise<BoardColumn>;
    deleteBoardColumn(request?: Nullable<DeleteBoardColumnRequest>): BoardColumn | Promise<BoardColumn>;
    updateBoardColumn(request?: Nullable<UpdateBoardColumnRequest>): BoardColumn | Promise<BoardColumn>;
    createBoard(request?: Nullable<CreateBoardRequest>): Board | Promise<Board>;
    deleteBoard(request?: Nullable<DeleteBoardRequest>): Board | Promise<Board>;
    updateBoard(request?: Nullable<UpdateBoardRequest>): Board | Promise<Board>;
    createCard(request?: Nullable<CreateCardRequest>): Card | Promise<Card>;
    deleteCard(request?: Nullable<DeleteCardRequest>): Card | Promise<Card>;
    updateCard(request?: Nullable<UpdateCardRequest>): Card | Promise<Card>;
    storeNotificationTokenRequest(request?: Nullable<StoreNotificationTokenRequest>): NotificationToken | Promise<NotificationToken>;
    deleteNotificationToken(request?: Nullable<DeleteNotificationTokenRequest>): NotificationToken | Promise<NotificationToken>;
    createOrganisationMember(request?: Nullable<CreateOrganisationMemberRequest>): OrganisationMember | Promise<OrganisationMember>;
    updateOrganisationMember(request?: Nullable<UpdateOrganisationMemberRequest>): OrganisationMember | Promise<OrganisationMember>;
    deleteOrganisationMember(request?: Nullable<DeleteOrganisationMemberRequest>): OrganisationMember | Promise<OrganisationMember>;
    requestToJoinOrganisation(request?: Nullable<RequestToJoinOrganisationRequest>): RequestToJoinOrganisationResponse | Promise<RequestToJoinOrganisationResponse>;
    createOrganisationRolePermission(request?: Nullable<CreateOrganisationRolePermissionRequest>): OrganisationRolePermission | Promise<OrganisationRolePermission>;
    createOrganisationRolePermissions(request?: Nullable<CreateOrganisationRolePermissionsRequest>): BatchPayload | Promise<BatchPayload>;
    deleteOrganisationRolePermission(request?: Nullable<DeleteOrganisationRolePermissionRequest>): OrganisationRolePermission | Promise<OrganisationRolePermission>;
    deleteOrganisationRolePermissions(request?: Nullable<DeleteOrganisationRolePermissionsRequest>): BatchPayload | Promise<BatchPayload>;
    updateOrganisationRolePermission(request?: Nullable<UpdateOrganisationRolePermissionRequest>): OrganisationRolePermission | Promise<OrganisationRolePermission>;
    createOrganisationRole(request?: Nullable<CreateOrganisationRoleRequest>): OrganisationRole | Promise<OrganisationRole>;
    deleteOrganisationRole(request?: Nullable<DeleteOrganisationRoleRequest>): OrganisationRole | Promise<OrganisationRole>;
    updateOrganisationRole(request?: Nullable<UpdateOrganisationRoleRequest>): OrganisationRole | Promise<OrganisationRole>;
    createOrganisation(request?: Nullable<CreateOrganisationRequest>): Organisation | Promise<Organisation>;
    updateOrganisation(request?: Nullable<UpdateOrganisationRequest>): Organisation | Promise<Organisation>;
    createProjectMember(request?: Nullable<CreateProjectMemberRequest>): ProjectMember | Promise<ProjectMember>;
    updateProjectMember(request?: Nullable<UpdateProjectMemberRequest>): ProjectMember | Promise<ProjectMember>;
    deleteProjectMember(request?: Nullable<DeleteProjectMemberRequest>): ProjectMember | Promise<ProjectMember>;
    requestToJoinProject(request?: Nullable<RequestToJoinProjectRequest>): RequestToJoinProjectResponse | Promise<RequestToJoinProjectResponse>;
    createProjectRolePermission(request?: Nullable<CreateProjectRolePermissionRequest>): ProjectRolePermission | Promise<ProjectRolePermission>;
    createProjectRolePermissions(request?: Nullable<CreateProjectRolePermissionsRequest>): BatchPayload | Promise<BatchPayload>;
    deleteProjectRolePermission(request?: Nullable<DeleteProjectRolePermissionRequest>): ProjectRolePermission | Promise<ProjectRolePermission>;
    deleteProjectRolePermissions(request?: Nullable<DeleteProjectRolePermissionsRequest>): BatchPayload | Promise<BatchPayload>;
    updateProjectRolePermission(request?: Nullable<UpdateProjectRolePermissionRequest>): ProjectRolePermission | Promise<ProjectRolePermission>;
    createProjectRole(request?: Nullable<CreateProjectRoleRequest>): ProjectRole | Promise<ProjectRole>;
    deleteProjectRole(request?: Nullable<DeleteProjectRoleRequest>): ProjectRole | Promise<ProjectRole>;
    updateProjectRole(request?: Nullable<UpdateProjectRoleRequest>): ProjectRole | Promise<ProjectRole>;
    createProject(request?: Nullable<CreateProjectRequest>): Project | Promise<Project>;
    updateProject(request?: Nullable<UpdateProjectRequest>): Project | Promise<Project>;
    deleteProject(request?: Nullable<DeleteProjectRequest>): Project | Promise<Project>;
    copyProject(request?: Nullable<CopyProjectRequest>): Project | Promise<Project>;
    extractZip(request?: Nullable<ExtractZipRequest>): Nullable<StorageRef> | Promise<Nullable<StorageRef>>;
    createColumn(request: CreateColumnRequest): Column | Promise<Column>;
    createColumns(request: CreateColumnsRequest): BatchPayload | Promise<BatchPayload>;
    createColumnsByFileUpload(request: CreateColumnsFileUploadRequest): BatchPayload | Promise<BatchPayload>;
    deleteColumn(request: DeleteColumnRequest): Column | Promise<Column>;
    updateColumn(request: UpdateColumnRequest): Column | Promise<Column>;
    updateColumns(request: UpdateColumnsRequest): BatchPayload | Promise<BatchPayload>;
    copyColumn(request: CopyColumnRequest): Column | Promise<Column>;
    copyColumns(request: CopyColumnsRequest): Column[] | Promise<Column[]>;
    createProjectSchema(request: CreateProjectSchemaRequest): Nullable<Data> | Promise<Nullable<Data>>;
    insertData(request: InsertDataRequest): Nullable<Data> | Promise<Nullable<Data>>;
    insertDataFromIOT(request: InsertDataRequest): Nullable<Data> | Promise<Nullable<Data>>;
    insertDataFromForm(request: InsertDataFromFormRequest): Nullable<Data> | Promise<Nullable<Data>>;
    updateDataColumn(request: UpdateDataColumnValuesRequest): Nullable<Data> | Promise<Nullable<Data>>;
    cloudUpdateDataColumn(request: UpdateDataColumnValuesRequest): Nullable<Data> | Promise<Nullable<Data>>;
    deleteDataRows(request: DeleteDataRowsRequest): Nullable<Data> | Promise<Nullable<Data>>;
    clearDataRows(request: ClearDataRowsRequest): Nullable<Data> | Promise<Nullable<Data>>;
    createTable(request: CreateTableRequest): Table | Promise<Table>;
    deleteTable(request: DeleteTableRequest): Table | Promise<Table>;
    updateTable(request: UpdateTableRequest): Table | Promise<Table>;
    copyTable(request: CopyTableRequest): Table | Promise<Table>;
    copyTables(request: CopyTablesRequest): Table[] | Promise<Table[]>;
    createApiKey(request?: Nullable<CreateApiKeyRequest>): ApiKey | Promise<ApiKey>;
    updateApiKey(request?: Nullable<UpdateApiKeyRequest>): ApiKey | Promise<ApiKey>;
    deleteApiKey(request?: Nullable<DeleteApiKeyRequest>): ApiKey | Promise<ApiKey>;
    createUser(request?: Nullable<CreateUserRequest>): User | Promise<User>;
    updateUser(request?: Nullable<UpdateUserRequest>): User | Promise<User>;
    syncUser(): User | Promise<User>;
    createPage(request?: Nullable<CreatePageRequest>): Page | Promise<Page>;
    updatePage(request?: Nullable<UpdatePageRequest>): Page | Promise<Page>;
    deletePage(request?: Nullable<DeletePageRequest>): Page | Promise<Page>;
}

export interface AppPageComponent {
    id: string;
    appPageId: string;
    dashboardId?: Nullable<string>;
    formId?: Nullable<string>;
    pageId?: Nullable<string>;
    deleted: boolean;
    created: string;
    updated: string;
}

export interface AppPage {
    id: string;
    appId: string;
    deleted: boolean;
    order: number;
    created: string;
    updated: string;
}

export interface App {
    id: string;
    projectId: string;
    deleted: boolean;
    slug: string;
    name: string;
    contents?: Nullable<string>;
    created: string;
    updated: string;
    configuration: string;
    visibility: VisibilityType;
    projectLabel?: Nullable<ProjectLabel>;
}

export interface AuthUser {
    id: string;
    verified: boolean;
    email?: Nullable<string>;
    displayName?: Nullable<string>;
    photoURL?: Nullable<string>;
}

export interface HasGroupPermissionResponse {
    hasPermission: boolean;
}

export interface HasOrganisationPermissionResponse {
    hasPermission: boolean;
}

export interface HasProjectPermissionResponse {
    hasPermission: boolean;
}

export interface HasSitePermissionResponse {
    hasPermission: boolean;
}

export interface DashboardComponent {
    id: string;
    dashboardId: string;
    deleted: boolean;
    name: string;
    type: DashboardComponentType;
    configuration: string;
    created: string;
    updated: string;
    data?: Nullable<JSON>;
    visibility: VisibilityType;
}

export interface Dashboard {
    id: string;
    dashboardComponent?: Nullable<Nullable<DashboardComponent>[]>;
    projectId: string;
    deleted: boolean;
    slug: string;
    name: string;
    created: string;
    updated: string;
    allowSharing: boolean;
    visibility: VisibilityType;
    datasource?: Nullable<Nullable<Datasource>[]>;
    project?: Nullable<Project>;
    projectLabel?: Nullable<ProjectLabel>;
}

export interface Datasource {
    id: string;
    dashboardId: string;
    deleted: boolean;
    tested: boolean;
    name: string;
    description?: Nullable<string>;
    type: DatasourceType;
    configuration: JSON;
    created: string;
    updated: string;
    visibility: VisibilityType;
}

export interface Form {
    id: string;
    projectId: string;
    deleted: boolean;
    slug: string;
    name: string;
    emailList?: Nullable<string>;
    created: string;
    updated: string;
    configuration: string;
    layout: Layout;
    allowSharing: boolean;
    visibility: VisibilityType;
    sections?: Nullable<Nullable<Section>[]>;
    project?: Nullable<Project>;
    projectLabel?: Nullable<ProjectLabel>;
}

export interface Question {
    id: string;
    deleted: boolean;
    sectionId: string;
    name: string;
    description?: Nullable<string>;
    content?: Nullable<string>;
    type: QuestionType;
    configuration: string;
    required: boolean;
    created: string;
    updated: string;
}

export interface Section {
    id: string;
    questions?: Nullable<Nullable<Question>[]>;
    deleted: boolean;
    formId: string;
    name: string;
    order: number;
    created: string;
    updated: string;
}

export interface GroupMember {
    id: string;
    deleted: boolean;
    groupId: string;
    userId: string;
    created: string;
    updated: string;
    role: RoleType;
    groupRoleId?: Nullable<string>;
    groupRole?: Nullable<GroupRole>;
    group?: Nullable<Group>;
    user?: Nullable<User>;
}

export interface RequestToJoinGroupResponse {
    response: string;
}

export interface Group {
    id: string;
    groupMembers?: Nullable<GroupMember[]>;
    roles?: Nullable<Nullable<GroupRole>[]>;
    deleted: boolean;
    organisationId: string;
    organisation?: Nullable<Organisation>;
    organisationLabel?: Nullable<OrganisationLabel>;
    slug: string;
    name: string;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created: string;
    updated: string;
    visibility: VisibilityType;
    projects?: Nullable<Nullable<Project>[]>;
}

export interface User {
    id: string;
    groupMembers?: Nullable<GroupMember[]>;
    cards?: Nullable<Nullable<Card>[]>;
    notificationTokens?: Nullable<NotificationToken[]>;
    organisationMembers?: Nullable<Nullable<OrganisationMember>[]>;
    projectMembers?: Nullable<Nullable<ProjectMember>[]>;
    projects?: Nullable<Nullable<Project>[]>;
    apiKeys?: Nullable<ApiKey[]>;
    siteRole?: Nullable<SiteRole>;
    deleted: boolean;
    verified?: Nullable<boolean>;
    email: string;
    displayName: string;
    username: string;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created: string;
    updated: string;
    visibility: VisibilityType;
    siteRoleId?: Nullable<string>;
}

export interface GroupPermission {
    id: string;
    deleted: boolean;
    identifier: string;
    created: string;
    updated: string;
    groupRolePermissions?: Nullable<GroupRolePermission[]>;
}

export interface GroupRolePermission {
    id: string;
    groupPermissions?: Nullable<Nullable<GroupPermission>[]>;
    deleted: boolean;
    groupRoleId: string;
    groupPermissionId: string;
    created: string;
    updated: string;
    groupPermission?: Nullable<GroupPermission>;
}

export interface GroupRole {
    id: string;
    groupRolePermissions?: Nullable<GroupRolePermission[]>;
    deleted: boolean;
    default: boolean;
    name: string;
    groupId?: Nullable<string>;
    created: string;
    updated: string;
}

export interface GroupLabel {
    id: string;
    name: string;
    slug: string;
    description?: Nullable<string>;
    visibility: VisibilityType;
}

export interface Organisation {
    id: string;
    groups?: Nullable<Nullable<Group>[]>;
    organisationMembers?: Nullable<Nullable<OrganisationMember>[]>;
    roles?: Nullable<Nullable<OrganisationRole>[]>;
    deleted: boolean;
    slug: string;
    name: string;
    description?: Nullable<string>;
    photoURL?: Nullable<string>;
    photoRef?: Nullable<string>;
    created: string;
    updated: string;
    visibility: VisibilityType;
    projects?: Nullable<Nullable<Project>[]>;
}

export interface BoardColumn {
    id: string;
    boardId: string;
    deleted: boolean;
    name: string;
    order: number;
    created: string;
    updated: string;
    cards?: Nullable<Nullable<Card>[]>;
}

export interface Board {
    id: string;
    BoardColumn?: Nullable<Nullable<BoardColumn>[]>;
    projectId: string;
    deleted: boolean;
    slug: string;
    name: string;
    created: string;
    updated: string;
    visibility: VisibilityType;
}

export interface Card {
    id: string;
    boardColumnId: string;
    userId?: Nullable<string>;
    deleted: boolean;
    closed: boolean;
    slug: string;
    name: string;
    description?: Nullable<string>;
    comments?: Nullable<string>;
    numberOfComments?: Nullable<number>;
    order: number;
    configuration: string;
    assigned?: Nullable<string>;
    dueDate?: Nullable<string>;
    created: string;
    updated: string;
    user?: Nullable<User>;
}

export interface NotificationToken {
    token: string;
    deleted: boolean;
    created: string;
    userId: string;
    user?: Nullable<User>;
    notificationType: PlatformType;
}

export interface OrganisationMember {
    id: string;
    deleted: boolean;
    organisationId: string;
    userId: string;
    created: string;
    updated: string;
    role: RoleType;
    organisationRoleId?: Nullable<string>;
    organisationRole?: Nullable<OrganisationRole>;
    organisation?: Nullable<Organisation>;
    user?: Nullable<User>;
}

export interface RequestToJoinOrganisationResponse {
    response: string;
}

export interface OrganisationPermission {
    id: string;
    deleted: boolean;
    identifier: string;
    created: string;
    updated: string;
    organisationRolePermissions?: Nullable<OrganisationRolePermission[]>;
}

export interface OrganisationRolePermission {
    id: string;
    organisationPermissions?: Nullable<Nullable<OrganisationPermission>[]>;
    deleted: boolean;
    organisationRoleId: string;
    organisationPermissionId: string;
    created: string;
    updated: string;
    organisationPermission?: Nullable<OrganisationPermission>;
}

export interface OrganisationRole {
    id: string;
    organisationRolePermissions?: Nullable<OrganisationRolePermission[]>;
    deleted: boolean;
    default: boolean;
    name: string;
    organisationId?: Nullable<string>;
    created: string;
    updated: string;
}

export interface OrganisationLabel {
    id: string;
    name: string;
    slug: string;
    description?: Nullable<string>;
}

export interface ProjectMember {
    id: string;
    deleted: boolean;
    favourite: boolean;
    projectId: string;
    userId: string;
    created: string;
    updated: string;
    role: RoleType;
    projectRoleId?: Nullable<string>;
    projectRole?: Nullable<ProjectRole>;
    project?: Nullable<Project>;
    user?: Nullable<User>;
}

export interface RequestToJoinProjectResponse {
    response: string;
}

export interface ProjectPermission {
    id: string;
    deleted: boolean;
    identifier: string;
    created: string;
    updated: string;
    projectRolePermissions?: Nullable<ProjectRolePermission[]>;
}

export interface ProjectRolePermission {
    id: string;
    projectPermissions?: Nullable<Nullable<ProjectPermission>[]>;
    deleted: boolean;
    projectRoleId: string;
    projectPermissionId: string;
    created: string;
    updated: string;
    projectPermission?: Nullable<ProjectPermission>;
}

export interface ProjectRole {
    id: string;
    projectRolePermissions?: Nullable<ProjectRolePermission[]>;
    deleted: boolean;
    default: boolean;
    name: string;
    projectId?: Nullable<string>;
    created: string;
    updated: string;
}

export interface ProjectLabel {
    id: string;
    name: string;
    slug: string;
    visibility: VisibilityType;
    organisationLabel?: Nullable<OrganisationLabel>;
    groupLabel?: Nullable<GroupLabel>;
}

export interface Table {
    id: string;
    projectLabel?: Nullable<ProjectLabel>;
    columns?: Nullable<Column[]>;
    projectId: string;
    project?: Nullable<Project>;
    deleted: boolean;
    name: string;
    description?: Nullable<string>;
    created: string;
    updated: string;
    visibility: VisibilityType;
}

export interface BatchPayload {
    count: number;
}

export interface SiteSearch {
    id: string;
    name: string;
    slug: string;
    description?: Nullable<string>;
    photoUrl?: Nullable<string>;
    visibility: string;
    type: string;
}

export interface SiteSearchResponse {
    siteSearch: SiteSearch[];
    error?: Nullable<string>;
}

export interface StorageRef {
    fullPath?: Nullable<string>;
}

export interface Column {
    id: string;
    tableId: string;
    table?: Nullable<Table>;
    deleted: boolean;
    primaryKey: boolean;
    name: string;
    description?: Nullable<string>;
    dataType: DataType;
    required: boolean;
    default?: Nullable<string>;
    order: number;
    dataTypeConfiguration?: Nullable<string>;
    unique: boolean;
    foreignKey?: Nullable<boolean>;
    autoIncrement?: Nullable<boolean>;
    isArray?: Nullable<boolean>;
    created: string;
    updated: string;
}

export interface Data {
    projectId?: Nullable<string>;
    tableName?: Nullable<string>;
    rowsAffected?: Nullable<number>;
    columnId?: Nullable<string>;
    rowId?: Nullable<string>;
}

export interface DataRow {
    columns: DataColumn[];
}

export interface DataRowsResponse {
    cursor?: Nullable<string>;
    rows: DataRow[];
}

export interface DataColumn {
    columnId: string;
    value?: Nullable<string>;
}

export interface ColumnData {
    key: string;
    value?: Nullable<string>;
}

export interface RowData {
    columns?: Nullable<Nullable<ColumnData>[]>;
}

export interface QueryTableResponse {
    rows?: Nullable<Nullable<RowData>[]>;
}

export interface ApiKey {
    id: string;
    deleted: boolean;
    userId: string;
    user?: Nullable<User>;
    name: string;
    description?: Nullable<string>;
    key: string;
    created: string;
    updated: string;
}

export interface SitePermission {
    id: string;
    deleted: boolean;
    identifier: string;
    created: string;
    updated: string;
    siteRolePermission?: Nullable<SiteRolePermission[]>;
}

export interface SiteRolePermission {
    id: string;
    sitePermission?: Nullable<SitePermission>;
    deleted: boolean;
    siteRoleId: string;
    sitePermissionId: string;
    created: string;
    updated: string;
}

export interface SiteRole {
    id: string;
    siteRolePermission?: Nullable<SiteRolePermission[]>;
    deleted: boolean;
    default: boolean;
    name: string;
    created: string;
    updated: string;
}

export interface ValidUserResponse {
    email: boolean;
    username: boolean;
}

export interface Page {
    id: string;
    deleted: boolean;
    published: boolean;
    pinned: boolean;
    projectId: string;
    project?: Nullable<Project>;
    name: string;
    slug: string;
    description?: Nullable<string>;
    content?: Nullable<string>;
    created: string;
    updated: string;
    allowSharing: boolean;
    visibility: VisibilityType;
}

export type JSON = any;
type Nullable<T> = T | null;
