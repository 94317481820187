import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RedirectGuard  {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {

    const newTab = window.open(route.data['externalUrl']);
    if (newTab) {
      newTab.focus();
      return false; 
    } else {
      window.location.href = route.data['externalUrl'];
      return true;
    }

  }
}
