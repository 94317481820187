import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { OfflineInfoHeaderPopoverComponent } from './offline-info-header-popover.component';

@NgModule({
  declarations: [OfflineInfoHeaderPopoverComponent],
  imports: [CommonModule, IonicModule, RouterModule],
  exports: [OfflineInfoHeaderPopoverComponent],
})
export class OfflineInfoHeaderPopoverModule {}
