import { ProjectMember, ProjectRole } from '@ih/app/shared/apis/interfaces';

export class SetProjectMembers {
  public static readonly type =
    '[Project/ProjectMember/List] SetProjectMembers';
  constructor(
    public readonly projectMembers: ProjectMember[],
    public readonly projectRoles: ProjectRole[]
  ) {}
}
