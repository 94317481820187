import { Injectable } from '@angular/core';
import {
  ToastService,
} from '@ih/app/client/shared/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { Submit } from '../actions';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface ChatFormModel {
  question: string;
}

export interface ChatFormStateModel {
  model: ChatFormModel;
  dirty: boolean;
  status: string;
}

export interface Message {
  text: string;
  type: string;
}

export interface ChatStateModel {
  form: ChatFormStateModel;
  busy: boolean;
  messages: Message[];
  errors: Error[];
}

const defaults: ChatStateModel = {
  form: {
    model: {
      question: '',
    },
    dirty: false,
    status: '',
  },
  busy: false,
  messages: [],
  errors: [],
};

@State<ChatStateModel>({
  name: 'chat',
  defaults,
})
@Injectable()
export class ChatState {
  @Selector()
  public static messages(state: ChatStateModel): Message[] {
    return state.messages;
  }

  @Selector()
  public static busy(state: ChatStateModel): boolean {
    return state.busy;
  }

  constructor(
    private readonly toastService: ToastService,
    private readonly http: HttpClient
  ) {}

  @Action(Submit)
  public submit(
    ctx: StateContext<ChatStateModel>
  ) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    const question = ctx.getState().form.model.question;

    ctx.setState(
      produce((draft) => {
        draft.form.model.question = '';
        draft.busy = true;
        draft.messages.push({ 'text': question, 'type': 'user' });
      })
    );

    const body = { question };

    this.http
      .post(
        'https://europe-west1-information-hub-io.cloudfunctions.net/generativeAiChat',
        body,
        httpOptions
      )
      .subscribe({
        next: (data: any) => {
          ctx.setState(
            produce((draft) => {
              draft.busy = false;
              draft.messages.push({ 'text': data.response, 'type': 'bot' });
            })
          );

        },
        error: (error) => {
          console.error(error);
          this.toastService.showError('Something went wrong. Please try again later.');
          ctx.setState(
            produce((draft) => {
              draft.busy = false;
            })
          );
        },
      });
  }
}
