import { ApolloQueryResult } from '@apollo/client/core';
import { DashboardComponent } from '@ih/app/shared/apis/interfaces';

export class FindDashboardChartsSuccess {
  public static readonly type =
    '[[Project/Dashboard/List] FindDashboardChartsSuccess';
  constructor(
    public readonly charts: DashboardComponent[],
    public readonly id: string
  ) {}
}
