import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ToastService, UsersService } from '@ih/app/client/shared/services';
import { MeState } from '@ih/app/client/shared/states';
import { NgxsModule } from '@ngxs/store';
import { RegisterState } from './states';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([RegisterState, MeState])],
  providers: [ToastService, UsersService],
})
export class AppClientRegisterDataAccessModule {}
