import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ProjectState } from '@ih/app/client/project/data-access';
import {
  AlertService,
  FormsService,
  ToastService,
} from '@ih/app/client/shared/services';
import { Form, Project } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';

import {
  ConfirmationCancelAction,
  DeleteFormAction,
  FindAllForms,
  RemoveFormAction,
  ResetProjectFormsList,
  SetErrorAction,
  SetForms,
  ShowDeleteConfirmationAction,
  TrashFormAction,
} from '../actions';
import {
  DeleteFormErrorEffect,
  DeleteFormSuccessEffect,
  FindAllFormsError,
  FindAllFormsSuccess,
  TrashFormErrorEffect,
  TrashFormSuccessEffect,
} from '../effects';

export interface ListStateModel {
  forms: Form[];
  downloadedForms: any[];
  error: Error | null;
}

@State<ListStateModel>({
  name: 'projectFormsList',
  defaults: {
    forms: [],
    downloadedForms: [],
    error: null,
  },
})
@Injectable()
export class ListState {
  constructor(
    private readonly toastService: ToastService,
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly alertService: AlertService,
  ) {}

  @Selector()
  public static getState(state: ListStateModel) {
    return state;
  }

  @Selector()
  public static forms(state: ListStateModel): Form[] {
    return state.forms;
  }

  @Action(FindAllForms)
  public findAllForms(ctx: StateContext<ListStateModel>) {
    const project = this.store.selectSnapshot<Project | null>(
      ProjectState.project,
    );
    const projectId = project?.id || '';

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllForms($projectId: String!) {
        findAllForms(
          request: { where: { projectId: $projectId, deleted: false } }
        ) {
          id
          name
          slug
        }
      }
    `;

    return this.apollo
      .watchQuery<{ findAllForms: Form[] }>({
        query,
        variables: {
          projectId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        tap((result: ApolloQueryResult<{ findAllForms: Form[] }>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllFormsSuccess(result));
        }),
        catchError((error) => ctx.dispatch(new FindAllFormsError(error))),
      );
  }

  @Action(FindAllFormsSuccess)
  public findAllFormsSuccess(
    ctx: StateContext<ListStateModel>,
    { result }: FindAllFormsSuccess,
  ) {
    const forms = (result?.data?.findAllForms || []) as Form[];
    ctx.dispatch(new SetForms(forms));
  }

  @Action(FindAllFormsError)
  public findAllFormsError(
    ctx: StateContext<ListStateModel>,
    { error }: FindAllFormsError,
  ) {
    this.toastService.showError('Failed to fetch forms');
    console.error('Failed to fetch forms', error.message);
  }

  @Action(SetForms)
  public setForms(ctx: StateContext<ListStateModel>, { forms }: SetForms) {
    ctx.setState(
      produce((draft) => {
        draft.forms = forms;
      }),
    );
  }

  @Action(ResetProjectFormsList)
  public resetProjectFormsList(ctx: StateContext<ListStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.forms = [];
      }),
    );
  }

  @Action(SetErrorAction)
  public setErrorAction(
    ctx: StateContext<ListStateModel>,
    { error }: SetErrorAction,
  ) {
    return ctx.setState(
      produce((draft) => {
        draft.error = error;
      }),
    );
  }

  @Action(ShowDeleteConfirmationAction)
  public showDeleteConfirmationAction(
    ctx: StateContext<ListStateModel>,
    { form }: ShowDeleteConfirmationAction,
  ) {
    this.alertService.showAlert(
      `You are about to delete this form. Are you sure you want to continue?`,
      'Confirm?',
      undefined,
      [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ctx.dispatch(new ConfirmationCancelAction());
          },
        },
        {
          text: 'Trash',
          cssClass: 'primary',
          handler: () => {
            ctx.dispatch(new TrashFormAction(form.id));
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            ctx.dispatch(new DeleteFormAction(form.id));
          },
        },
      ],
    );
  }

  @Action(ConfirmationCancelAction)
  public confirmationCancelAction() {
    this.alertService.hide();
  }

  @Action(TrashFormAction)
  public trashFormAction(
    ctx: StateContext<ListStateModel>,
    { id }: TrashFormAction,
  ) {
    type ResultType = { deleteForm: Form | null };
    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeleteForm($id: String!) {
            deleteForm(request: { id: $id, permanent: false }) {
              id
              name
            } 
          }
        `,
        variables: {
          id,
        },
      })
      .pipe(
        tap((result: FetchResult<
          ResultType,
          Record<string, any>,
          Record<string, any>      
        >) =>{ 
          return ctx.dispatch(new TrashFormSuccessEffect(result.data?.deleteForm))
        }
        ),
        catchError((error) =>
          ctx.dispatch(new TrashFormErrorEffect(error as Error)),
        ),
      );
  }

  /**
   * Removes a form from the list of forms and shows a success toast
   * @param ctx StateContext<ListStateModel>
   * @param form TrashFormSuccessEffect
   * @returns observable
   * @updated 03/07/2024 - 09:39:40
   */
  @Action(TrashFormSuccessEffect)
  public trashFormSuccessEffect(
    ctx: StateContext<ListStateModel>,
    { form }: TrashFormSuccessEffect,
  ) {
    this.toastService.showInfo('Form trashed successfully','success');
    if (form) {
      return ctx.dispatch(new RemoveFormAction(form.id));
    }
    ctx.dispatch(new FindAllForms());
    return null;
  }

  @Action(RemoveFormAction)
  public removeFormAction(
    ctx: StateContext<ListStateModel>,
    { id }: RemoveFormAction,
  ) {
    //don't need to reset the forms twice
    ctx.dispatch(new FindAllForms());
    return ctx.setState(
      produce((draft) => {
        draft.forms = draft.forms.filter((form) => form.id !== id);
      }),
    );
  }

  @Action(TrashFormErrorEffect)
  public trashFormErrorEffect(
    ctx: StateContext<ListStateModel>,
    { error }: TrashFormErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(DeleteFormAction)
  public deleteFormAction(
    ctx: StateContext<ListStateModel>,
    { id }: DeleteFormAction,
  ) {
    type ResultType = { deleteForm: Form | null };
    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeleteForm($id: String!) {
            deleteForm(request: { id: $id, permanent: true }) {
              id
              name
            }
          }
        `,
        variables: {
          id,
        },
      })
      .pipe(
        tap((result: FetchResult<
              ResultType,
              Record<string, any>,
              Record<string, any>      
        >) => {
          return ctx.dispatch(new DeleteFormSuccessEffect(result.data?.deleteForm));
        }
      ),
        catchError((error) =>
          ctx.dispatch(new DeleteFormErrorEffect(error as Error)),
        ),
      );
  }

  /**
   * Removes a form from the list of forms and shows a success toast
   * @param ctx StateContext<ListStateModel>
   * @param form DeleteFormSuccessEffect
   * @returns observable
   * @updated 03/07/2024 - 09:37:09
   */
  @Action(DeleteFormSuccessEffect)
  public deleteFormSuccessEffect(
    ctx: StateContext<ListStateModel>,
    { form }: DeleteFormSuccessEffect,
  ) {
    this.toastService.showInfo('Form deleted successfully','success');
    if (form) {
      return ctx.dispatch(new RemoveFormAction(form.id));
    }
    ctx.dispatch(new FindAllForms());
    return null;
  }

  @Action(DeleteFormErrorEffect)
  public deleteFormErrorEffect(
    ctx: StateContext<ListStateModel>,
    { error }: DeleteFormErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }
}
