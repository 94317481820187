export const environment = {
  production: false,
  pwa: true,
  firebase: {
    apiKey: "AIzaSyDDise8laU8GwAU2PnFa_BASyOdkDO_OoE",
    authDomain: "dev-information-hub-io.firebaseapp.com",
    projectId: "dev-information-hub-io",
    storageBucket: "dev-information-hub-io.appspot.com",
    messagingSenderId: "502153936561",
    appId: "1:502153936561:web:667ed4f936340c31fbac90",
    measurementId: "G-HR49J26TDV",
    vapidKey: 'BAtkr3Gnpyi9RW5fQrjkzAVUiGoOCmEgQxeiIaYBYaXfEAGVpOefM_KTiAfxdIvW7yGyfZ9R1nslE1kF4KZeedo',
  },
  api: {
    url: 'https://dev.api.informationhub.io/graphql',
  },
};
