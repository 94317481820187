import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchPolicy } from '@apollo/client/core';
import {
  CreatePageRequest,
  DeletePageRequest,
  FindAllPagesRequest,
  FindUniquePageRequest,
  Page,
  UpdatePageRequest,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { map, Observable, take } from 'rxjs';

@Injectable()
export class WikisService {
  constructor(private readonly apollo: Apollo, private readonly store: Store) {}

  findUniquePage(request: FindUniquePageRequest): Observable<Page | null> {
    type ResultType = { findUniquePage: Page | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindUniquePage($request: FindUniquePageRequest!) {
        findUniquePage(request: $request) {
          id
          deleted
          pinned
          published
          project {
            id
          }
          name
          slug
          description
          content
          created
          updated
          allowSharing
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <FetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<ResultType>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          return result.data.findUniquePage;
        })
      );
  }

  findAllPages(request: FindAllPagesRequest): Observable<Page[]> {
    type ResultType = { findAllPages: Page[] };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllPages($request: FindAllPagesRequest!) {
        findAllPages(request: $request) {
          id
          deleted
          pinned
          published
          name
          slug
          description
          content
          created
          updated
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <FetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map((result: ApolloQueryResult<ResultType>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          return result.data.findAllPages;
        })
      );
  }

  createPage(request: CreatePageRequest): Observable<Page | null> {
    type ResultType = { createPage: Page | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation CreatePage($request: CreatePageRequest!) {
            createPage(request: $request) {
              id
              deleted
              pinned
              published
              name
              slug
              description
              content
              created
              updated
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map(
          (result: any) =>
            result.data?.createPage || null
        )
      );
  }

  deletePage(request: DeletePageRequest): Observable<Page | null> {
    type ResultType = { deletePage: Page | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeletePage($request: DeletePageRequest!) {
            deletePage(request: $request) {
              id
              deleted
              pinned
              published
              name
              slug
              description
              content
              deleted
              updated
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map(
          (result: any) =>
            result.data?.deletePage || null
        )
      );
  }

  updatePage(request: UpdatePageRequest): Observable<Page | null> {
    type ResultType = { updatePage: Page | null };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation UpdatePage($request: UpdatePageRequest!) {
            updatePage(request: $request) {
              id
              deleted
              pinned
              published
              name
              slug
              description
              content
              deleted
              updated
            }
          }
        `,
        variables: {
          request,
        },
      })
      .pipe(
        map(
          (result: any) =>
            result.data?.updatePage || null
        )
      );
  }
}
