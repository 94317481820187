import { Injectable } from '@angular/core';
import { db } from '@ih/app/client/shared/db';

@Injectable({ providedIn: 'root' })
export class DownloadSharedFormService {
  async add(payload: any) {
    await db.downloadedSharedForms.put(payload, payload.id);
  }

  async remove(id: string | undefined) {
    if (!id) return;
    await db.downloadedSharedForms.delete(id);
  }

  async get(id: string | undefined) {
    if (!id) return;
    return await db.downloadedSharedForms.get(id);
  }

  async getAll() {
    return await db.downloadedSharedForms.toArray();
  }
}
