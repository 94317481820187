import { Injectable } from '@angular/core';
import { ProjectState } from '@ih/app/client/project/data-access';
import { AlertService, WikisService } from '@ih/app/client/shared/services';
import { Page, Project } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { produce } from 'immer';
import { catchError, tap } from 'rxjs';
import {
  ConfirmationCancelAction,
  FindAllPagesAction,
  RemovePageAction,
  ResetProjectWikisList,
  SetErrorAction,
  SetPagesAction,
  ShowDeleteConfirmationAction,
  TrashPageAction,
} from '../actions';
import { DeletePageAction } from '../actions/delete-page';
import {
  DeletePageErrorEffect,
  DeletePageSuccessEffect,
  FindAllPagesErrorEffect,
  FindAllPagesSuccessEffect,
  TrashPageErrorEffect,
  TrashPageSuccessEffect,
} from '../effects';

export interface WikiListStateModel {
  pages: Page[];
  error: Error | null;
}

@State<WikiListStateModel>({
  name: 'projectWikiList',
  defaults: {
    pages: [],
    error: null,
  },
})
@Injectable()
export class WikiListState {
  constructor(
    private readonly store: Store,
    private readonly wikisService: WikisService,
    private readonly alertService: AlertService,
  ) {}

  @Selector()
  public static pages(state: WikiListStateModel): Page[] {
    return state.pages;
  }

  @Action(FindAllPagesAction)
  public findAllPagesAction(ctx: StateContext<WikiListStateModel>) {
    const project = this.store.selectSnapshot<Project | null>(
      ProjectState.project,
    );
    const projectId = project?.id;

    if (projectId) {
      return this.wikisService
        .findAllPages({
          where: {
            projectId,
            deleted: false,
          },
        })
        .pipe(
          tap((pages: Page[]) =>
            ctx.dispatch(new FindAllPagesSuccessEffect(pages)),
          ),
          catchError((error) =>
            ctx.dispatch(new FindAllPagesErrorEffect(error)),
          ),
        );
    }

    const error = new Error('Project not set');
    return ctx.dispatch(new FindAllPagesErrorEffect(error));
  }

  @Action(FindAllPagesSuccessEffect)
  public findAllPagesSuccessEffect(
    ctx: StateContext<WikiListStateModel>,
    { pages }: FindAllPagesSuccessEffect,
  ) {
    return ctx.dispatch(new SetPagesAction(pages));
  }

  @Action(FindAllPagesErrorEffect)
  public findAllPagesErrorEffect(
    ctx: StateContext<WikiListStateModel>,
    { error }: FindAllPagesErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(SetPagesAction)
  public setPagesAction(
    ctx: StateContext<WikiListStateModel>,
    { pages }: SetPagesAction,
  ) {
    return ctx.setState(
      produce((draft) => {
        draft.pages = pages;
      }),
    );
  }

  @Action(SetErrorAction)
  public setErrorAction(
    ctx: StateContext<WikiListStateModel>,
    { error }: SetErrorAction,
  ) {
    return ctx.setState(
      produce((draft) => {
        draft.error = error;
      }),
    );
  }

  @Action(ShowDeleteConfirmationAction)
  public showDeleteConfirmationAction(
    ctx: StateContext<WikiListStateModel>,
    { page }: ShowDeleteConfirmationAction,
  ) {
    this.alertService.showAlert(
      `You are about to delete this page. Are you sure you want to continue?`,
      'Confirm?',
      undefined,
      [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            ctx.dispatch(new ConfirmationCancelAction());
          },
        },
        {
          text: 'Trash',
          cssClass: 'primary',
          handler: () => {
            ctx.dispatch(new TrashPageAction(page.id));
          },
        },
        {
          text: 'Delete',
          cssClass: 'danger',
          handler: () => {
            ctx.dispatch(new DeletePageAction(page.id));
          },
        },
      ],
    );
  }

  @Action(ConfirmationCancelAction)
  public confirmationCancelAction() {
    this.alertService.hide();
  }

  @Action(TrashPageAction)
  public trashPageAction(
    ctx: StateContext<WikiListStateModel>,
    { id }: TrashPageAction,
  ) {
    return this.wikisService
      .deletePage({
        id,
        permanent: false,
      })
      .pipe(
        tap((page: Page | null) =>
          ctx.dispatch([
            new TrashPageSuccessEffect(page),
            new FindAllPagesAction(),
          ]),
        ),
        catchError((error) => ctx.dispatch(new TrashPageErrorEffect(error))),
      );
  }

  @Action(TrashPageSuccessEffect)
  public trashPageSuccessEffect(
    ctx: StateContext<WikiListStateModel>,
    { page }: TrashPageSuccessEffect,
  ) {
    if (page) {
      return ctx.dispatch(new RemovePageAction(page.id));
    }

    return null;
  }

  @Action(RemovePageAction)
  public removePageAction(
    ctx: StateContext<WikiListStateModel>,
    { id }: RemovePageAction,
  ) {
    return ctx.setState(
      produce((draft) => {
        draft.pages = draft.pages.filter((page) => page.id !== id);
      }),
    );
  }

  @Action(TrashPageErrorEffect)
  public trashPageErrorEffect(
    ctx: StateContext<WikiListStateModel>,
    { error }: TrashPageErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(DeletePageAction)
  public deletePageAction(
    ctx: StateContext<WikiListStateModel>,
    { id }: DeletePageAction,
  ) {
    return this.wikisService
      .deletePage({
        id,
        permanent: true,
      })
      .pipe(
        tap((page: Page | null) =>
          ctx.dispatch([
            new DeletePageSuccessEffect(page),
            new FindAllPagesAction(),
          ]),
        ),
        catchError((error) => ctx.dispatch(new DeletePageErrorEffect(error))),
      );
  }

  @Action(DeletePageSuccessEffect)
  public deletePageSuccessEffect(
    ctx: StateContext<WikiListStateModel>,
    { page }: DeletePageSuccessEffect,
  ) {
    if (page) {
      return ctx.dispatch(new RemovePageAction(page.id));
    }

    return null;
  }

  @Action(DeletePageErrorEffect)
  public deletePageErrorEffect(
    ctx: StateContext<WikiListStateModel>,
    { error }: TrashPageErrorEffect,
  ) {
    return ctx.dispatch(new SetErrorAction(error));
  }

  @Action(ResetProjectWikisList)
  public resetProjectWikisList(ctx: StateContext<WikiListStateModel>) {
    return ctx.setState(
      produce((draft) => {
        draft.pages = [];
        draft.error = null;
      }),
    );
  }
}
