export * from './actions';
export * from './effects';
export * from './states';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WikisService } from '@ih/app/client/shared/services';
import { NgxsModule } from '@ngxs/store';
import { WikiListState } from './states';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([WikiListState])],
  providers: [WikisService],
})
export class AppClientProjectWikiListDataAccessModule {}
