export interface MenuContext {
  img: string;
  type: string;
  title: string;
  username: string;
  organisationSubTitle: string;
  organisationSubLink: string[];
  groupSubTitle: string;
  groupSubLink: string[];
  userSubTitle: string;
  userSubLink: string[];
  settingLink: string[];
}
