import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { AlertService, ProjectsService, ToastService } from '@ih/app/client/shared/services';
import { ProjectMember, ProjectRole } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';

import {
  FindAllDefaultRoles,
  FindAllProjectMembers,
  FindAllProjectRoles,
  LeaveProject,
  ResetProjectUsersList,
  SetProjectMembers,
} from '../actions';
import {
  FindAllDefaultRolesError,
  FindAllDefaultRolesSuccess,
  FindAllProjectMembersError,
  FindAllProjectMembersSuccess,
  FindAllProjectRolesError,
  FindAllProjectRolesSuccess,
} from '../effects';
import { Router } from '@angular/router';
import { Project } from '@ih/app/database';

export interface ProjectUsersListStateModel {
  projectMembers: ProjectMember[];
  projectRoles: ProjectRole[];
}

@State<ProjectUsersListStateModel>({
  name: 'projectMembersList',
  defaults: {
    projectMembers: [],
    projectRoles: [],
  },
})
@Injectable()
export class ProjectUsersListState {
  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
    private readonly router: Router,
    private readonly alertService: AlertService,
  ) {}

  @Selector()
  public static getState(state: ProjectUsersListStateModel) {
    return state;
  }

  @Selector()
  public static projectMembers(
    state: ProjectUsersListStateModel,
  ): ProjectMember[] {
    return state.projectMembers;
  }

  @Action(FindAllProjectMembers)
  public findAllProjectMembers(ctx: StateContext<ProjectUsersListStateModel>) {
    const projectId = this.store.selectSnapshot<string>(
      (state) => state.project.project.id,
    );

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllProjectMembers($projectId: String!) {
        findAllProjectMembers(request: { where: { projectId: $projectId } }) {
          id
          role
          user {
            id
            username
            displayName
            email
            photoURL
          }
          projectRoleId
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllProjectMembers: ProjectMember[];
    }>({
      query,
      variables: {
        projectId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllProjectMembers: ProjectMember[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              variables: {
                projectId,
              },
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllProjectMembersSuccess(result));
        },
      ),
      catchError((error) =>
        ctx.dispatch(new FindAllProjectMembersError(error)),
      ),
    );
  }

  @Action(FindAllProjectMembersSuccess)
  public findAllProjectMembersSuccess(
    ctx: StateContext<ProjectUsersListStateModel>,
    { result }: FindAllProjectMembersSuccess,
  ) {
    const projectMembers: ProjectMember[] = (
      result?.data?.findAllProjectMembers || []
    )
      .filter(function (projectMember) {
        if (projectMember) {
          return true;
        }
        return result;
      }, [])
      .sort((a, b) => {
        if (a == null || b == null) return 0;
        if (a.user == null || b.user == null) return 0;
        return a.user.displayName.localeCompare(b.user.displayName);
      });

    ctx.dispatch(new FindAllDefaultRoles(projectMembers));
  }

  @Action(FindAllProjectMembersError)
  public findAllProjectMembersError(
    ctx: StateContext<ProjectUsersListStateModel>,
    { error }: FindAllProjectMembersError,
  ) {
    this.toastService.showError('Failed to fetch users');
    console.error('Failed to fetch users: ', error.message);
  }

  @Action(FindAllDefaultRoles)
  public findAllDefaultRoles(
    ctx: StateContext<ProjectUsersListStateModel>,
    { projectMembers }: SetProjectMembers,
  ) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    //  needs to be done better, limiting is not to say the first 3 will be the defaults
    // +1 TODO
    const query = gql`
      query {
        getBaseProjectRoles {
          id
          name
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      getBaseProjectRoles: ProjectRole[];
    }>({
      query,
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            getBaseProjectRoles: ProjectRole[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllDefaultRolesSuccess(result, projectMembers));
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllDefaultRolesError(error))),
    );
  }

  @Action(FindAllDefaultRolesSuccess)
  public findAllDefaultRolesSuccess(
    ctx: StateContext<ProjectUsersListStateModel>,
    { result, members }: FindAllDefaultRolesSuccess,
  ) {
    const defaultRoles: ProjectRole[] = result?.data?.getBaseProjectRoles || [];

    ctx.dispatch(new FindAllProjectRoles(members, defaultRoles));
  }

  @Action(FindAllDefaultRolesError)
  public findAllDefaultRolesError(
    ctx: StateContext<ProjectUsersListStateModel>,
    { error }: FindAllDefaultRolesError,
  ) {
    this.toastService.showError('Failed to fetch roles');
    console.error('Failed to fetch roles', error.message);
  }

  @Action(FindAllProjectRoles)
  public findAllProjectRoles(
    ctx: StateContext<ProjectUsersListStateModel>,
    { projectMembers, defaultRoles }: FindAllProjectRoles,
  ) {
    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    const projectId = this.store.selectSnapshot<string>(
      (state) => state.project.project.id,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllProjectRoles($projectId: String!) {
        findAllProjectRoles(
          request: { where: { projectId: $projectId, deleted: false } }
        ) {
          id
          name
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{
      findAllProjectRoles: ProjectRole[];
    }>({
      query,
      variables: {
        projectId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap(
        (
          result: ApolloQueryResult<{
            findAllProjectRoles: ProjectRole[];
          }>,
        ) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(
            new FindAllProjectRolesSuccess(
              result,
              projectMembers,
              defaultRoles,
            ),
          );
        },
      ),
      catchError((error) => ctx.dispatch(new FindAllProjectRolesError(error))),
    );
  }

  @Action(FindAllProjectRolesSuccess)
  public findAllProjectRolesSuccess(
    ctx: StateContext<ProjectUsersListStateModel>,
    { result, members, defaultRoles }: FindAllProjectRolesSuccess,
  ) {
    const projectRoles: ProjectRole[] = defaultRoles.concat(
      result?.data?.findAllProjectRoles,
    );
    ctx.dispatch(new SetProjectMembers(members, projectRoles));
  }

  @Action(FindAllProjectRolesError)
  public findAllProjectRolesError(
    ctx: StateContext<ProjectUsersListStateModel>,
    { error }: FindAllProjectRolesError,
  ) {
    this.toastService.showError('Failed to fetch roles');
    console.error('Failed to fetch roles', error.message);
  }

  @Action(SetProjectMembers)
  public setProjectMembers(
    ctx: StateContext<ProjectUsersListStateModel>,
    { projectMembers, projectRoles }: SetProjectMembers,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.projectMembers = projectMembers.map((member) => {
          return {
            ...member,
            projectRole: projectRoles.filter(
              (projectRole) => projectRole.id === member.projectRoleId,
            )[0],
          };
        });

        draft.projectRoles = projectRoles;
      }),
    );
  }

  @Action(ResetProjectUsersList)
  public resetProjectUserList(ctx: StateContext<ProjectUsersListStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.projectMembers = [];
        draft.projectRoles = [];
      }),
    );
  }

  @Action(LeaveProject)
  public leaveProject(ctx: StateContext<ProjectUsersListStateModel>, { userId }: LeaveProject) {
    const project = this.store.selectSnapshot<Project>(
      (state) => state.project.project,
    );

    const projectName = project.name;

    this.alertService.showAlert(
      `You are about to leave "${projectName}". Are you sure you want to continue?`,
      'Confirm?',
      undefined,
      [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            return;
          },
        },
        {
          text: 'Leave',
          cssClass: 'danger',
          handler: async () => {
            const state = ctx.getState();
            const projectMember = state.projectMembers.find(
              (member) => member.user?.id === userId
            );
        
            const id = projectMember?.id;
        
            const postsQuery = this.apollo.mutate<{
              deleteProjectMember: ProjectMember | null;
            }>({
              mutation: gql`
                mutation DeleteProjectMember($id: String!) {
                  deleteProjectMember(request: { id: $id, permanent: true }) {
                    id
                  }
                }
              `,
              variables: {
                id,
              },
              fetchPolicy: 'network-only',
            });

             postsQuery.pipe(
              take(1),
              tap(() => {
                this.toastService.showInfo('You have left the project','success');
                this.router.navigate(['/home']);
              }),
              catchError((error) => {
                this.toastService.showError('Failed to leave the project');
                console.error('Failed to leave the project', error.message);
                return error;
              }
            )
            ).subscribe();
          },
        },
      ],
    );
  }
  
}
