import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MenuState } from '@ih/app/client/shared/components/menu-content/data-access';
import { AppClientSharedComponentsMenuContentFeatureModule } from '@ih/app/client/shared/components/menu-content/feature';
import { AppClientSharedComponentsMenuHeaderUiModule } from '@ih/app/client/shared/components/menu-header/ui';
import { MenuDisplayState, MeState } from '@ih/app/client/shared/states';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { AppGoShellFeatureRoutingModule } from './app-go-shell-feature-routing.module';
import { AppGoShellFeatureComponent } from './app-go-shell-feature.component';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    AppGoShellFeatureRoutingModule,
    AppClientSharedComponentsMenuContentFeatureModule,
    AppClientSharedComponentsMenuHeaderUiModule,
    NgxsModule.forFeature([MenuState, MeState, MenuDisplayState]),
  ],
  declarations: [AppGoShellFeatureComponent],
  exports: [AppGoShellFeatureComponent],
})
export class AppGoShellFeatureModule {}
