import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FetchResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import {
  Column,
  CountTableRowsRequest,
  Data,
  DataColumn,
  DataRowsResponse,
  DataType,
  DeleteDataRowsRequest,
  DownloadDataToCsvRequest,
  DownloadDataToCsvResponse,
  FindDataRowsRequest,
  FindUniqueTableRequest,
  InsertDataFromFileRequest,
  QueryTableRequest,
  QueryTableResponse,
  RowData,
  Table,
  UpdateDataColumnValuesRequest,
} from '@ih/app/shared/apis/interfaces';
import { Store } from '@ngxs/store';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import * as Papa from 'papaparse';
import { lastValueFrom, map, Observable, take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TablesService {
  constructor(
    private readonly apollo: Apollo,
    private readonly http: HttpClient,
    private readonly store: Store
  ) {}

  /*** graphql queries and mutations ***/
  findTable(request: FindUniqueTableRequest): Observable<Table | null> {
    type ResultType = { findUniqueTable: Table | null };

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findUniqueTable($request: FindUniqueTableRequest!) {
        findUniqueTable(request: $request) {
          name
          id
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, unknown>,
              Record<string, unknown>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findUniqueTable || null;
          }
        )
      );
  }

  findDataRows(
    request: FindDataRowsRequest
  ): Observable<DataRowsResponse | null> {
    type ResultType = { findDataRows: DataRowsResponse };
    let fetchPolicy = 'cache-only';
    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindDataRows(
        $tableId: String!
        $cursor: String
        $limit: Int!
        $orderBy: String!
        $orderByType: DataType!
        $search: String
      ) {
        findDataRows(
          request: {
            tableId: $tableId
            cursor: $cursor
            limit: $limit
            orderBy: $orderBy
            orderByType: $orderByType
            search: $search
          }
        ) {
          rows {
            columns {
              columnId
              value
            }
          }
          cursor
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          tableId: request.tableId,
          cursor: request.cursor,
          limit: request.limit,
          orderBy: request.orderBy,
          orderByType: request.orderByType,
          search: request.search,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, unknown>,
              Record<string, unknown>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.findDataRows || null;
          }
        )
      );
  }

  queryTable(request: QueryTableRequest): Observable<(RowData | null)[] | null> { // Array can be null or array can contain null elements 
    type ResultType = { queryTable: QueryTableResponse };
    let fetchPolicy = 'cache-only';
    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query QueryTable($request: QueryTableRequest!) {
        queryTable(request: $request) {
          rows {
            columns {
              key
              value
            }
          }
        }
      }
    `;


    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request: request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, unknown>,
              Record<string, unknown>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.queryTable.rows || null;
          }
        )
      );
  }

  countTableRows(request: CountTableRowsRequest): Observable<number | null> {
    type ResultType = { countTableRows: {count : number} };
    let fetchPolicy = 'cache-only';
    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query countTableRows($request: CountTableRowsRequest!) {
        countTableRows(request: $request){
          count
        }
      }
    `;

    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          request,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, unknown>,
              Record<string, unknown>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            if(result.data) return result.data.countTableRows.count;
            else return null;
          }
        )
      );
  }

  updateDataColumnValues(
    request: UpdateDataColumnValuesRequest
  ): Observable<Data | null> {
    type ResultType = { updateDataColumnValues: Data };
    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation UpdateDataColumnValues(
            $tableId: String!
            $primaryKeyValue: String!
            $row: DataRowInput!
          ) {
            updateDataColumnValues(
              request: {
                tableId: $tableId
                primaryKeyValue: $primaryKeyValue
                row: $row
              }
            ) {
              projectId
            }
          }
        `,
        variables: {
          tableId: request.tableId,
          primaryKeyValue: request.primaryKeyValue,
          row: request.row,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((result: MutationResult<ResultType>
        ) => {
          return result.data?.updateDataColumnValues || null;
        })
      );
  }

  generateDownloadFile(
    request: DownloadDataToCsvRequest
  ): Observable<DownloadDataToCsvResponse | null> {
    type ResultType = { downloadDataToCsv: DownloadDataToCsvResponse };
    let fetchPolicy = 'cache-only';
    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query downloadDataToCsv(
        $tableId: String!
        $projectIdName: ProjectIdName!
        $email: String!
      ) {
        downloadDataToCsv(
          request: {
            tableId: $tableId
            projectIdName: $projectIdName
            email: $email
          }
        ) {
          response
        }
      }
    `;
    return this.apollo
      .watchQuery<ResultType>({
        query,
        variables: {
          tableId: request.tableId,
          projectIdName: request.projectIdName,
          email: request.email,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        map(
          (
            result: FetchResult<
              ResultType,
              Record<string, unknown>,
              Record<string, unknown>
            >
          ) => {
            if (result.data && networkStatus) {
              this.apollo.client.cache.writeQuery({
                query,
                data: result.data,
              });
            }
            return result.data?.downloadDataToCsv || null;
          }
        )
      );
  }

  insertDataFromFile(
    request: InsertDataFromFileRequest
  ): Observable<Data | null> {
    type ResultType = { insertDataFromFile: Data };

    const response = this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation InsertDataFromFile(
            $tableId: String!
            $filePath: String!
            $email: String!
          ) {
            insertDataFromFile(
              request: { tableId: $tableId, filePath: $filePath, email: $email }
            ) {
              projectId
              rowsAffected
            }
          }
        `,
        variables: {
          tableId: request.tableId,
          filePath: request.filePath,
          email: request.email,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((result: MutationResult<ResultType>) => {
          return result.data?.insertDataFromFile || null;
        })
      );

    return response;
  }

  deleteDataRows(request: DeleteDataRowsRequest): Observable<Data | null> {
    type ResultType = { deleteDataRows: Data };

    return this.apollo
      .mutate<ResultType>({
        mutation: gql`
          mutation DeleteDataRows(
            $tableId: String!
            $primaryKeyValues: [String!]!
          ) {
            deleteDataRows(
              request: {
                tableId: $tableId
                primaryKeyValues: $primaryKeyValues
              }
            ) {
              projectId
            }
          }
        `,
        variables: {
          tableId: request.tableId,
          primaryKeyValues: request.primaryKeyValues,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((result: MutationResult<ResultType>) => {
          return result.data?.deleteDataRows || null;
        })
      );
  }

  /*** helper methods used by components or stores ***/

  constructDataRow(
    columns: Column[],
    row: Record<string, unknown>
  ): DataColumn[] {
    const dataColumns: DataColumn[] = [];

    columns.forEach((column) => {
      if (!column.autoIncrement) {
        const key = Object.keys(row).filter((x) => x === column.name)[0];
        let value = null;
        if (String(row[key]) !== 'null') {
          value = String(row[key]);

          if (column.dataType === DataType.FILE) {
            value = "'" + value + "'";
          }
        }

        dataColumns.push({
          columnId: column.id,
          value,
        });
      }
    });

    return dataColumns;
  }

  async convertFileToJSON(downloadUrl: string): Promise<unknown[]> {
    let text = await lastValueFrom(
      this.http.get(downloadUrl, { responseType: 'text' })
    );

    const rows = text.split(/\r?\n/);
    if (rows.length > 1) {
      text = rows.slice(0, 2).join('\n');
    }

    return new Promise((resolve, reject) => {
      return Papa.parse(text, {
        header: true,
        skipEmptyLines: true,
        error: (e: Error) => reject(e),
        complete: (results) => resolve(results.data),
      });
    });
  }

  // TODO : Investigate type of data
  getColumnsFromData(data: unknown[]): Partial<Column>[] { 
    const columns: Partial<Column>[] = [];
    const firstRow = data[0] as unknown;

    if (firstRow) {
      Object.keys(firstRow).forEach((key) => {
        const dataType = this.getDataType(firstRow, key);
        columns.push({
          name: key,
          dataType,
        });
      });
    } else throw 'File has no data';
    return columns;
  }

  private getDataType(data: any, key: string): DataType {
    // default to text
    if (!data[key]) return DataType.TEXT;

    if (Number(data[key])) {
      // if no decimal in value then cast to INT, else DOUBLE
      if (data[key].indexOf('.') === -1) return DataType.INT;
      return DataType.DOUBLE;
    }

    if (this.isBoolean(data[key])) {
      // this does not work for values like 'yes' or 'on'
      return DataType.BOOLEAN;
    }

    return DataType.TEXT;
  }

  private isBoolean(value: any): boolean {
    if (value === true || value === false) return true;
    if (typeof value === 'string') {
      if (value.toLowerCase() === 'false' || value.toLowerCase() === 'true')
        return true;
    }
    return false;
  }
}
