import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ProjectState } from '@ih/app/client/project/data-access';
import { ToastService } from '@ih/app/client/shared/services';
import { Project, Table } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';
import { FindAllTables, ResetProjectTablesList, SetTables } from '../actions';
import { FindAllTablesError, FindAllTablesSuccess } from '../effects';

export interface ListStateModel {
  tables: Table[];
}

@State<ListStateModel>({
  name: 'projectTablesList',
  defaults: {
    tables: [],
  },
})
@Injectable()
export class TablesListState {
  constructor(
    private readonly store: Store,
    private readonly toastService: ToastService,
    private readonly apollo: Apollo,
  ) {}

  @Selector()
  public static getState(state: ListStateModel) {
    return state;
  }

  @Selector()
  public static tables(state: ListStateModel): Table[] {
    return state.tables;
  }

  @Action(FindAllTables)
  public findAllTables(ctx: StateContext<ListStateModel>) {
    const project = this.store.selectSnapshot<Project | null>(
      ProjectState.project,
    );
    const projectId = project?.id || '';

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query FindAllTables($projectId: String!) {
        findAllTables(request: { where: { projectId: $projectId } }) {
          id
          name
          description
        }
      }
    `;

    return this.apollo
      .watchQuery<{ findAllTables: Table[] }>({
        query,
        variables: {
          projectId,
        },
        fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
      })
      .valueChanges.pipe(
        take(1),
        tap((result: ApolloQueryResult<{ findAllTables: Table[] }>) => {
          if (result.data && networkStatus) {
            this.apollo.client.cache.writeQuery({
              query,
              data: result.data,
            });
          }
          ctx.dispatch(new FindAllTablesSuccess(result));
        }),
        catchError((error) => ctx.dispatch(new FindAllTablesError(error))),
      );
  }

  @Action(FindAllTablesSuccess)
  public findAllTablesSuccess(
    ctx: StateContext<ListStateModel>,
    { result }: FindAllTablesSuccess,
  ) {
    const tables = (result?.data?.findAllTables || []) as Table[];
    ctx.dispatch(new SetTables(tables));
  }

  @Action(FindAllTablesError)
  public findAllTablesError(
    ctx: StateContext<ListStateModel>,
    { error }: FindAllTablesError,
  ) {
    this.toastService.showError('Failed to fetch tables');
    console.error('Failed to fetch tables: ', error.message);
  }

  @Action(SetTables)
  public setTables(ctx: StateContext<ListStateModel>, { tables }: SetTables) {
    ctx.setState(
      produce((draft) => {
        draft.tables = tables;
      }),
    );
  }

  @Action(ResetProjectTablesList)
  public resetProjectTablesList(ctx: StateContext<ListStateModel>) {
    ctx.setState(
      produce((draft) => {
        draft.tables = [];
      }),
    );
  }
}
