import { Injectable } from '@angular/core';
import { db } from '@ih/app/client/shared/db';

@Injectable({ providedIn: 'root' })
export class DownloadFormService {
  async add(payload: any) {
    await db.downloadedForms.put(payload, payload.id);
  }

  async remove(id: string | undefined) {
    if (!id) return;
    await db.downloadedForms.delete(id);
  }

  async get(id: string | undefined) {
    if (!id) return;

    return await db.downloadedForms.get(id);
  }

  async getAll() {
    return await db.downloadedForms.toArray();
  }
}
