import { UserCredential } from '@angular/fire/auth';

  /**
   * Displays a success toast and navigates to the home page
   * @updated 18/03/2024 - 09:25:15
   * @public
   * @param ctx StateContext<RegisterStateModel>
   * @param userCredential UserCredential 
   * @see libs/app/client/register/data-access/src/lib/states/register.state.ts
   */
export class RegisterSuccessAction {
  public static readonly type = '[Register] RegisterSuccessAction';
  constructor(public readonly userCredential: UserCredential) {}
}
