import { ApolloQueryResult } from '@apollo/client/core';
import { User } from '@ih/app/shared/apis/interfaces';
/**
 * Dispatches SetMe action to set state
 * @updated 18/03/2024 - 09:22:11
 * @public
 * @param ctx StateContext<MeStateModel>
 * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
 */
export class GetMeSuccess {
  public static readonly type = '[Me] GetMeSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{ me: User }>
    ,public readonly sitePermissions: string[]
) {}
}
