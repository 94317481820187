import { ApolloQueryResult } from '@apollo/client/core';
import { OrganisationMember } from '@ih/app/shared/apis/interfaces';

export class FindAllOrganisationMembersSuccess {
  public static readonly type =
    '[Organisation/OrganisationMembers/List] FindAllOrganisationMembersSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllOrganisationMembers: OrganisationMember[];
    }>
  ) {}
}
