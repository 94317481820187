import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class ToastService {
  private toast!: HTMLIonToastElement;
  private blocked: boolean;

  constructor(private readonly toastController: ToastController) {
    this.blocked = false;
  }

  async showError(message: string, duration = 3000) {
    if(this.blocked) return;
    duration += (message.length) / 300 * 2000;
    this.toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: 'danger',
      duration: duration,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
    this.toast.present();
  }

  async showInfo(message: string, color = '', duration = 3000) {
    if(this.blocked) return;
    this.toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: color,
      duration: duration,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
    this.toast.present();
  }

  async ShowPrioritizedInfo(message: string, color = '', duration = 3000) {
    this.blocked = true;
    this.toast = await this.toastController.create({
      message: message,
      position: 'bottom',
      color: color,
      duration: duration,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });
    this.toast.present();
    this.toast.onDidDismiss().then(() => {
      this.blocked = false;
    });
  }

  // async showInfoOnce(message: string, color = '', duration = 3000) {
  //   if (!this.opened) {
  //     this.toast = await this.toastController.create({
  //       message: message,
  //       position: 'bottom',
  //       color: color,
  //       duration: duration,
  //       buttons: [
  //         {
  //           text: 'Dismiss',
  //           role: 'cancel',
  //         },
  //       ],
  //     });
  //     this.toast.present();
  //     this.opened = true;
  //   }
  //   const { role } = await this.toast.onDidDismiss();
  //   console.warn(role);
  //   if (role) this.opened = false;
  // }

  async hide() {
    if (this.toast) await this.toast.dismiss();
  }
}
