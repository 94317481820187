import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NotificationsState } from '@ih/app/client/shared/components/notifications/data-access';
import { AppClientSharedComponentsNotificationsFeatureComponent } from '@ih/app/client/shared/components/notifications/feature';
import { PopoverController } from '@ionic/angular';
import { Observable, Subscription, tap } from 'rxjs';
import { OfflineInfoHeaderPopoverComponent } from './offline-info-header-popover';
import { Select, Store } from '@ngxs/store';
import { ManuallySetStatus } from '@ih/app/client/shared/states';

@Component({
  selector: 'ih-app-client-shared-components-menu-header-ui',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-item lines="none">
          <ng-container *ngIf="customLogo || customLogoDark">
            <img
              class="logo-light"
              alt="Custom Logo"
              [src]="customLogo ? customLogo : customLogoDark"
            />
            <!-- TODO: Change to dark once added to config -->
            <img
              class="logo-dark"
              alt="Custom Logo"
              [src]="customLogoDark ? customLogoDark : customLogo"
            />
          </ng-container>
          <!-- TODO: Add some kind of delay so it wont show default until it is sure there is no custom logo -->
          <ng-container *ngIf="!customLogo && !customLogoDark">
            <a [routerLink]="['/home']" slot="start">
              <img
                class="logo-light"
                alt="Information Hub Logo"
                src="/assets/ih_logo_primary_no_up.svg"
              />
              <img
                class="logo-dark"
                alt="Information Hub Logo"
                src="/assets/ih_logo_white_no_up.svg"
              />
            </a>
          </ng-container>
        </ion-item>
        <ng-container *ngIf="sharedForm">
          <ion-buttons slot="end">
            <ion-button (click)="presentNotificationPopover($event)">
              <ion-icon
                name="notifications-outline"
                [color]="notificationCount > 0 ? 'primary' : ''"
              ></ion-icon>
              <ion-badge *ngIf="notificationCount > 0">{{
                notificationCount
              }}</ion-badge>
            </ion-button>
            <ion-button
              *ngIf="!networkStatus"
              (click)="toggleOffline($event, true)"
            >
              <ion-icon color="warning" name="wifi" slot="icon-only"></ion-icon>
            </ion-button>
            <ion-button
              *ngIf="networkStatus"
              (click)="toggleOffline($event, false)"
            >
              <ion-icon color="success" name="wifi" slot="icon-only"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ng-container>
      </ion-toolbar>
    </ion-header>
  `,
  styles: [
    `
      img {
        height: 50px;
      }
    `,
  ],
})
export class AppClientSharedComponentsMenuHeaderUiComponent
  implements OnInit, OnDestroy
{
  @Select(NotificationsState.count) notificationCount$!: Observable<number>;

  @Input() customLogo!: string | undefined | null;
  @Input() customLogoDark!: string | undefined | null;
  @Input() networkStatus!: boolean | unknown;
  @Input() sharedForm!: boolean;

  notificationCountSub$!: Subscription;

  notificationCount = 0;

  constructor(private popover: PopoverController, private store: Store) {}

  ngOnInit() {
    this.notificationCountSub$ = this.notificationCount$
      .pipe(
        tap(
          (notificationCount: number) =>
            (this.notificationCount = notificationCount)
        )
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.notificationCountSub$ && !this.notificationCountSub$.closed)
      this.notificationCountSub$.unsubscribe();
  }

  async toggleOffline(event: Event, status: boolean) {
    this.store.dispatch(new ManuallySetStatus(status));
    if (!status) {
      this.presentOfflineInfoPopover(event);
    }
  }

  async presentOfflineInfoPopover(ev: Event) {
    const popover = await this.popover.create({
      component: OfflineInfoHeaderPopoverComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
    });
    await popover.present();
  }

  async presentNotificationPopover(ev: Event) {
    const popover = await this.popover.create({
      component: AppClientSharedComponentsNotificationsFeatureComponent,
      event: ev,
      translucent: true,
      showBackdrop: false,
    });
    await popover.present();
  }
}
