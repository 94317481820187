import { Injectable } from '@angular/core';
import { MenuContext, MenuItem } from '@ih/app/client/shared/interfaces';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { SetMenuContexts } from './../actions/set-menu-contexts/set-menu-contexts.action';
import { SetMenuItems } from './../actions/set-menu-items/set-menu-items.action';

export interface MenuStateModel {
  menuItems: MenuItem[];
  menuContexts: MenuContext[] | null;
}

@State<MenuStateModel>({
  name: 'menu',
  defaults: {
    menuItems: [],
    menuContexts: [],
  },
})
@Injectable()
export class MenuState {
  @Selector()
  public static getState(state: MenuStateModel) {
    return state;
  }

  @Selector()
  public static menuItems(state: MenuStateModel): MenuItem[] {
    return state.menuItems;
  }

  @Selector()
  public static menuContexts(state: MenuStateModel): MenuContext[] | null {
    return state.menuContexts;
  }

  @Action(SetMenuItems)
  public setMenuItems(
    ctx: StateContext<MenuStateModel>,
    { menuItems }: SetMenuItems,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.menuItems = menuItems;
      }),
    );
  }

  @Action(SetMenuContexts)
  public setMenuContexts(
    ctx: StateContext<MenuStateModel>,
    { menuContexts }: SetMenuContexts,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.menuContexts = menuContexts;
      }),
    );
  }
}
