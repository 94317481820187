  /**
   * Gets current user and sets MeStateModel.me to it
   * @updated 18/03/2024 - 09:22:11
   * @public
   * @param ctx StateContext<MeStateModel>
   * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
   */
export class GetMe {
  public static readonly type = '[Me] GetMe';
}
