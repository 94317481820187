import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  StorageService,
  TablesService,
  TrackQueriesService,
} from '@ih/app/client/shared/services';
import { IonicModule } from '@ionic/angular';
import { AppClientSharedComponentsNotificationsFeatureComponent } from './app-client-shared-components-notifications-feature.component';
export * from './app-client-shared-components-notifications-feature.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [AppClientSharedComponentsNotificationsFeatureComponent],
  exports: [AppClientSharedComponentsNotificationsFeatureComponent],
  providers: [TrackQueriesService, StorageService, TablesService],
})
export class AppClientSharedComponentsNotificationsFeatureModule {}
