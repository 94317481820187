
// Utility Type that removes:
// - Entries that are read only (-readonly)
// - Optional values (-?)
// - Nullable values (NonNullable<T>)
// modifiers from an object
export type RequiredMutable<T> = {
    -readonly [K in keyof T]-?: NonNullable<T[K]>;
};


//Remove readonly modifier
export type Mutable<T> = {
    -readonly [K in keyof T]: T[K];
  };
  
// Remove optional modifier
export type NoOptionals<T> = {
  [K in keyof T]-?: T[K];
};

// Remove any nullable types
export type NonNull<T> = {
  [K in keyof T]: NonNullable<T[K]>;
};