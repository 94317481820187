import { StorageReference } from '@angular/fire/storage';

export class SetFileDownloadUrlAction {
  public static readonly type =
    '[Project/Storage/List] SetFileDownloadUrlAction';
  constructor(
    public readonly file: StorageReference,
    public readonly downloadUrl: string
  ) {}
}
