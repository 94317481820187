import { StorageReference } from '@angular/fire/storage';
import { FileExtra } from './file-extra.interface';
import { SelectionState } from './selection-state.interface';

export interface SelectedObjects {
  projectId: string;
  parentPath: string;
  selectedFolders: (StorageReference & SelectionState)[];
  selectedFiles: (StorageReference & FileExtra & SelectionState)[];
}
