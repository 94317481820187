// utility functions for checking file types on table image preview

export function fileImageTypeArrayChecker(array: any[]): boolean {
  let isImage = false;
  array.forEach((element: any) => {
    if (fileImageTypeChecker(element.title)) {
      isImage = true;
    }
  });

  return isImage;
}

export function fileImageTypeChecker(file: string): boolean {
  const acceptedExtensionTypes = [
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'jfif',
    'tiff',
    'tif',
  ];
  const fileExtension = file.split('.').pop();
  if (fileExtension) {
    return acceptedExtensionTypes.includes(fileExtension.toLowerCase());
  }
  return false;
}

export function returnImageTypeArray(array: any[]): any {
  return array.filter((element: any) => {
    return fileImageTypeChecker(element.title)
  });
}
