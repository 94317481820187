import { ProjectMember, ProjectRole } from '@ih/app/shared/apis/interfaces';

export class FindAllProjectRoles {
  public static readonly type =
    '[Project/ProjectMember/List] FindAllProjectRoles';
  constructor(
    public readonly projectMembers: ProjectMember[],
    public readonly defaultRoles: ProjectRole[]
  ) {}
}
