export * from './actions';
export * from './effects';
export * from './states';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AlertService,
  StorageService,
  ToastService,
} from '@ih/app/client/shared/services';
import { NgxsModule } from '@ngxs/store';
import { StorageBrowseState } from './states';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([StorageBrowseState])],
  providers: [StorageService, AlertService, ToastService],
})
export class AppClientProjectStorageBrowseDataAccessModule {}
