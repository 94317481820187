import { Injectable } from '@angular/core';
import { ApolloQueryResult, WatchQueryFetchPolicy } from '@apollo/client/core';
import { ToastService } from '@ih/app/client/shared/services';
import { Group } from '@ih/app/shared/apis/interfaces';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Apollo, gql } from 'apollo-angular';
import { produce } from 'immer';
import { catchError, take, tap } from 'rxjs';
import {
  FindAllGroups,
  RequestToJoinGroup,
  ResetOrganisationGroupsList,
  SetGroups,
} from '../actions';
import {
  FindAllGroupsError,
  FindAllGroupsSuccess,
  RequestToJoinGroupError,
  RequestToJoinGroupSuccess,
} from '../effects';

export interface OrganisationGroupsListStateModel {
  groups: Group[];
}

@State<OrganisationGroupsListStateModel>({
  name: 'organisationGroupsList',
  defaults: {
    groups: [],
  },
})
@Injectable()
export class OrganisationGroupsListState {
  constructor(
    private readonly store: Store,
    private readonly apollo: Apollo,
    private readonly toastService: ToastService,
  ) {}

  @Selector()
  public static getState(state: OrganisationGroupsListStateModel) {
    return state;
  }

  @Selector()
  public static groups(state: OrganisationGroupsListStateModel): Group[] {
    return state.groups;
  }

  @Action(FindAllGroups)
  public findAllGroups(ctx: StateContext<OrganisationGroupsListStateModel>) {
    const organisationId = this.store.selectSnapshot<string>(
      (state) => state.organisation.organisation.id,
    );

    let fetchPolicy = 'cache-only';

    const networkStatus = this.store.selectSnapshot<boolean>(
      (state) => state.network.status,
    );

    if (networkStatus) {
      fetchPolicy = 'network-only';
    }

    const query = gql`
      query findAllGroups($organisationId: String!) {
        findAllGroups(request: { where: { organisationId: $organisationId } }) {
          name
          id
          slug
          visibility
          description
          photoURL
        }
      }
    `;

    const postsQuery = this.apollo.watchQuery<{ findAllGroups: Group[] }>({
      query,
      variables: {
        organisationId,
      },
      fetchPolicy: <WatchQueryFetchPolicy>fetchPolicy,
    });
    postsQuery.refetch();
    return postsQuery.valueChanges.pipe(
      take(1),
      tap((result: ApolloQueryResult<{ findAllGroups: Group[] }>) => {
        if (result.data && networkStatus) {
          this.apollo.client.cache.writeQuery({
            query,
            data: result.data,
          });
        }
        ctx.dispatch(new FindAllGroupsSuccess(result));
      }),
      catchError((error) => ctx.dispatch(new FindAllGroupsError(error))),
    );
  }

  @Action(FindAllGroupsSuccess)
  public findAllGroupsSuccess(
    ctx: StateContext<OrganisationGroupsListStateModel>,
    { result }: FindAllGroupsSuccess,
  ) {
    const groups = (result?.data?.findAllGroups || []) as Group[];
    ctx.dispatch(new SetGroups(groups));
  }

  @Action(FindAllGroupsError)
  public findAllGroupsError(
    ctx: StateContext<OrganisationGroupsListStateModel>,
    { error }: FindAllGroupsError,
  ) {
    this.toastService.showError('Failed to fetch groups');
    console.error('Failed to fetch groups: ', error.message);
  }

  @Action(SetGroups)
  public setGroups(
    ctx: StateContext<OrganisationGroupsListStateModel>,
    { groups }: SetGroups,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.groups = groups;
      }),
    );
  }

  @Action(ResetOrganisationGroupsList)
  public resetOrganisationGroupsList(
    ctx: StateContext<OrganisationGroupsListStateModel>,
  ) {
    ctx.setState(
      produce((draft) => {
        draft.groups = [];
      }),
    );
  }

  @Action(RequestToJoinGroup)
  public requestToJoinGroup(
    ctx: StateContext<OrganisationGroupsListStateModel>,
    { groupId }: RequestToJoinGroup,
  ) {
    const userId = this.store.selectSnapshot<string>(
      (state) => state.authentication.user.uid,
    );
    return this.apollo
      .mutate({
        mutation: gql`
          mutation requestToJoinGroup($userId: ID!, $groupId: ID!) {
            requestToJoinGroup(
              request: { userId: $userId, groupId: $groupId }
            ) {
              response
            }
          }
        `,
        variables: {
          userId,
          groupId,
        },
      })
      .pipe(
        tap(() => ctx.dispatch(new RequestToJoinGroupSuccess())),
        catchError((error) =>
          ctx.dispatch(new RequestToJoinGroupError(error as Error)),
        ),
      );
  }

  @Action(RequestToJoinGroupSuccess)
  public async requestToJoinGroupSuccess() {
    this.toastService.showInfo(`Request to join group sent`);
  }

  @Action(RequestToJoinGroupError)
  public async requestToJoinGroupError(
    ctx: StateContext<OrganisationGroupsListStateModel>,
    { error }: RequestToJoinGroupError,
  ) {
    this.toastService.showError('Request failed to send');
    console.error('Request failed to send: ', error.message);
  }
}
