import { ApolloQueryResult } from '@apollo/client/core';
import { Table } from '@ih/app/shared/apis/interfaces';

export class FindAllTablesSuccess {
  public static readonly type = '[Project/Tables/List] FindAllTablesSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllTables: Table[];
    }>
  ) {}
}
