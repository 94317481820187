import { Injectable } from '@angular/core';
import { db } from '@ih/app/client/shared/db';
import {
  RemoveTrackedQuery,
  TrackedQuery,
} from '@ih/app/client/shared/interfaces';


/**
 * Tracks all queries to graphql during the runtime of the app. 
 */
@Injectable({ providedIn: 'root' })
export class TrackQueriesService {
  // add to db if there is no duplicates based on trackId key
  add(payload: TrackedQuery) {
    db.trackedQuery
      .where('trackId')
      .equals(payload.trackId)
      .toArray()
      .then((trackedQueries: TrackedQuery[]) => {
        if (trackedQueries.length === 0) {
          db.trackedQuery.add(payload);
        }
      });
  }

  addToRemove(trackId: string) {
    db.removeTrackedQuery.add({ trackId: trackId });
  }

  // remove(trackId: string) {
  //   db.trackedQuery.delete(trackId);
  // }

  async removeCompletedQueries() {
    return await this.getRemoveTrackedQueriesFromDB().then(
      (removeTrackedQueries) => {
        if (removeTrackedQueries.length > 0) {
          const queriesToDelete = removeTrackedQueries.map(
            (query) => query.trackId
          );
          db.trackedQuery.bulkDelete(queriesToDelete).then(() => {
            db.removeTrackedQuery.bulkDelete(queriesToDelete).then(() => {
              return;
            });
          });
        }


      }
    );
  }

  async getTrackedQueriesFromDB(): Promise<TrackedQuery[]> {
    return db.trackedQuery.toArray().then((trackedQueries: TrackedQuery[]) => {
      return trackedQueries;
    });
  }

  async getTrackedQuery(id: string): Promise<TrackedQuery[]> {
    return db.trackedQuery
      .where('trackId')
      .equals(id)
      .toArray()
      .then((trackedQuery: TrackedQuery[]) => {
        return trackedQuery;
      });
  }

  async getRemoveTrackedQueriesFromDB(): Promise<RemoveTrackedQuery[]> {
    return db.removeTrackedQuery
      .toArray()
      .then((removeTrackedQuery: RemoveTrackedQuery[]) => {
        return removeTrackedQuery;
      });
  }
}
