import { Injectable } from '@angular/core';
import { ProjectsService } from '../projects';
import { GroupsService } from '../groups';
import { OrganisationsService } from '../organisations';
import { lastValueFrom, map, take } from 'rxjs';
import { ProjectMember,RoleType } from '@ih/app/shared/apis/interfaces';
import { SiteService } from '../site';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {
  constructor(
    private readonly projectService : ProjectsService,
    private readonly organisationService : OrganisationsService,
    private readonly siteService : SiteService,
    private readonly groupService : GroupsService,

  ) {}

  async getSitePermissions(siteRoleId  : string) : Promise<string[]> {
    const siteRolePermissions = await lastValueFrom(this.siteService.findUserPermissions({ where: { siteRoleId } })
    .pipe(
      take(1), 
      map(result => result), 
    ))

    const permissionIds = siteRolePermissions.map((permission) => permission.sitePermissionId);

    const allPermissions = await lastValueFrom(this.siteService.findAllSitePermissions({where: {}}))

    const permissions = allPermissions.filter(
      (permission) => permissionIds.includes(permission.id),
    ).map((permission) => permission.identifier);

    return permissions;
  }

  async  getProjectPermissions(projectId: string,userId  : string) : Promise<string[]> {
    const projectPermissions : string[] = [];

    const response : ProjectMember[] = await lastValueFrom(this.projectService.findMeProjectMemberWithRolePermissions({
      where: {
        projectId,
        userId,
      }
    })
    )

    const member : ProjectMember = response[0];

    if(!member){
      // check if project is template 
      const project = await lastValueFrom(this.projectService.findUniqueProject({id: projectId}))

      if(!project || !project.template) return [];

      const viewerPermissions = await lastValueFrom(this.projectService.findProjectRolePermissions({name: RoleType.VIEWER, projectId: null}))
      return viewerPermissions.map((permission) => permission.identifier);
    }

    const roleId = member.projectRoleId;
    if(!roleId) return [];

    const rolePermissions = await lastValueFrom(this.projectService.findProjectRolePermissions({id: roleId, projectId}))

    rolePermissions.forEach(
      (rolePermission) => {
        projectPermissions.push(rolePermission.identifier);
      },
    );

    return projectPermissions;
  }

  async getGroupPermissions(groupId: string, userId: string) : Promise<string[]> {
    const groupPermissions: string[] = [];

    const response = await lastValueFrom(this.groupService.findGroupMemberWithRolePermissions(userId, groupId)
    )

    const member = response[0];
    if(!member) return [];

    const roleId = member.groupRoleId;
    if(!roleId) return [];

    const rolePermissions = await lastValueFrom(this.groupService.findUniqueGroupRolePermissions({id: roleId,groupId}))

    rolePermissions.forEach(
      (rolePermission) => {
        groupPermissions.push(rolePermission.identifier);
      },
    );

    return groupPermissions;
  }

  async getOrganisationPermissions(organisationId: string, userId: string) : Promise<string[]> {
    const organisationPermissions: string[] = [];

    const response = await lastValueFrom(this.organisationService.findOrganisationMemberWithRolePermissions(userId, organisationId))

    const member = response[0];
    if(!member) return [];

    const roleId = member.organisationRoleId;
    if(!roleId) return organisationPermissions;

    const rolePermissions = await lastValueFrom(this.organisationService.findUniqueOrganisationRolePermissions({id:roleId, organisationId}))

    rolePermissions.forEach(
      (rolePermission) => {
        organisationPermissions.push(rolePermission.identifier);
      },
    );

    return organisationPermissions;
  }
}
