import { ApolloQueryResult } from '@apollo/client/core';
import { GroupMember, GroupRole } from '@ih/app/shared/apis/interfaces';

export class FindAllGroupRolesSuccess {
  public static readonly type = '[Group/Users/List] FindAllGroupRolesSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllGroupRoles: GroupRole[];
    }>,
    public readonly members: GroupMember[],
    public readonly defaultRoles: GroupRole[]
  ) {}
}
