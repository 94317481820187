import { ApolloQueryResult } from '@apollo/client/core';
import { GroupMember, GroupRole } from '@ih/app/shared/apis/interfaces';

export class FindAllDefaultRolesSuccess {
  public static readonly type =
    '[Group/GroupRoles/List] FindAllDefaultRolesSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      listCoreGroupRoles: GroupRole[];
    }>,
    public readonly members: GroupMember[]
  ) {}
}
