import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { produce } from 'immer';
import { SetRedirectPath } from '../actions';

export interface RedirectStateModel {
    redirectPath: string;
    errors: Error[];
}

const defaults: RedirectStateModel = {
    redirectPath: '/home',
    errors: [],
};

@State<RedirectStateModel>({
    name: 'redirect',
    defaults,
})
@Injectable()
export class RedirectState {
    @Selector()
    public static redirectPath(state: RedirectStateModel): string {
        return state.redirectPath;
    }

    @Action(SetRedirectPath)
    public setRedirectPath(
        ctx: StateContext<RedirectStateModel>,
        { redirectPath }: SetRedirectPath
    ) {
        return ctx.setState(
            produce(ctx.getState(), (state: RedirectStateModel) => {
                state.redirectPath = redirectPath;
            }),
        );
    }
}

