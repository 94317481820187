/**
 * Gets group permissions of member and updates state
 * @updated 04/11/2024
 * @public
 * @param ctx StateContext<MeStateModel>
 * @param userId string
 * @param groupId string
 * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
 */export class GetGroupPermissions {
  public static readonly type = '[Me] GetGroupPermissions';
  constructor(
    public readonly userId: string,
    public readonly groupId: string,
    public readonly meta? : boolean
  ) {}
}
