import { User } from '@ih/app/shared/apis/interfaces';
/**
 * Sets MeStateModel.me to the provided user
 * @updated 18/03/2024 - 09:22:11
 * @public
 * @param ctx StateContext<MeStateModel>
 * @param me User | null
 * @see libs/app/client/shared/states/src/lib/me/states/me.state.ts
 */export class SetMe {
  public static readonly type = '[Me] SetMe';
  constructor(
    public readonly me: User | null,
    public readonly sitePermissions: string[],
  ) {}
}
