import { ApolloQueryResult } from '@apollo/client/core';
import { GroupMember } from '@ih/app/shared/apis/interfaces';

export class FindAllGroupMembersSuccess {
  public static readonly type =
    '[Group/GroupMembers/List] FindAllGroupMembersSuccess';
  constructor(
    public readonly result: ApolloQueryResult<{
      findAllGroupMembers: GroupMember[];
    }>
  ) {}
}
